import { AddressAutofill } from "@mapbox/search-js-react"
import Button from "components/Reusables/Button"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import styles from "./AddressCollectionWidget.module.scss"
import { TextField } from "@mui/material"

// The widget returns locations at various accuracy levels as outlined here
// https://docs.mapbox.com/mapbox-search-js/api/core/autofill/#addressautofillsuggestion#accuracy
// Not all of these make sense for our use case. Suggestions returned with the
// following accuracies will fail form validation
const invalidSuggestionAccuracyLevels = new Set([
  `intersection`,
  `street`,
  `interpolated`,
  `approximate`,
])

const AddressCollectionWidget = ({ onSubmit, isLoading }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    setError,
    control,
  } = useForm()

  // While we don't care about the actual mapbox object itself, just the
  // place_name (address), because both the user and AddressAutofill might
  // modify the autofill input, we track what they *currently* selected. If the
  // value in the input matches this, then we know we can apply some additional
  // validation
  const [selectedMapboxObject, setSelectedMapboxObject] = useState(null)

  const selectedMapboxAddress =
    selectedMapboxObject?.properties.place_name ?? ``
  useEffect(() => {
    if (!selectedMapboxObject) {
      setError(`address`, `Please select an address.`)
    } else if (
      invalidSuggestionAccuracyLevels.has(
        selectedMapboxObject.properties.accuracy
      )
    ) {
      setError(`address`, `Please select a concrete, non-approximate address`)
    }
    setValue(`address`, selectedMapboxAddress)
    setValue(`longitude`, selectedMapboxObject?.geometry.coordinates[0] ?? ``)
    setValue(`latitude`, selectedMapboxObject?.geometry.coordinates[1] ?? ``)
  }, [selectedMapboxObject])

  useEffect(() => {
    if (getValues(`address`) !== selectedMapboxAddress)
      setSelectedMapboxObject(null)
  }, [getValues(`address`)])

  let addressSelected = false
  // Making sure that user has selected an address from the dropdown
  if (selectedMapboxAddress !== "") {
    addressSelected = true
  }

  return (
    <form
      onSubmit={
        addressSelected ? handleSubmit(onSubmit) : e => e.preventDefault()
      }
      className={styles.AddressCollectionWidget}
      data-testid="AddressCollectionWidget"
    >
      <Controller
        render={() => (
          <>
            <AddressAutofill
              accessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              options={{ country: `US` }}
              autoComplete="new-password"
              onRetrieve={x => setSelectedMapboxObject(x.features[0] ?? null)}
            >
              <TextField
                {...register(`address`)}
                type="text"
                variant="outlined"
                color="primary"
                fullWidth
                required
                autoComplete="off"
                onChange={e => setValue(`address`, e.target.value)}
                value={getValues(`address`) || ``}
                onBlur={() => {
                  if (selectedMapboxAddress != getValues(`address`))
                    setValue(`address`, ``)
                }}
                className={`form-control search ${styles.IbrAddressSearch}`}
                placeholder="ENTER BUILDING ADDRESS"
                id="address-autofill-input"
              />
            </AddressAutofill>
          </>
        )}
        name="address"
        control={control}
        errors={errors.address}
        rules={{ required: "Please select an address." }}
      />
      {errors.address && (
        <div className={styles.Error} aria-describedby="address-autofill-input">
          {errors.address.message}
        </div>
      )}
      <div className={styles.NextButtonSection}>
        <Button styles={styles} isLoading={isLoading}>
          Next
        </Button>
      </div>
    </form>
  )
}

AddressCollectionWidget.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

AddressCollectionWidget.defaultProps = {}

export default AddressCollectionWidget
