import React from "react"
import { useParams } from "react-router-dom"
import styles from "./ReportPage.module.scss"
import { default as PlainReportPage } from "./ReportPage"

const ReportPage = () => {
  const { buildingId } = useParams()
  // TODO:
  // * fetch the building report data for this building ID
  //  * Use https://docs.google.com/document/d/1uRdW1SjUcL0uhpwK7ZRxlPYjLkPywCCsvjgf0hcOUOQ/edit#bookmark=id.8c4zw1w80fxg
  // * while the data is loading, show a loading state
  // * when the data is loaded, show the actual report

  // For now, the data is stubbed
  const reportData = {
    building_id: buildingId,
    address: "123 Sesame St, Brooklyn, NY 12345",
    metrics: [],
  }

  return (
    <div className={styles.App}>
      <PlainReportPage
        address={reportData.address}
        metrics={reportData.metrics}
        buildingId={buildingId}
      />
    </div>
  )
}

export default ReportPage
