import React, { useState, useEffect } from "react"
import styles from "./AccuracyStatement.module.scss"
import "react-responsive-modal/styles.css"
import PropTypes from "prop-types"
import CTAButton from "components/Reusables/CTAButton"
import ActionButton from "components/IBRv2/ActionButton/ActionButton"

const AccuracyStatement = ({ reportInfo, ibr }) => {
  let scheduleButton = (
    <>
      <CTAButton styles={styles}>SCHEDULE YOUR FREE CONSULTATION</CTAButton>
    </>
  )
  if (ibr == "v2") {
    scheduleButton = (
      <>
        <ActionButton
          styles={styles}
          arrow={"none"}
          owner={reportInfo?.hubspot_owner_id}
          url={reportInfo?.hubspot_owner_calendar_url}
        >
          SCHEDULE YOUR FREE CONSULTATION
        </ActionButton>
      </>
    )
  }

  return (
    <div className={styles.AccuracyStatement} data-testid="AccuracyStatement">
      <div className={styles.SectionTitle}>
        How accurate are these estimates?
      </div>
      <div className={styles.SectionSubTitle}>
        The incentives, cost and energy bill savings estimates are predictions
        with the information we have available. To get a more accurate estimate,
        {scheduleButton}.
      </div>
    </div>
  )
}

AccuracyStatement.propTypes = {
  ibr: PropTypes.string,
  reportInfo: PropTypes.object,
}

AccuracyStatement.defaultProps = {}

export default AccuracyStatement
