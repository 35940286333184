import React from "react"
import PropTypes from "prop-types"
import styles from "./Subscriber.module.scss"

const Subscriber = () => (
  <div className={styles.Subscriber} data-testid="Subscriber">
    {window.location.replace(process.env.REACT_APP_SUBSCRIBER_PAGE_URL)}
  </div>
)

Subscriber.propTypes = {}

Subscriber.defaultProps = {}

export default Subscriber
