import React from "react"
import PropTypes from "prop-types"
import { Divider } from "@mui/material"
import styles from "./FooterSection.module.scss"

const FooterSection = ({ reportInfo }) => {
  let mountainViewLead = reportInfo?.is_mountain_view_lead

  return (
    <div className={styles.FooterSection} data-testid="FooterSection">
      {mountainViewLead && (
        <>
          <Divider className={styles.Divider} />
          <div className={styles.MountainViewFooter}>
            <p>
              With support from Mountain View, BlocPower is working to electrify
              the City’s building stock with a focus on multi-family buildings
              and can help you increase NOI and improve benefits to your tenants
              health, space, and community.
            </p>
          </div>
        </>
      )}
      <div className={styles.Footer}>
        <a
          href="https://www.blocpower.io/terms-conditions"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Service
        </a>{" "}
        |{" "}
        <a
          href="https://www.blocpower.io/privacy-policy-2"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  )
}

FooterSection.propTypes = {
  reportInfo: PropTypes.object,
}

FooterSection.defaultProps = {}

export default FooterSection
