import React from "react"
import PropTypes from "prop-types"
import styles from "./SectionButton.module.scss"
import { ButtonBlueArraowIcon } from "components/Reusables/Icons"
import { Link } from "react-scroll"
import { useMediaQuery } from "@mui/material"

const SectionButton = ({ section, children, arrow }) => {
  const isSmallScreen = useMediaQuery("(max-width: 900px)")
  let offset = isSmallScreen ? -246 : -210
  let arrowIcon = <ButtonBlueArraowIcon />
  if (arrow == "none") {
    arrowIcon = <></>
  }
  return (
    <div className={styles.SectionButton} data-testid="SectionButton">
      <Link to={section} spy smooth offset={offset}>
        <button className={styles.Button}>
          {children} {arrowIcon}
        </button>
      </Link>
    </div>
  )
}

SectionButton.propTypes = {
  section: PropTypes.string,
  children: PropTypes.node.isRequired,
  arrow: PropTypes.string,
}

SectionButton.defaultProps = {
  children: "",
  section: "",
}

export default SectionButton
