import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchAssessmentInfo } from "redux/actions"
import { useParams } from "react-router-dom"
import styles from "./Assessment.module.scss"
import AssessmentLoading from "../AssessmentLoading/AssessmentLoading"
import AssessmentReport from "../ReportV2/Assessment/Assessment"

const Assessment = () => {
  const { submissionId } = useParams()
  const dispatch = useDispatch()
  const [isFakeLoading, setFakeLoading] = useState(true)
  useEffect(() => dispatch(fetchAssessmentInfo(submissionId)), [submissionId])
  useEffect(() => {
    setTimeout(() => setFakeLoading(false), 4000)
  }, [])
  const {
    status,
    data: assessmentInfo,
    isError,
  } = useSelector(state => state.assessmentInfo)
  const isDataLoading = status === "loading" || status === "idle"
  const showLoading = isFakeLoading || isDataLoading
  if (showLoading) {
    return <AssessmentLoading />
  }
  if (isError) {
    assessmentInfo.detail
      ? window.alert(assessmentInfo.detail)
      : window.alert("There is an error. Please try to refresh the page.")
  }
  const metrics = []
  metrics.push({
    label: "Estimated Savings",
    value:
      assessmentInfo.savings_min === 0 && assessmentInfo.savings_max === 0
        ? "Not Applicable"
        : `${assessmentInfo.savings_min}\u2013${assessmentInfo.savings_max}%`,
    unit: "decrease on monthly energy bill",
  })
  if (
    assessmentInfo.incentives_min !== "0" &&
    assessmentInfo.incentives_max !== "0"
  ) {
    metrics.push({
      label: "Estimated Incentives",
      value: `$${assessmentInfo.incentives_min}\u2013$${assessmentInfo.incentives_max}`,
      unit: "potential rebates from state, local, federal incentives",
    })
  }
  const isMQL = assessmentInfo?.is_qualified_lead
  const hasIBR =
    assessmentInfo?.customer_marketing_status === "MQL - No IBR" ||
    !("upgrade_measures" in assessmentInfo)
  let hasFinancing = true
  if (assessmentInfo?.is_san_jose_lead || !isMQL) {
    hasFinancing = false
  }
  const hasProjectManagement = !assessmentInfo?.is_san_jose_lead
  return (
    <div className={styles.Assessment} data-testid="Assessment">
      <AssessmentReport
        address={assessmentInfo.street_address}
        reportInfo={assessmentInfo}
        hasIBR={hasIBR}
        isMQL={isMQL}
        hasFinancing={hasFinancing}
        hasProjectManagement={hasProjectManagement}
      />
    </div>
  )
}

Assessment.propTypes = {}

Assessment.defaultProps = {}

export default Assessment
