import React from "react"
import PropTypes from "prop-types"
import styles from "./ProjectManagement.module.scss"
import {
  Grid,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"
import HeatPumpIcon from "images/icons/heat_pump_icon.svg"
import { makeStyles } from "@mui/styles"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import MultiFamily from "images/MultiFamily.jpeg"
import EnergyIcon from "images/icons/energy_icon.svg"
import EandDIcon from "images/icons/e_and_d_icon.svg"
import PermittingIcon from "images/icons/permitting_icon.svg"
import ConstructionIcon from "images/icons/construction_man_icon.svg"
import IncentiveIcon from "images/icons/incentive_app_icon.svg"
import CommissioningIcon from "images/icons/commissioning_icon.svg"
import MaintenanceIcon from "images/icons/ongoing_maint_icon.svg"

const ProjectManagement = ({ isMQL }) => {
  const [expanded, setExpanded] = React.useState(false)
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const useStyle = makeStyles(() => ({
    root: {
      boxShadow: "none",
      padding: 0,
      "&:hover": {
        borderRadius: "4px",
        background: "rgba(75, 51, 226, 0.08)",
      },
    },
    expanded: {
      margin: "0 !important",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: "4px",
      "&:before": {
        opacity: 1,
      },
      "&:first-of-type": {
        margin: "0 !important",
      },
    },
  }))
  const useAccStyle = makeStyles(() => ({
    root: {
      minHeight: "40px",
    },
    content: {
      margin: 0,
    },
  }))
  const classes = useStyle()
  const classesAcc = useAccStyle()
  return (
    <div className={styles.ProjectManagement} data-testid="ProjectManagement">
      {isMQL ? (
        <div className={styles.HeaderText}>
          All-Inclusive Project Management Services
        </div>
      ) : (
        <div className={styles.HeaderText}>Project Guide</div>
      )}
      <Grid item sm={12}>
        <div className={styles.ProjectsList}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            classes={classes}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon style={{ fontSize: 24, color: "#4B33E2" }} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              classes={classesAcc}
            >
              <Grid container alignItems={"center"}>
                <Grid item className={styles.IconStyle}>
                  <img
                    className={styles.Icon}
                    alt={"Energy Icon"}
                    src={EnergyIcon}
                  />
                </Grid>
                <Grid item className={styles.ProjectHeader}>
                  Energy Assessment
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item textAlign={"justify"} fontSize={"12px"}>
                  Begin with a free energy assessment to know where your
                  building stands, including potential fines from local laws.
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            classes={classes}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon style={{ fontSize: 24, color: "#4B33E2" }} />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
              classes={classesAcc}
            >
              <Grid container alignItems={"center"}>
                <Grid item className={styles.IconStyle}>
                  <img
                    className={styles.Icon}
                    alt={"E&D Icon"}
                    src={EandDIcon}
                  />
                </Grid>
                <Grid item className={styles.ProjectHeader}>
                  Engineering and Design
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item textAlign={"justify"} fontSize={"12px"}>
                  BlocPower engineers bring decades of expertise to analyze your
                  building and design the optimal solution for your
                  building&apos;s unique needs.
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            classes={classes}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon style={{ fontSize: 24, color: "#4B33E2" }} />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
              classes={classesAcc}
            >
              <Grid container alignItems={"center"}>
                <Grid item className={styles.IconStyle}>
                  <img
                    className={styles.Icon}
                    alt={"Permitting Icon"}
                    src={PermittingIcon}
                  />
                </Grid>
                <Grid item className={styles.ProjectHeader}>
                  Permitting
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item textAlign={"justify"} fontSize={"12px"}>
                  We take care of the paperwork so your project complies with
                  zoning regulations, building codes and other requirements.
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            classes={classes}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon style={{ fontSize: 24, color: "#4B33E2" }} />
              }
              aria-controls="panel4a-content"
              id="panel4a-header"
              classes={classesAcc}
            >
              <Grid container alignItems={"center"}>
                <Grid item className={styles.IconStyle}>
                  <img
                    className={styles.Icon}
                    alt={"Construction Icon"}
                    src={ConstructionIcon}
                  />
                </Grid>
                <Grid item className={styles.ProjectHeader}>
                  Construction Management
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item textAlign={"justify"} fontSize={"12px"}>
                  Trust our vetted, job-tested, proven construction crews to get
                  your project done right and on schedule.
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
            classes={classes}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon style={{ fontSize: 24, color: "#4B33E2" }} />
              }
              aria-controls="panel5a-content"
              id="panel5a-header"
              classes={classesAcc}
            >
              <Grid container alignItems={"center"}>
                <Grid item className={styles.IconStyle}>
                  <img
                    className={styles.Icon}
                    alt={"Incentive Icon"}
                    src={IncentiveIcon}
                  />
                </Grid>
                <Grid item className={styles.ProjectHeader}>
                  Incentive Application
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item textAlign={"justify"} fontSize={"12px"}>
                  Incentive capture is a complex but critical step. We help you
                  identify and apply for all available incentives to lower your
                  cost and increase your ROI.
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
            classes={classes}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon style={{ fontSize: 24, color: "#4B33E2" }} />
              }
              aria-controls="panel6a-content"
              id="panel6a-header"
              classes={classesAcc}
            >
              <Grid container alignItems={"center"}>
                <Grid item className={styles.IconStyle}>
                  <img
                    className={styles.Icon}
                    alt={"Commissioning Icon"}
                    src={CommissioningIcon}
                  />
                </Grid>
                <Grid item className={styles.ProjectHeader}>
                  Commissioning
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item textAlign={"justify"} fontSize={"12px"}>
                  Thorough testing, verification and documentation ensures our
                  work meets quality and safety standards.
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
            classes={classes}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon style={{ fontSize: 24, color: "#4B33E2" }} />
              }
              aria-controls="panel7a-content"
              id="panel7a-header"
              classes={classesAcc}
            >
              <Grid container alignItems={"center"}>
                <Grid item className={styles.IconStyle}>
                  <img
                    className={styles.Icon}
                    alt={"Maintenance Icon"}
                    src={MaintenanceIcon}
                  />
                </Grid>
                <Grid item className={styles.ProjectHeader}>
                  Ongoing Maintenance Post-Install
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item textAlign={"justify"} fontSize={"12px"}>
                  Remote monitoring and ongoing maintenance come standard,
                  giving you one less thing to worry about.
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      </Grid>
    </div>
  )
}

ProjectManagement.propTypes = {
  isMQL: PropTypes.bool,
}

ProjectManagement.defaultProps = {}

export default ProjectManagement
