import React from "react"
import PropTypes from "prop-types"
import styles from "./Waitlist.module.scss"

const Waitlist = () => (
  <div className={styles.Waitlist} data-testid="Waitlist">
    {window.location.replace(process.env.REACT_APP_WAITLIST_PAGE_URL)}
  </div>
)

Waitlist.propTypes = {}

Waitlist.defaultProps = {}

export default Waitlist
