import React from "react"
import PropTypes from "prop-types"
import styles from "./FAQs.module.scss"
import {
  Grid,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import ArrowRightIcon from "@mui/icons-material/ArrowRight"

const FAQs = ({ isMQL, hasFinancing, hasProjectManagement }) => {
  const [expanded, setExpanded] = React.useState(false)
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const useStyle = makeStyles(() => ({
    content: {
      flexDirection: "column",
    },
    root: {
      flexDirection: "row-reverse",
      boxShadow: "none",
      padding: 0,
      "&:hover": {
        borderRadius: "4px",
        background: "rgba(75, 51, 226, 0.08)",
      },
    },
    expanded: {
      "&:before": {
        opacity: 1,
      },
    },
  }))
  const useAccStyle = makeStyles(() => ({
    root: {
      boxShadow: "none",
    },
  }))
  const useStyleAcc = makeStyles(() => ({
    root: {
      paddingLeft: "55px",
      paddingTop: 0,
      paddingBottom: 0,
    },
  }))
  const classesAcc = useAccStyle()
  const classes = useStyle()
  const Accclasses = useStyleAcc()
  return (
    <div className={styles.FAQs} data-testid="FAQs">
      <Grid container spacing={2} alignItems="center">
        <Grid item sm={12}>
          <div className={styles.OpportunityList}>
            <Divider className={styles.Divider} />
            <Accordion
              classes={classesAcc}
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowRightIcon style={{ fontSize: 55, color: "#4B33E2" }} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={classes}
                sx={{
                  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                    transform: "rotate(90deg)",
                  },
                }}
              >
                <div className={styles.FAQTitle}>
                  How does all-electric heat and hot water work?
                </div>
              </AccordionSummary>
              <AccordionDetails classes={Accclasses}>
                <p className={styles.FAQContent}>
                  Heating, cooling and hot water system equipment that is
                  powered by electricity—not the combustion of fossil fuels—uses
                  ambient air from outside the building or the ground as either
                  a heat source or heat sink. Check out this resource guide for
                  a deeper look, or read on for a high-level overview.
                </p>
                <p className={styles.FAQContent}>
                  This technology, called a heat pump, gets its name because it
                  moves heat into a building — it doesn&apos;t generate it as
                  furnaces and boilers do. Heat pumps also function in reverse,
                  acting as an air conditioner. In heating mode, the ambient air
                  from outside the building acts as the heat source. Modern
                  technology makes this possible even when the ambient air is
                  cold; the heat pump&apos;s refrigerant can extract heat even
                  in cold climates.
                </p>
                <p className={styles.FAQContent}>
                  Like any heating and cooling system, electricity-powered
                  equipment are at their best in a home or building that
                  maximizes efficiency, which is why BlocPower offers these
                  systems in conjunction with other efficiency measures as a
                  part of the Climate Comfort Service.
                </p>
              </AccordionDetails>
            </Accordion>
            <Divider className={styles.Divider} />
            <Accordion
              classes={classesAcc}
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowRightIcon style={{ fontSize: 55, color: "#4B33E2" }} />
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
                classes={classes}
                sx={{
                  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                    transform: "rotate(90deg)",
                  },
                }}
              >
                <div className={styles.FAQTitle}>
                  Why switch to a heat pump HVAC or hot water system?
                </div>
              </AccordionSummary>
              <AccordionDetails classes={Accclasses}>
                <p className={styles.FAQContent}>
                  The best reason for upgrading to a heat pump for your heating
                  and cooling needs is because of its performance—it&apos;s 2 to
                  4 times more efficient than boilers, furnaces or window air
                  conditioners.
                </p>
                <p className={styles.FAQContent}>
                  In addition, heat pumps provide precise, room-by-room
                  temperature control that just isn&apos;t possible with even
                  the newest boiler or furnace models. The ENERGYSTAR cold
                  climate systems we install will keep everyone comfortable all
                  year round—even in subzero temperatures.
                </p>
                <p className={styles.FAQContent}>
                  For these reasons and more, heat pump sales outpaced gas
                  furnaces for the first time in the U.S. in 2022.
                </p>
              </AccordionDetails>
            </Accordion>
            <Divider className={styles.Divider} />
            {hasFinancing && isMQL && (
              <>
                <Accordion
                  classes={classesAcc}
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowRightIcon
                        style={{ fontSize: 55, color: "#4B33E2" }}
                      />
                    }
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                    classes={classes}
                    sx={{
                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                        transform: "rotate(90deg)",
                      },
                    }}
                  >
                    <div className={styles.FAQTitle}>
                      What upgrades can I finance?
                    </div>
                  </AccordionSummary>
                  <AccordionDetails classes={Accclasses}>
                    <p className={styles.FAQContent}>
                      Our financing product is designed to support clean energy
                      and energy efficiency projects. For most customers, that
                      includes upgrading their property&apos;s HVAC system to an
                      all-electric, ENERGYSTAR heat pump. But it also can
                      include solar and storage, EV charging, electric panels
                      and wiring, domestic hot water and appliances like
                      induction ranges.
                    </p>
                    <p className={styles.FAQContent}>
                      Plus, it can include other upgrades that support the clean
                      energy project. This could be a new roof to accommodate
                      solar or HVAC siting, as well as insulation, windows and
                      facade repair to tighten your building&apos;s envelope or
                      mold and asbestos remediation required to advance your
                      retrofit.
                    </p>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
            {isMQL && (
              <>
                <Divider className={styles.Divider} />
                <Accordion
                  classes={classesAcc}
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowRightIcon
                        style={{ fontSize: 55, color: "#4B33E2" }}
                      />
                    }
                    aria-controls="panel4a-content"
                    id="panel4a-header"
                    classes={classes}
                    sx={{
                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                        transform: "rotate(90deg)",
                      },
                    }}
                  >
                    <div className={styles.FAQTitle}>
                      Why do owners choose to finance upgrades?
                    </div>
                  </AccordionSummary>
                  <AccordionDetails classes={Accclasses}>
                    <p className={styles.FAQContent}>
                      Opting for a $0 down lease allows owners to preserve
                      working capital or afford a major capital improvement that
                      is otherwise out of reach. Instead of making a large
                      upfront payment, you can spread the cost over manageable,
                      fixed monthly payments.
                    </p>
                    <p className={styles.FAQContent}>
                      For many customers, monthly lease payments can also be
                      tax-deductible as operational expenses, which can mean
                      significant savings at tax time. Customers may
                      additionally be able to take advantage of accelerated
                      depreciation. Make sure to talk to a tax advisor to
                      explore the advantages that apply to your specific
                      situation.
                    </p>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
            <Divider className={styles.Divider} />
            <Accordion
              classes={classesAcc}
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowRightIcon style={{ fontSize: 55, color: "#4B33E2" }} />
                }
                aria-controls="panel5a-content"
                id="panel5a-header"
                classes={classes}
                sx={{
                  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                    transform: "rotate(90deg)",
                  },
                }}
              >
                <div className={styles.FAQTitle}>
                  What incentives are available?
                </div>
              </AccordionSummary>
              <AccordionDetails classes={Accclasses}>
                <p className={styles.FAQContent}>
                  Many utilities, states and counties have incentive programs
                  and BlocPower makes it our business to not only know the inner
                  workings of all programs in our service area, but to
                  completely cover the discount ourselves.
                </p>
                <p className={styles.FAQContent}>
                  Even if it takes weeks or months to get paid back, BlocPower
                  handles all the paperwork and administration so you don&apos;t
                  have to.
                </p>
                <p className={styles.FAQContent}>
                  We&apos;ve provided an initial estimate of your incentives in
                  this report, including new federal incentives through the
                  Inflation Reduction Act. Schedule time with your BlocPower
                  representative to learn more.
                </p>
              </AccordionDetails>
            </Accordion>
            <Divider className={styles.Divider} />
            <Accordion
              classes={classesAcc}
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowRightIcon style={{ fontSize: 55, color: "#4B33E2" }} />
                }
                aria-controls="panel6a-content"
                id="panel6a-header"
                classes={classes}
                sx={{
                  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                    transform: "rotate(90deg)",
                  },
                }}
              >
                <div className={styles.FAQTitle}>
                  How long does it take to install the new system?
                </div>
              </AccordionSummary>
              <AccordionDetails classes={Accclasses}>
                <p className={styles.FAQContent}>
                  Installation times can vary based on complexity of project,
                  age of building, type of system and several other factors. In
                  a multifamily building, it takes an average of 3 days per unit
                  to install a new system.
                </p>
              </AccordionDetails>
            </Accordion>
            {isMQL && hasProjectManagement && (
              <>
                <Divider className={styles.Divider} />
                <Accordion
                  classes={classesAcc}
                  expanded={expanded === "panel7"}
                  onChange={handleChange("panel7")}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowRightIcon
                        style={{ fontSize: 55, color: "#4B33E2" }}
                      />
                    }
                    aria-controls="panel7a-content"
                    id="panel7a-header"
                    classes={classes}
                    sx={{
                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                        transform: "rotate(90deg)",
                      },
                    }}
                  >
                    <div className={styles.FAQTitle}>
                      What steps can I expect if we partner on my project?
                    </div>
                  </AccordionSummary>
                  <AccordionDetails classes={Accclasses}>
                    <p className={styles.FAQContent}>
                      Our team walks with you through the whole process of
                      scoping, designing and implementing your new system.
                      <ul>
                        <li>
                          <b>Qualify:</b> Fill out a brief questionnaire about
                          your home or building. [DONE]
                        </li>
                        <li>
                          <b>Schedule:</b> Schedule a call with BlocPower when
                          it&apos;s convenient for you.
                        </li>
                        <li>
                          <b>Propsal:</b> Review detailed proposed scope of work
                          (SOW) and financing options.
                        </li>
                        <li>
                          <b>Contract:</b> Sign project plan, SOW and preferred
                          method of financing.
                        </li>
                        <li>
                          <b>Construction:</b> BlocPower manages projects to
                          ensure high-quality and on-time installation.
                        </li>
                        <li>
                          <b>Peace of Mind:</b> We handle maintenance system
                          performance for 15+ years.
                        </li>
                      </ul>
                    </p>
                    <p className={styles.FAQContent}>
                      Learn more about our process{" "}
                      <a href="https://www.blocpower.io/process">here</a>.
                    </p>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
            <Divider className={styles.Divider} />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

FAQs.propTypes = {
  isMQL: PropTypes.bool,
  hasFinancing: PropTypes.bool,
  hasProjectManagement: PropTypes.bool,
}

FAQs.defaultProps = {}

export default FAQs
