import blocLink from "utils/js/blocLink"
// IBRv1 actions
export const submitBuildingInfo = (id, data) => dispatch => {
  dispatch({ type: "SubmitBuildingInfoRequested" })
  return blocLink
    .put(`v1/building/${id}`, JSON.stringify(data))
    .then(({ data }) => dispatch({ type: "SubmitBuildingInfoSucceeded", data }))
    .catch(err => {
      const errorResponse = err.response ? err.response : { status: 502 }
      dispatch({
        type: "SubmitBuildingInfoFailed",
        data: errorResponse.data,
      })
    })
}

export const submitRequalifyInfo = (id, data) => dispatch => {
  dispatch({ type: "RequalifyInfoRequested" })
  return blocLink
    .put(`v1/building/${id}`, JSON.stringify(data))
    .then(({ data }) => dispatch({ type: "RequalifyInfoSucceeded", data }))
    .catch(err => {
      const errorResponse = err.response ? err.response : { status: 502 }
      dispatch({
        type: "RequalifyInfoFailed",
        data: errorResponse.data,
      })
    })
}

export const fetchReportInfo = id => dispatch => {
  dispatch({ type: "ReportInfoRequested" })
  return blocLink
    .get(`v1/building/${id}`)
    .then(({ data }) => dispatch({ type: "ReportInfoSucceeded", data }))
    .catch(err => {
      const errorResponse = err.response ? err.response : { status: 502 }
      dispatch({
        type: "ReportInfoFailed",
        data: errorResponse.data,
      })
    })
}

export const fetchBmmgReportInfo = bmmgId => dispatch => {
  dispatch({ type: "BmmgReportInfoRequested" })
  return blocLink
    .get(`v1/bmmgreport/${bmmgId}`)
    .then(({ data }) => dispatch({ type: "BmmgReportInfoSucceeded", data }))
    .catch(err => {
      const errorResponse = err.response ? err.response : { status: 502 }
      dispatch({
        type: "BmmgReportInfoFailed",
        data: errorResponse.data,
      })
    })
}

export const submitAddress = data => {
  return dispatch => {
    dispatch({ type: "AddressSubmissionRequested" })
    return blocLink
      .post(`v1/building/`, JSON.stringify(data))
      .then(res => dispatch({ type: "AddressSubmissionSucceeded", data: res }))
      .catch(err => {
        const errorResponse = err.response ? err.response : { status: 502 }
        dispatch({
          type: "AddressSubmissionFailed",
          data: errorResponse.data,
        })
      })
  }
}

// IBRv2 actions
export const submitBuildingAddress = data => dispatch => {
  dispatch({ type: "BuildingAddressSubmissionRequested" })
  let submission_source = "IBR"
  if (data.submission_source != undefined) {
    submission_source = data.submission_source
  }
  return blocLink
    .post(
      `v2/submission/`,
      JSON.stringify({ ...data, submission_source: submission_source })
    )
    .then(res =>
      dispatch({ type: "BuildingAddressSubmissionSucceeded", data: res })
    )
    .catch(err => {
      const errorResponse = err.response ? err.response : { status: 502 }
      dispatch({
        type: "BuildingAddressSubmissionFailed",
        data: errorResponse.data,
      })
    })
}

export const submitBuildingSubmissionInfo =
  (submissionId, data) => dispatch => {
    dispatch({ type: "BuildingInfoSubmissionRequested" })
    return blocLink
      .put(`v2/submission/${submissionId}`, JSON.stringify(data))
      .then(res =>
        dispatch({ type: "BuildingInfoSubmissionSucceeded", data: res })
      )
      .catch(err => {
        const errorResponse = err.response ? err.response : { status: 502 }
        dispatch({
          type: "BuildingInfoSubmissionFailed",
          data: errorResponse.data,
        })
      })
  }

export const submitProjectSubmissionInfo = (submissionId, data) => dispatch => {
  dispatch({ type: "ProjectInfoSubmissionRequested" })
  return blocLink
    .put(`v2/submission/${submissionId}`, JSON.stringify(data))
    .then(res =>
      dispatch({ type: "ProjectInfoSubmissionSucceeded", data: res })
    )
    .catch(err => {
      const errorResponse = err.response ? err.response : { status: 502 }
      dispatch({
        type: "ProjectInfoSubmissionFailed",
        data: errorResponse.data,
      })
    })
}

export const fetchAssessmentInfo = id => dispatch => {
  dispatch({ type: "AssessmentInfoRequested" })
  return blocLink
    .get(`v2/submission/${id}`)
    .then(({ data }) => dispatch({ type: "AssessmentInfoSucceeded", data }))
    .catch(err => {
      const errorResponse = err.response ? err.response : { status: 502 }
      dispatch({
        type: "AssessmentInfoFailed",
        data: errorResponse.data,
      })
    })
}
