import React from "react"
import PropTypes from "prop-types"
import styles from "./BMMGReportTop.module.scss"
import ReportHeader from "../ReportHeader"
import ProjectRecommendationSection from "../ProjectRecommendationSection"
import ScopeOfWorkSection from "../ScopeOfWorkSection"
import BMMGBuildingInfo from "../BMMGBuildingInfo"
import BMMGBackdrop from "../BMMGBackdrop"
import BMMGSavings from "../BMMGSavings"
import BMMGIncentives from "../BMMGIncentives"
import { Grid } from "@mui/material"

const BMMGReportTop = ({ reportInfo }) => {
  return (
    <div className={styles.BMMGReportTop} data-testid="BMMGReportTop">
      <ReportHeader reportInfo={reportInfo} />
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        className={styles.BMMGReportTopGrid}
      >
        <Grid item>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            className={styles.BMMGReportTopInnerGrid}
          >
            <Grid item>
              <ProjectRecommendationSection {...reportInfo} />
            </Grid>
            <Grid item>
              <ScopeOfWorkSection {...reportInfo} />
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              className={styles.BMMGMetrics}
            >
              <BMMGSavings {...reportInfo} />
              <BMMGIncentives {...reportInfo} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            justifyContent="space-around"
            alignItems="center"
            spacing={8}
          >
            <Grid item>
              <BMMGBackdrop {...reportInfo} />
            </Grid>
            <Grid item>
              <BMMGBuildingInfo {...reportInfo} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

BMMGReportTop.propTypes = {
  reportInfo: PropTypes.object,
}

BMMGReportTop.defaultProps = {}

export default BMMGReportTop
