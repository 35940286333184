import React from "react"
import PropTypes from "prop-types"
import styles from "./SanJoseLandingPage.module.scss"

const SanJoseLandingPage = () => (
  <div className={styles.SanJoseLandingPage} data-testid="SanJoseLandingPage">
    {window.location.replace(process.env.REACT_APP_SAN_JOSE_LANDING_PAGE_URL)}
  </div>
)

SanJoseLandingPage.propTypes = {}

SanJoseLandingPage.defaultProps = {}

export default SanJoseLandingPage
