import React from "react"
import PropTypes from "prop-types"
import styles from "./BMMGBuildingInfo.module.scss"
import Button from "components/Reusables/Button"
import { Grid } from "@mui/material"

const BMMGBuildingInfo = reportInfo => {
  const is_old_building = reportInfo.is_old_building ? "Yes" : "No"
  const missing_data = <a href="https://staging.blocpower.io">Add data</a>
  const mapping = {
    building_type: {
      SINGLE_FAM: "Single Family Residential",
      SMALL_RES: "Small Residential (1-4 Units)",
      MULTI_FAM: "Multifamily Residential (5+ Units)",
      MIXED_USE: "Mixed Use (Commercial / Residential)",
      LARGE_CRE: "Large CRE / Industrial",
      HOW: "House of Worship / Religious Institution",
      SMALL_COMM: "Small Commercial",
      COMM: "Commercial",
      SCHOOL: "School",
      WAREHOUSE: "Warehouse",
      OTHER: "Other",
    },
    heating_system_type: {
      STEAM: "Steam",
      HOT_WATER: "Hot Water",
      FORCED_AIR: "Forced Air",
      ELECT_RESIST: "Electric Resistance",
      HEAT_PUMP: "Heat Pump",
      NO_HEATING_SYS: "No heating system",
    },
    heating_fuel_source: {
      OIL: "Oil",
      PROPANE: "Propane",
      NATURAL_GAS: "Natural Gas",
      ELECT_RESIST: "Electric Resistance",
      DUAL_FUEL: "Dual Fuel",
      OTHER: "Other",
      NO_HEATING_SYS: "No heating system",
    },
    heating_system_age: {
      NEW: "New (0-3 years)",
      PRETTY_NEW: "Pretty New (4-10 years)",
      PRETTY_OLD: "Pretty Old (11-20 years)",
      OLD: "Old (21+ years)",
      NOT_SURE: "Not sure",
      NO_HEATING_SYS: "No heating system",
    },
  }
  return (
    <div className={styles.BMMGBuildingInfo} data-testid="BMMGBuildingInfo">
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="flex-start"
        className={styles.GridLayout}
      >
        <Grid item className={styles.CombinedSectionTitle}>
          <div className={styles.SectionTitle}>Your Building</div>
          <div className={styles.SectionSubtitle}>
            Update incorrect or missing information for more accurate estimates.
          </div>
        </Grid>
        <Grid item>
          <Button
            onClick={() =>
              (window.location.href = "https://staging.blocpower.io")
            }
            styles={styles}
            arrow=""
          >
            EDIT
          </Button>
        </Grid>
      </Grid>
      <div className={styles.FloatingCells}>
        <div className={styles.BuildingInfoTable}>
          <div className={styles.InfoLabel}>Building Type</div>
          <div className={styles.InfoValue}>
            {mapping.building_type[reportInfo.building_type]}
          </div>
        </div>
        <div className={styles.BuildingInfoTable}>
          <div className={styles.InfoLabel}>Number of Units</div>
          <div className={styles.InfoValue}>{reportInfo.num_units}</div>
        </div>
        <div className={styles.BuildingInfoTable}>
          <div className={styles.InfoLabel}>More than 15 years old?</div>
          <div className={styles.InfoValue}>{is_old_building}</div>
        </div>
        <div className={styles.BuildingInfoTable}>
          <div className={styles.InfoLabel}>Square Footage</div>
          <div className={styles.InfoValue}>{reportInfo.square_footage}</div>
        </div>
        <div className={styles.BuildingInfoTable}>
          <div className={styles.InfoLabel}>Heating System Type</div>
          <div className={styles.InfoValue}>
            {mapping.heating_system_type[reportInfo.heating_system_type]}
          </div>
        </div>
        <div className={styles.BuildingInfoTable}>
          <div className={styles.InfoLabel}>Heating Fuel Source</div>
          <div className={styles.InfoValue}>
            {mapping.heating_fuel_source[reportInfo.heating_fuel_source]}
          </div>
        </div>
        <div className={styles.BuildingInfoTable}>
          <div className={styles.InfoLabel}>Heating System Age</div>
          <div className={styles.InfoValue}>
            {mapping.heating_system_age[reportInfo.heating_system_age]}
          </div>
        </div>
        <div className={styles.BuildingInfoTable}>
          <div className={styles.InfoLabel}>Cooling System Age</div>
          <div className={styles.InfoValue}>{missing_data}</div>
        </div>
      </div>
    </div>
  )
}

BMMGBuildingInfo.propTypes = {}

BMMGBuildingInfo.defaultProps = {}

export default BMMGBuildingInfo
