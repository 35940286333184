import React from "react"
import styles from "./FaqSection.module.scss"
import Faq from "react-faq-component"
import FAQImage from "images/FAQImage.svg"
import FAQBackground from "images/FAQBackground.svg"

const config = {
  expandIcon: "+",
  collapseIcon: "-",
}

const FaqSection = reportInfo => {
  let isCambridgeLead = reportInfo?.is_cambridge_lead
  let isComedLead = reportInfo?.is_comed_lead
  let heatAndHotWaterText = isComedLead ? (
    <>
      <p>
        Heat pumps provide highly efficient electric heating and cooling by
        transferring heat from the surrounding environment, rather than
        generating it.
      </p>
      <p>
        They work similar to an air conditioner in the summer, and like an air
        conditioner in reverse during the winter. To heat a home, heat pumps
        pull heat from the outside environment and move it to the indoor
        environment, and to cool a home, heat pumps transfer heat from the
        indoor environment to the outdoor environment.
      </p>
    </>
  ) : (
    <>
      <p>
        Heating, cooling and hot water system equipment that is powered by
        electricity — not the combustion of fossil fuels — use ambient air from
        outside the building or the ground as either a heat source or heat sink.
        Check out this{" "}
        <a
          target="_blank"
          href="https://www.blocpower.io/posts/what-is-an-air-source-heat-pump"
          rel="noreferrer"
        >
          resource guide
        </a>{" "}
        for a deeper look, or read on for a high-level overview.
      </p>
      <p>
        This technology, called a heat pump, gets its name because it moves heat
        into a building — it doesn’t generate it as furnaces and boilers do.
        Heat pumps also function in reverse, acting as an air conditioner. In
        heating mode, the ambient air from outside the building acts as the heat
        source. Modern technology makes this possible even when the ambient air
        is cold; the heat pump’s refrigerant can extract heat even in cold
        climates.
      </p>
      <p>
        Like any heating and cooling system, electricity-powered equipment are
        at their best in a home or building that maximizes efficiency, which is
        why BlocPower offers these systems in conjunction with other efficiency
        measures as a part of the Climate Comfort Service.
      </p>
    </>
  )
  let data = {
    rows: [
      {
        title: (
          <p className={styles.FaqQTitle}>
            <span className={styles.FaqQText}>
              How does all-electric heat and hot water work?
            </span>
          </p>
        ),
        content: (
          <span className={styles.FaqASection}>
            <span className={styles.FaqAText}>{heatAndHotWaterText}</span>
          </span>
        ),
      },
      {
        title: (
          <p className={styles.FaqQTitle}>
            <span className={styles.FaqQText}>
              How much does the BlocPower Climate Comfort Service cost?
            </span>
          </p>
        ),
        content: (
          <span className={styles.FaqASection}>
            <span className={styles.FaqAText}>
              <p>
                At BlocPower, we know that every home and building is unique, as
                are their owners. That’s why our Climate Comfort Service can
                encompass as much or as little of your home or building as you
                like. For those reasons, project and retrofit costs can vary.
              </p>
              <p>
                Whatever you choose, we offer no-money-down leases with low,
                fixed monthly payments that can often be paid back by the
                savings obtained from moving away from inefficient
                {!isComedLead && ", dirty"} fossil fuel-reliant equipment. Many
                customers often see savings between 20-70%! Learn more about our
                innovative financing tools that never require a lien on your
                property{" "}
                <a href="https://www.blocpower.io/posts/energy-service-agreements">
                  here
                </a>
                {". "}
                Read more about how heat pumps can save you money{" "}
                <a href="https://www.blocpower.io/posts/do-heat-pumps-save-money">
                  here
                </a>
                {"."}
              </p>
            </span>
          </span>
        ),
      },
      {
        title: (
          <p className={styles.FaqQTitle}>
            <span className={styles.FaqQText}>
              How long does it take to install the new system?
            </span>
          </p>
        ),
        content: (
          <span className={styles.FaqASection}>
            <span className={styles.FaqAText}>
              <p>
                Installation times can vary based on complexity of project, age
                of building, type of system and several other factors. In a
                multifamily building, it takes an average of 3 days per unit to
                install a new system.
              </p>
            </span>
          </span>
        ),
      },
      {
        title: (
          <p className={styles.FaqQTitle}>
            <span className={styles.FaqQText}>
              How does the BlocPower Process work?
            </span>
          </p>
        ),
        content: (
          <span className={styles.FaqASection}>
            <span className={styles.FaqAText}>
              <p>
                Our team walks with you through the whole process of scoping,
                designing and implementing your new system.
              </p>
              <ol className={styles.FaqAList}>
                <li>Free Estimate with an Instant Building Report</li>
                <li>Provide Lease Quoted Statement of Work</li>
                <li>Sign Agreement</li>
                <li>Finalize Project</li>
                <li>Installation</li>
                <li>
                  Ongoing Maintenance with BlocPower’s Customer Success Team
                </li>
              </ol>
              <p>
                Learn more about our process{" "}
                <a href="https://www.blocpower.io/#the-process"> here </a>
                {"."}
              </p>
            </span>
          </span>
        ),
      },
    ],
  }
  const cambridgeQuestions = [
    {
      title: (
        <p className={styles.FaqQTitle}>
          <span className={styles.FaqQText}>What upgrades are available?</span>
        </p>
      ),
      content: (
        <span className={styles.FaqASection}>
          <span className={styles.FaqAText}>
            <p>
              The estimates in this report are inclusive of all of BlocPower’s
              available upgrades: Electric Air Source Heat Pump for Heating and
              Cooling, Heat Pump Water Heater, Weatherstripping for Exterior
              Windows, and Community Solar Subscription.
            </p>
            <p>
              When you speak to the BlocPower team, an expert will help you
              assess what specific upgrades are best fit for your needs and
              preferences.
            </p>
          </span>
        </span>
      ),
    },
    {
      title: (
        <p className={styles.FaqQTitle}>
          <span className={styles.FaqQText}>
            How can I learn about other Cambridge energy programs?
          </span>
        </p>
      ),
      content: (
        <span className={styles.FaqASection}>
          <span className={styles.FaqAText}>
            <p>
              If you’re interested in learning about other programs that the
              City of Cambridge offers, including low-income programs, retrofit
              advising, and Cambridge Community Electricity, visit the City’s
              energy programs page at{" "}
              <a
                target="_blank"
                href="https://cambridgeenergyalliance.org/"
                rel="noreferrer"
              >
                CambridgeEnergyAlliance.org
              </a>{" "}
              or call the Cambridge Energy Helpline at (857) 309-5080.
            </p>
          </span>
        </span>
      ),
    },
  ]

  if (isCambridgeLead) {
    {
      cambridgeQuestions.map(question => data["rows"].push(question))
    }
  }

  return (
    <div className={styles.FaqSection} data-testid="FaqSection">
      <div className={styles.FaqHeader}>
        <div className={styles.FaqTitle}> Frequently Asked Questions </div>
        <img alt="FAQImage" src={FAQImage} className={styles.FaqImage} />
      </div>
      <div className={styles.FaqContent}>
        <Faq data={data} config={config} />
        <img
          alt="FAQBackground"
          src={FAQBackground}
          className={styles.FaqBackground}
        />
      </div>
    </div>
  )
}

FaqSection.propTypes = {}

FaqSection.defaultProps = {}

export default FaqSection
