import React from "react"
import styles from "./FinancingLandingPage.module.scss"

const FinancingLandingPage = () => (
  <div
    className={styles.FinancingLandingPage}
    data-testid="FinancingLandingPage"
  >
    {window.location.replace(process.env.REACT_APP_FINANCING_PAGE_URL)}
  </div>
)

FinancingLandingPage.propTypes = {}

FinancingLandingPage.defaultProps = {}

export default FinancingLandingPage
