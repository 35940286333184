const intialState = {
  buildingInfo: {
    status: "idle",
    data: undefined,
    isError: false,
  },
  addressSubmission: {
    status: "idle",
    data: undefined,
    isError: false,
  },
  reportInfo: {
    status: "idle",
    data: undefined,
    isError: false,
  },
  bmmgReportInfo: {
    status: "idle",
    data: undefined,
    isError: false,
  },
  requalifyInfo: {
    status: "idle",
    data: undefined,
    isError: false,
  },
  buildingAddressSubmission: {
    status: "idle",
    data: undefined,
    isError: false,
  },
  buildingInfoSubmission: {
    status: "idle",
    data: undefined,
    isError: false,
  },
  projectInfoSubmission: {
    status: "idle",
    data: undefined,
    isError: false,
  },
  assessmentInfo: {
    status: "idle",
    data: undefined,
    isError: false,
  },
}

const reducer = (state = intialState, action) => {
  switch (action.type) {
    case "SubmitBuildingInfoRequested":
      return {
        ...state,
        buildingInfo: {
          ...state.buildingInfo,
          status: "loading",
          isError: false,
        },
      }
    case "SubmitBuildingInfoSucceeded":
      return {
        ...state,
        buildingInfo: {
          data: action.data,
          status: "succeeded",
          isError: false,
        },
      }
    case "SubmitBuildingInfoFailed":
      return {
        ...state,
        buildingInfo: {
          data: action.data,
          status: "failed",
          isError: true,
        },
      }
    case "RequalifyInfoRequested":
      return {
        ...state,
        requalifyInfo: {
          ...state.requalifyInfo,
          status: "loading",
          isError: false,
        },
      }
    case "RequalifyInfoSucceeded":
      return {
        ...state,
        requalifyInfo: {
          data: action.data,
          status: "succeeded",
          isError: false,
        },
      }
    case "RequalifyInfoFailed":
      return {
        ...state,
        requalifyInfo: {
          data: action.data,
          status: "failed",
          isError: true,
        },
      }
    case "AddressSubmissionRequested":
      return {
        ...state,
        addressSubmission: {
          ...state.addressSubmission,
          status: "loading",
          isError: false,
        },
      }
    case "AddressSubmissionSucceeded":
      return {
        ...state,
        addressSubmission: {
          data: action.data,
          status: "succeeded",
          isError: false,
        },
      }
    case "AddressSubmissionFailed":
      return {
        ...state,
        addressSubmission: {
          data: action.data,
          status: "failed",
          isError: true,
        },
      }
    case "ReportInfoRequested":
      return {
        ...state,
        reportInfo: {
          ...state.reportInfo,
          status: "loading",
          isError: false,
        },
      }
    case "ReportInfoSucceeded":
      return {
        ...state,
        reportInfo: {
          data: action.data,
          status: "succeeded",
          isError: false,
        },
      }
    case "ReportInfoFailed":
      return {
        ...state,
        reportInfo: {
          data: action.data,
          status: "failed",
          isError: true,
        },
      }
    case "BmmgReportInfoRequested":
      return {
        ...state,
        bmmgReportInfo: {
          ...state.bmmgReportInfo,
          status: "loading",
          isError: false,
        },
      }
    case "BmmgReportInfoSucceeded":
      return {
        ...state,
        bmmgReportInfo: {
          data: action.data,
          status: "succeeded",
          isError: false,
        },
      }
    case "BmmgReportInfoFailed":
      return {
        ...state,
        bmmgReportInfo: {
          data: action.data,
          status: "failed",
          isError: true,
        },
      }
    case "BuildingAddressSubmissionRequested":
      return {
        ...state,
        buildingAddressSubmission: {
          ...state.buildingAddressSubmission,
          status: "loading",
          isError: false,
        },
      }
    case "BuildingAddressSubmissionSucceeded":
      return {
        ...state,
        buildingAddressSubmission: {
          data: action.data,
          status: "succeeded",
          isError: false,
        },
      }
    case "BuildingAddressSubmissionFailed":
      return {
        ...state,
        buildingAddressSubmission: {
          data: action.data,
          status: "failed",
          isError: true,
        },
      }
    case "BuildingInfoSubmissionRequested":
      return {
        ...state,
        buildingInfoSubmission: {
          ...state.buildingInfoSubmission,
          status: "loading",
          isError: false,
        },
      }
    case "BuildingInfoSubmissionSucceeded":
      return {
        ...state,
        buildingInfoSubmission: {
          data: action.data,
          status: "succeeded",
          isError: false,
        },
      }
    case "BuildingInfoSubmissionFailed":
      return {
        ...state,
        buildingInfoSubmission: {
          data: action.data,
          status: "failed",
          isError: true,
        },
      }
    case "ProjectInfoSubmissionRequested":
      return {
        ...state,
        projectInfoSubmission: {
          ...state.projectInfoSubmission,
          status: "loading",
          isError: false,
        },
      }
    case "ProjectInfoSubmissionSucceeded":
      return {
        ...state,
        projectInfoSubmission: {
          data: action.data,
          status: "succeeded",
          isError: false,
        },
      }
    case "ProjectInfoSubmissionFailed":
      return {
        ...state,
        projectInfoSubmission: {
          data: action.data,
          status: "failed",
          isError: true,
        },
      }
    case "AssessmentInfoRequested":
      return {
        ...state,
        assessmentInfo: {
          ...state.assessmentInfo,
          status: "loading",
          isError: false,
        },
      }
    case "AssessmentInfoSucceeded":
      return {
        ...state,
        assessmentInfo: {
          data: action.data,
          status: "succeeded",
          isError: false,
        },
      }
    case "AssessmentInfoFailed":
      return {
        ...state,
        assessmentInfo: {
          data: action.data,
          status: "failed",
          isError: true,
        },
      }
    default:
      return state
  }
}

export default reducer
