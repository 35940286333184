import React from "react"
import PropTypes from "prop-types"
import styles from "./OpportunityScore.module.scss"
import {
  Grid,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import ArrowRightIcon from "@mui/icons-material/ArrowRight"

const OpportunityScore = ({ reportInfo, hasFinancing }) => {
  const [expanded, setExpanded] = React.useState(false)
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const useStyle = makeStyles(() => ({
    content: {
      flexDirection: "column",
    },
    root: {
      flexDirection: "row-reverse",
      boxShadow: "none",
      padding: 0,
      "&:hover": {
        borderRadius: "4px",
        background: "rgba(75, 51, 226, 0.08)",
      },
    },
    expanded: {
      "&:before": {
        opacity: 1,
      },
    },
  }))
  const useAccStyle = makeStyles(() => ({
    root: {
      boxShadow: "none",
    },
  }))
  const useStyleAcc = makeStyles(() => ({
    root: {
      paddingLeft: "55px",
    },
  }))
  const classesAcc = useAccStyle()
  const classes = useStyle()
  const Accclasses = useStyleAcc()
  let showCarbonEmission = false
  let showFinancing = false
  if (reportInfo?.project_outcomes) {
    showCarbonEmission =
      Object.values(reportInfo?.project_outcomes).includes("IMPROVE_HEALTH") ||
      Object.values(reportInfo?.project_outcomes).includes(
        "REDUCE_GAS_EMISSIONS"
      ) ||
      reportInfo?.heating_source_type != "OIL"
    showFinancing =
      Object.values(reportInfo?.project_outcomes).includes(
        "FINANCE_UPGRADES"
      ) ||
      (reportInfo?.heating_source_type != "OIL" &&
        !["ELECT_RESIST", "PTAC"].includes(reportInfo?.heating_system_type))
  }
  showFinancing = hasFinancing
  return (
    <div className={styles.OpportunityScore} data-testid="OpportunityScore">
      <Grid container spacing={2} alignItems="center" flexWrap={"nowrap"}>
        <Grid item>
          <div className={styles.OpportunityNumberSec}>
            <div className={styles.OpportunityNumber}>
              {reportInfo?.score}/10
            </div>
          </div>
        </Grid>
        <Grid item>
          <div className={styles.OpportunityHeaderText}>
            Retrofit Opportunity Score
          </div>
          <div className={styles.OpportunityRegularText}>
            Determined by your building typology, existing HVAC system and
            potential to benefit from upgrades.
          </div>
        </Grid>
      </Grid>
      <Divider className={styles.Divider} />
      <Grid container spacing={2} alignItems="center">
        <Grid item sm={12}>
          <div className={styles.OpportunityHeaderText}>
            Top Opportunities for Your Building
          </div>
        </Grid>
        <Grid item sm={12}>
          <div className={styles.OpportunityList}>
            {reportInfo?.heating_source_type == "OIL" && (
              <Accordion
                classes={classesAcc}
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={
                    <ArrowRightIcon
                      style={{ fontSize: 55, color: "#4B33E2" }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  classes={classes}
                  sx={{
                    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                      transform: "rotate(90deg)",
                    },
                  }}
                >
                  <div className={styles.OpportunityTitle}>
                    Reduce Operating Costs
                  </div>
                  <div className={styles.OpportunitySubTitle}>
                    Estimated 20-30% annual savings
                  </div>
                </AccordionSummary>
                <AccordionDetails classes={Accclasses}>
                  Heat pump systems are 2-4X more efficient and typically result
                  in 20-30% lower heating bills than oil, which has seen prices
                  rise{" "}
                  <a
                    href="https://www.eia.gov/dnav/pet/hist/LeafHandler.ashx?n=PET&s=M_EPD2F_PRS_R1X_DPG&f=M"
                    target="_blank"
                    rel="noreferrer"
                  >
                    85%
                  </a>{" "}
                  from Jan &apos;21 to &apos;23. Combined with smart monitoring,
                  heat pumps also ensure reliable performance.
                </AccordionDetails>
              </Accordion>
            )}
            {["ELECT_RESIST", "PTAC"].includes(
              reportInfo?.heating_system_type
            ) && (
              <Accordion
                classes={classesAcc}
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={
                    <ArrowRightIcon
                      style={{ fontSize: 55, color: "#4B33E2" }}
                    />
                  }
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  classes={classes}
                  sx={{
                    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                      transform: "rotate(90deg)",
                    },
                  }}
                >
                  <div className={styles.OpportunityTitle}>
                    Reduce Operating Costs
                  </div>
                  <div className={styles.OpportunitySubTitle}>
                    Cut heating bills in half
                  </div>
                </AccordionSummary>
                <AccordionDetails classes={Accclasses}>
                  Heat pumps use less than half the energy of other electric
                  heating systems including PTACs or baseboards. In addition to
                  lowering heating bills by 50% or more, most heat pumps systems
                  come with 10+ year equipment warranties.
                </AccordionDetails>
              </Accordion>
            )}
            <Accordion
              classes={classesAcc}
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowRightIcon style={{ fontSize: 55, color: "#4B33E2" }} />
                }
                aria-controls="panel3a-content"
                id="panel3a-header"
                classes={classes}
                sx={{
                  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                    transform: "rotate(90deg)",
                  },
                }}
              >
                <div className={styles.OpportunityTitle}>
                  Improve Occupant Comfort
                </div>
                <div className={styles.OpportunitySubTitle}>
                  Goodbye complaints!
                </div>
              </AccordionSummary>
              <AccordionDetails classes={Accclasses}>
                Introducing your all-in-one heating and cooling system. Heat
                pumps provide precise, room-by-room temperature control in all
                seasons for dependable comfort. They steadily and quietly
                deliver conditioned and filtered air. No more uneven
                temperatures, no more complaints!
              </AccordionDetails>
            </Accordion>
            {showCarbonEmission && (
              <Accordion
                classes={classesAcc}
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={
                    <ArrowRightIcon
                      style={{ fontSize: 55, color: "#4B33E2" }}
                    />
                  }
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                  classes={classes}
                  sx={{
                    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                      transform: "rotate(90deg)",
                    },
                  }}
                >
                  <div className={styles.OpportunityTitle}>
                    Cut Carbon Emissions
                  </div>
                  <div className={styles.OpportunitySubTitle}>
                    35-93% lifetime reduction
                  </div>
                </AccordionSummary>
                <AccordionDetails classes={Accclasses}>
                  Over 15 years, a heat pump operating on the nation&apos;s most
                  carbon-intensive electric grid will still{" "}
                  <a href="https://rmi.org/now-is-the-time-to-go-all-in-on-heat-pumps/">
                    reduce emissions by 35%
                  </a>{" "}
                  versus a high-efficiency gas furnace. This number rises as
                  high as 93% for states predominantly utilizing clean energy.
                  No more fossil fuel combustion equals cleaner air and
                  healthier communities.
                </AccordionDetails>
              </Accordion>
            )}
            {showFinancing && (
              <Accordion
                classes={classesAcc}
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  expandIcon={
                    <ArrowRightIcon
                      style={{ fontSize: 55, color: "#4B33E2" }}
                    />
                  }
                  aria-controls="panel5a-content"
                  id="panel5a-header"
                  classes={classes}
                  sx={{
                    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                      transform: "rotate(90deg)",
                    },
                  }}
                >
                  <div className={styles.OpportunityTitle}>
                    Utilize Flexible Financing
                  </div>
                  <div className={styles.OpportunitySubTitle}>
                    Complete deferred maintenance
                  </div>
                </AccordionSummary>
                <AccordionDetails classes={Accclasses}>
                  Our $0 down financing makes clean energy projects affordable
                  and can also cover supporting upgrades, including roof
                  replacement, mold and asbestos remediation, new windows,
                  facade repair, electrical and more.
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

OpportunityScore.propTypes = {
  reportInfo: PropTypes.object,
  hasFinancing: PropTypes.bool,
}

OpportunityScore.defaultProps = {}

export default OpportunityScore
