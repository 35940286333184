import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchReportInfo } from "redux/actions"
import { useParams } from "react-router-dom"
import ReportPage from "components/Report/ReportChallenger"
import ReportLoadingScreen from "components/Report/ReportLoadingScreen"

// This dispatches fetchReportInfo and formats the data into Metrics
const ReportPageController = () => {
  const { buildingId } = useParams()
  const dispatch = useDispatch()
  const [isFakeLoading, setFakeLoading] = useState(true)
  useEffect(() => dispatch(fetchReportInfo(buildingId)), [buildingId])

  useEffect(() => {
    setTimeout(() => setFakeLoading(false), 3000)
  }, [])

  const {
    status,
    data: reportInfo,
    isError,
  } = useSelector(state => state.reportInfo)

  const isDataLoading = status === "loading" || status === "idle"
  const showLoading = isFakeLoading || isDataLoading

  if (showLoading) {
    return <ReportLoadingScreen />
  }

  if (isError) {
    reportInfo.message
      ? window.alert(JSON.stringify(reportInfo.message))
      : window.alert("There is an error. Please try again.")
  }
  const metrics = []

  metrics.push({
    label: "Estimated Savings",
    value:
      reportInfo.savings_min === 0 && reportInfo.savings_max === 0
        ? "Not Applicable"
        : `${reportInfo.savings_min}\u2013${reportInfo.savings_max}%`,
    unit: "decrease on monthly energy bill",
  })

  if (reportInfo.incentives_min !== "0" && reportInfo.incentives_max !== "0") {
    metrics.push({
      label: "Estimated Incentives",
      value: `$${reportInfo.incentives_min}\u2013$${reportInfo.incentives_max}`,
      unit: "potential rebates from state, local, federal incentives",
    })
  }

  return (
    <ReportPage
      address={reportInfo?.address}
      metrics={metrics}
      reportInfo={reportInfo}
      ibr="v1"
    />
  )
}

export default ReportPageController
