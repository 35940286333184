import React, { useState } from "react"
import PropTypes from "prop-types"
import { ButtonArraowIcon } from "components/Reusables/Icons"
import { ButtonArraowLightIcon } from "components/Reusables/Icons"
import Button from "components/Reusables/Button"
import { Modal, Backdrop, Fade, Box, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "none",
  border: 0,
  p: 4,
}

const ownerMapping = {
  145025886: "ryan-merkin/ryan-merkin", //Ryan
  145030411: "ryan-merkin/ryan-merkin", //BlocPower-Admin
  369568908: "emily802/emily-ais-step-4",
  90186759: "ian-harris/ian-harris-ais-step-4",
  124939555: "luke-rupcic/luke-ais-step-4",
  124939557: "russ-lindell/russ-ais-step-4",
  280100246: "brody20/brody-vance-ais-step-4",
  459152778: "nick1723/nick-ais-step-4",
  675444272: "sydney-tanaka/sydney-tanaka-ais-step-4",
  195870065: "craig-altemose/ais-step-4",
  405643700: "david-ewing1/david-ewing-ais-step-4",
  389996973: "caitlin-benedict/caitlin-benedict-ais-step-4",
  354496328: "ethan-bodnaruk/ethan-bodnaruk-ais-step-4",
  85939836: "gavin-gratson/gavin-gratson-ais-step-4",
}

const AssessmentButton = ({
  children,
  styles,
  arrow,
  isLoading,
  printable,
  openPrintPage,
  owner,
  url,
}) => {
  let arrowIcon = <ButtonArraowIcon />
  if (arrow == "none") {
    arrowIcon = <></>
  }
  if (arrow == "light") {
    arrowIcon = <ButtonArraowLightIcon />
  }
  //This supports the old link method as first priority then looks to the value from the backend
  const iframeUrl =
    owner && ownerMapping[owner]
      ? "https://meetings.hubspot.com/" + ownerMapping[owner]
      : url
  const [open, setOpen] = useState(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  return (
    <div className={styles.AssessmentButton} data-testid="AssessmentButton">
      {!printable ? (
        <>
          <button
            disabled={isLoading}
            onClick={onOpenModal}
            className={styles.Button}
          >
            {children} {arrowIcon}
          </button>
          <Modal
            open={open}
            onClose={onCloseModal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={modalStyle}>
                <Typography
                  id="transition-modal-description"
                  sx={{ mt: 2 }}
                  style={{ textAlign: "center" }}
                >
                  <CloseIcon
                    onClick={onCloseModal}
                    className={styles.CloseButton}
                  />
                  <iframe
                    title="test"
                    src={iframeUrl}
                    className={styles.Iframe}
                  />
                </Typography>
              </Box>
            </Fade>
          </Modal>
        </>
      ) : (
        <>
          <button
            disabled={isLoading}
            className={styles.Button}
            onClick={openPrintPage}
          >
            Download Your Personal Electrification Guide
          </button>
        </>
      )}
    </div>
  )
}

AssessmentButton.propTypes = {
  children: PropTypes.node.isRequired,
  arrow: PropTypes.string,
  styles: PropTypes.object,
  isLoading: PropTypes.bool,
  owner: PropTypes.number,
  printable: PropTypes.bool,
  openPrintPage: PropTypes.func,
  url: PropTypes.string,
}

AssessmentButton.defaultProps = {
  styles: {},
  children: "",
}

export default AssessmentButton
