import React, { createContext, useContext, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useCookies } from "react-cookie"

// Create a Context
const ExperimentContext = createContext()

// Create a custom hook to use the experiment context
export const useExperiments = () => useContext(ExperimentContext)

export const ExperimentProvider = ({ children }) => {
  const [cookies] = useCookies(["active-experiments"])
  const [activeExperiments, setActiveExperiments] = useState({})

  useEffect(() => {
    document.cookie = "test-cookie"
    const cookiesEnabled =
      navigator.cookieEnabled && document.cookie.indexOf("test-cookie") != -1
    if (!cookiesEnabled) {
      // Handle case where cookies are disabled by setting a default value for active experiments
      setActiveExperiments({
        "BI-7695": 0,
      })
    } else {
      // Check if the "active-experiments" cookie exists and is not just an empty string
      if (cookies["active-experiments"]) {
        // Assuming the value is a JSON string, parse it and update state
        const experiments = cookies["active-experiments"]
        setActiveExperiments(experiments)
      } else {
        // Ensure activeExperiments is set to an empty object if no cookie is found
        setActiveExperiments({})
      }
    }
  }, [cookies]) // Re-run this effect if the cookies object changes
  return (
    <ExperimentContext.Provider value={{ activeExperiments }}>
      {children}
    </ExperimentContext.Provider>
  )
}

ExperimentProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

ExperimentProvider.defaultProps = {}

export default ExperimentProvider
