import React from "react"
import PropTypes from "prop-types"
import styles from "./AccuracyStatement.module.scss"

const AccuracyStatement = () => (
  <div className={styles.AccuracyStatement} data-testid="AccuracyStatement">
    <div className={styles.Title}>How accurate are these estimates?</div>
    <div className={styles.SubTitle}>
      The incentives, cost and energy bill savings estimates are predictions
      with the information we have available. To get a more accurate estimate,
      schedule a call with our expert team.
    </div>
  </div>
)

AccuracyStatement.propTypes = {}

AccuracyStatement.defaultProps = {}

export default AccuracyStatement
