import React from "react"
import PropTypes from "prop-types"
import styles from "./TeamSection.module.scss"
import YourTeam from "../YourTeam/YourTeam"
import { Grid } from "@mui/material"
import AssessmentButton from "../AssessmentButton/AssessmentButton"
import SectionButton from "../SectionButton/SectionButton"

const TeamSection = ({ reportInfo }) => {
  return (
    <div className={styles.TeamSection} data-testid="TeamSection">
      <div className={styles.SectionHeader}>Your Team</div>
      <Grid container rowGap={"1em"}>
        <Grid item xs={12}>
          <div className={styles.HeaderText}>
            Our experts have a combined 50+ years of experience in building
            energy efficiency and electrification
          </div>
          <YourTeam isSanJoseLead={reportInfo?.is_san_jose_lead} />
        </Grid>
        <Grid container spacing={1}>
          <Grid item>
            <AssessmentButton
              styles={styles}
              arrow="none"
              owner={reportInfo?.hubspot_owner_id}
              url={reportInfo?.hubspot_owner_calendar_url}
            >
              Schedule Free Consultation
            </AssessmentButton>
          </Grid>
          <Grid item className={styles.SectionButton}>
            <SectionButton arrow="true" section="faq">
              View FAQs
            </SectionButton>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

TeamSection.propTypes = {
  reportInfo: PropTypes.object,
}

TeamSection.defaultProps = {}

export default TeamSection
