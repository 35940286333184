import React from "react"
import PropTypes from "prop-types"
import styles from "./ReportLoadingScreen.module.scss"
import BuildingLoading from "images/IBR_rebranded_loading.gif"

const ReportLoadingScreen = () => (
  <div className={styles.ReportLoadingScreen} data-testid="ReportLoadingScreen">
    <div className={styles.LoadingContent}> Generating Custom Report... </div>
    <div className={styles.LoadingIconArea}>
      <img
        src={BuildingLoading}
        alt="Fetching the Report"
        className={styles.LoadingIcon}
      />
    </div>
  </div>
)

ReportLoadingScreen.propTypes = {}

ReportLoadingScreen.defaultProps = {}

export default ReportLoadingScreen
