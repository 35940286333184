import React, { useState } from "react"
import PropTypes from "prop-types"
import Button from "components/Reusables/Button"
import { Modal, Backdrop, Fade, Box, Typography } from "@mui/material"

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "none",
  border: 0,
  p: 4,
}

const ownerMapping = {
  145025886: "ryan-merkin/ryan-merkin", //Ryan
  145030411: "ryan-merkin/ryan-merkin", //BlocPower-Admin
  369568908: "emily802/emily-ais-step-4",
  90186759: "ian-harris/ian-harris-ais-step-4",
  124939555: "luke-rupcic/luke-ais-step-4",
  124939557: "russ-lindell/russ-ais-step-4",
  280100246: "brody20/brody-vance-ais-step-4",
  459152778: "nick1723/nick-ais-step-4",
  675444272: "sydney-tanaka/sydney-tanaka-ais-step-4",
  195870065: "craig-altemose/ais-step-4",
  405643700: "david-ewing1/david-ewing-ais-step-4",
  389996973: "caitlin-benedict/caitlin-benedict-ais-step-4",
  354496328: "ethan-bodnaruk/ethan-bodnaruk-ais-step-4",
  85939836: "gavin-gratson/gavin-gratson-ais-step-4",
}

const ActionButton = ({ children, styles, arrow, owner, url }) => {
  const iframeUrl =
    owner && ownerMapping[owner]
      ? "https://meetings.hubspot.com/" + ownerMapping[owner]
      : url
  const [open, setOpen] = useState(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  return (
    <>
      <Button onClick={onOpenModal} styles={styles} arrow={arrow}>
        {children}
      </Button>
      <Modal
        open={open}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={modalStyle}>
            <Typography
              id="transition-modal-description"
              sx={{ mt: 2 }}
              style={{ textAlign: "center" }}
            >
              <iframe title="test" src={iframeUrl} className={styles.Iframe} />
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </>
  )
}

ActionButton.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.object,
  arrow: PropTypes.string,
  owner: PropTypes.number,
  url: PropTypes.string,
}

ActionButton.defaultProps = {
  styles: {},
}

export default ActionButton
