import React from "react"
import PropTypes from "prop-types"
import styles from "./EquipmentUpgrades.module.scss"
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import AshpImage from "images/img_ashp_upgrade.webp"
import DhwImage from "images/img_dhw_upgrade.webp"
import EVImage from "images/img_ev_charger.webp"
import InductionImage from "images/img_induction_upgrade.webp"
import SolarImage from "images/img_solar_upgrade.webp"
import WeatherImage from "images/img_weather_upgrade.webp"
import AshpIcon from "images/icons/ashp_icon.svg"
import DhwIcon from "images/icons/dhw_icon.svg"
import EVIcon from "images/icons/ev_charger_icon.svg"
import IcIcon from "images/icons/ic_icon.svg"
import SolarIcon from "images/icons/solar_pv_icon.svg"
import EPIcon from "images/icons/electrical_panel_icon.svg"

const EquipmentUpgrades = ({ reportInfo }) => {
  const [expanded, setExpanded] = React.useState(false)
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const useStyle = makeStyles(() => ({
    content: {
      margin: 0,
    },
    root: {
      boxShadow: "none",
      padding: 0,
      "&:hover": {
        borderRadius: "4px",
        background: "rgba(75, 51, 226, 0.08)",
      },
    },
    expanded: {
      margin: "0 !important",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: "4px",
      "&:before": {
        opacity: 1,
      },
      "&:first-of-type": {
        margin: 0,
      },
    },
  }))
  const useAccStyle = makeStyles(() => ({
    root: {
      minHeight: "40px",
    },
    content: {
      margin: 0,
    },
  }))
  const classes = useStyle()
  const classesAcc = useAccStyle()
  const sowMapping = {
    ASHP: {
      icon: AshpIcon,
      image: AshpImage,
      title: "Air Source Heat Pump",
      description:
        "Air Source Heat Pumps are effective, low-carbon heating and cooling solutions that use electricity to transfer heat energy in and out of buildings instead of generating heat through combustion of oil or gas. Heat Pumps are 3 to 6 times more energy efficient than conventional heating and cooling technologies.",
    },
    HPWH: {
      icon: DhwIcon,
      image: DhwImage,
      title: "Heat Pump Water Heater",
      description:
        "Heat Pump Water Heaters are effective, low-carbon heating and cooling solutions that use electricity to transfer heat energy in and out of buildings instead of generating heat from oil or gas. Heat Pumps are 2 to 4 times more energy efficient than conventional water heating technologies.",
    },
    INDUCTION_STOVE: {
      icon: IcIcon,
      image: InductionImage,
      title: "Induction Cooktop",
      description:
        "Induction cooking is performed on an electric stove using direct induction heating instead of cooking vessels and is quite efficient, which means it puts out less waste heat and it can be quickly turned on and off. Induction cooktops provide substantial health benefits over gas stoves. They also boil water faster, heat food more consistently, can be controlled at precise temperatures, are easier to clean, and less likely to cause burns.",
    },
    COMM_SOLAR: {
      icon: SolarIcon,
      image: SolarImage,
      title: "Solar Panels",
      description:
        "Solar Photovoltaics (PV) reduce net electric consumption and can generate even more savings by bundling with heat pumps and other electric appliances.",
    },
    AIR_SEALING: {
      icon: EPIcon,
      image: WeatherImage,
      title: "Air Sealing and Insulation",
      description:
        "Cut down on energy waste by sealing air leakages in air ducts, cavities, doors, ramps, and overhead door hinges, as well as caulking windows and doors.",
    },
    EV_CHARGING: {
      icon: EVIcon,
      image: EVImage,
      title: "EV Charging",
      description:
        "Installing EV charging at your building enhances competitiveness by attracting environmentally-conscious tenants and customers while future-proofing your property for the electrification. It generates additional revenue streams, helps with compliance regulations, and improves tenant satisfaction. Moreover, higher incentives, reduced parking congestion, and an enhanced corporate image further justify the investment in EV charging infrastructure.",
    },
  }
  return (
    <div className={styles.EquipmentUpgrades} data-testid="EquipmentUpgrades">
      <div className={styles.HeaderText}>Equipment Upgrades</div>
      <Grid item sm={12}>
        <div className={styles.UpgradesList}>
          {Object.entries(sowMapping)
            .filter(([key]) => reportInfo?.includes(key))
            .map(([key, value]) => (
              <Accordion
                expanded={expanded === key}
                onChange={handleChange(key)}
                classes={classes}
                key={"EqupmentUpgrades_" + key}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      style={{ fontSize: 24, color: "#4B33E2" }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id={key}
                  classes={classesAcc}
                >
                  <Grid container sm={12} alignItems={"center"}>
                    <Grid item className={styles.IconStyle}>
                      <img
                        className={styles.Icon}
                        alt={value.title}
                        src={value.icon}
                      />
                    </Grid>
                    <Grid item className={styles.UpgradeHeader}>
                      {value.title}
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item className={styles.IconStyle} xs={4}>
                      <img
                        className={styles.Image}
                        alt={value.title}
                        src={value.image}
                      />
                    </Grid>
                    <Grid item xs={8} textAlign={"justify"} fontSize={"12px"}>
                      {value.description}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
        </div>
      </Grid>
    </div>
  )
}

EquipmentUpgrades.propTypes = {
  reportInfo: PropTypes.object,
}

EquipmentUpgrades.defaultProps = {}

export default EquipmentUpgrades
