import React from "react"
import styles from "./MetricsDisplay.module.scss"
import PropTypes from "prop-types"
import SavingsSection from "../SavingsSection"
import IncentivesSection from "../IncentivesSection"
import gridGroup from "../../../images/grid-group.png"
import CommMixedUse from "../../../images/CommMixedUse.jpeg"
import HoW from "../../../images/House-of-worship.jpg"
import MultiFamily from "../../../images/MultiFamily.jpeg"
import MultiFamilyLarge from "../../../images/MultiFamilyLarge.jpeg"
import SingleFam from "../../../images/Single-Family-v2.jpg"

const MetricsDisplay = ({ reportInfo }) => {
  const fetchBackground = building_type => {
    switch (building_type) {
      case "SINGLE_FAM":
        return SingleFam
      case "SMALL_RES":
      case "CO_OP":
      case "OTHER":
        return MultiFamily
      case "HOW":
        return HoW
      case "MIXED_USE":
        return CommMixedUse
      case "MULTI_FAM":
        return MultiFamilyLarge
      default:
        return MultiFamilyLarge
    }
  }
  // let hideSavingsDenver = false
  let hideSavingsDenver =
    reportInfo?.is_denver_lead && parseInt(reportInfo?.savings_min, 10) < 10
  let background = fetchBackground(reportInfo.building_type)
  var divImage = {
    backgroundImage: `url(${background})`,
    backgroundPositionY: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  }

  return (
    <div className={styles.MetricsDisplay} data-testid="MetricsDisplay">
      <div className={styles.Backdrop} style={divImage}>
        <div className={styles.FloatingCells}>
          <img
            src={gridGroup}
            srcSet={`${gridGroup}, ${gridGroup}`}
            alt="Grids"
            className={styles.GridGroupReportMetricsPage}
          />
          {!hideSavingsDenver && <SavingsSection {...reportInfo} />}
          {reportInfo.incentives_min !== "0" &&
            reportInfo.incentives_max !== "0" && (
              <IncentivesSection {...reportInfo} />
            )}
        </div>
      </div>
    </div>
  )
}

MetricsDisplay.propTypes = {
  reportInfo: PropTypes.object,
}

MetricsDisplay.defaultProps = {}

export default MetricsDisplay
