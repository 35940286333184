import React from "react"
import { Grid } from "@mui/material"
import PropTypes from "prop-types"
import styles from "./IncentivesSection.module.scss"
import IncentiveDetailsModal from "components/IncentiveDetailsModal"
import Rectangle from "../../../images/metrics-rectangle.png"
import ActionButton from "components/IBRv2/ActionButton/ActionButton"

const IncentivesSection = reportInfo => {
  let reportEligibleLead = reportInfo?.is_report_eligible_lead
  let nationalFuelLead = reportInfo?.is_national_fuel_lead
  let georgiaLead = reportInfo?.is_ga_power_lead
  let menloParkLead = reportInfo?.is_menlo_park_lead
  let ctaHidden =
    reportEligibleLead || nationalFuelLead || georgiaLead || menloParkLead
  let incentives =
    "$" + reportInfo.incentives_min + "-$" + reportInfo.incentives_max
  if (reportInfo.is_comed_lead && reportInfo.income_eligible) {
    incentives = reportInfo.building_type == "SINGLE_FAM" ? "100%" : "70%"
  }
  let incentives_text =
    "Potential local, state, and federal rebates and tax incentives"
  if (reportInfo.is_comed_lead && reportInfo.income_eligible) {
    incentives_text =
      reportInfo.building_type == "SINGLE_FAM"
        ? "ComEd may pay up to 100% of your project costs"
        : "ComEd may pay up to 70% of your project costs"
  }
  let sectionTitle = reportInfo.is_comed_lead
    ? "Potential Project Incentives"
    : "Estimated Incentives"

  if (!reportInfo.incentives_min || !reportInfo.incentives_max) {
    return (
      <div className={styles.IncentivesSection} data-testid="IncentivesSection">
        <div className={styles.Title}>{sectionTitle}</div>
        <div className={styles.AlternateMetrics}>
          {
            "A BlocPower expert will work with you to maximize and apply for city, state, and federal incentives."
          }
        </div>

        <Grid container>
          <Grid item xs={10} md={10} lg={10}>
            <div>
              <ActionButton
                styles={styles}
                owner={reportInfo?.hubspot_owner_id}
                url={reportInfo?.hubspot_owner_calendar_url}
              >
                SCHEDULE A CALL
              </ActionButton>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <div className={styles.IncentivesSection} data-testid="IncentivesSection">
      <div className={styles.Title}>{sectionTitle}</div>
      <div className={styles.Metrics}>{incentives}</div>

      <Grid container>
        <Grid item xs={1} md={1} lg={1}>
          <img
            src={Rectangle}
            srcSet={`${Rectangle}, ${Rectangle}`}
            alt="Saving"
            className={styles.MetricsRectangle}
          />
        </Grid>
        <Grid item xs={10} md={10} lg={10}>
          <div className={styles.MetricsDetails}>{incentives_text}</div>
        </Grid>
      </Grid>
      <div className={ctaHidden ? styles.CTAHidden : ""}>
        <IncentiveDetailsModal reportInfo={reportInfo}>
          {" "}
          SEE DETAILS{" "}
        </IncentiveDetailsModal>
      </div>
    </div>
  )
}

IncentivesSection.propTypes = {}

IncentivesSection.defaultProps = {}

export default IncentivesSection
