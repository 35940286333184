import React from "react"
import PropTypes from "prop-types"
import styles from "./IncentiveSection.module.scss"
import Incentives from "../Incentives/Incentives"
import { Grid } from "@mui/material"
import AssessmentButton from "../AssessmentButton/AssessmentButton"
import SectionButton from "../SectionButton/SectionButton"

const IncentiveSection = ({ reportInfo, isMQL, printable, openPrintPage }) => {
  const nextSectionButtonText = isMQL ? "View Case Studies" : "View FAQs"
  return (
    <section id="incentives">
      <div className={styles.IncentiveSection} data-testid="IncentiveSection">
        <Grid container rowGap={"1em"}>
          <Grid item xs={12}>
            <Incentives reportInfo={reportInfo} />
          </Grid>
          <Grid container spacing={1}>
            <Grid item>
              <AssessmentButton
                styles={styles}
                arrow="none"
                owner={reportInfo?.hubspot_owner_id}
                url={reportInfo?.hubspot_owner_calendar_url}
                printable={printable}
                openPrintPage={openPrintPage}
              >
                Schedule Free Consultation
              </AssessmentButton>
            </Grid>
            <Grid item className={styles.SectionButton}>
              <SectionButton
                arrow="true"
                className={styles.SectionButton}
                section="casestudies"
              >
                {nextSectionButtonText}
              </SectionButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </section>
  )
}

IncentiveSection.propTypes = {
  reportInfo: PropTypes.object,
  isMQL: PropTypes.bool,
  printable: PropTypes.bool,
  openPrintPage: PropTypes.func,
}

IncentiveSection.defaultProps = {}

export default IncentiveSection
