import React from "react"
import PropTypes from "prop-types"
import styles from "./CaseStudiesSection.module.scss"
import CaseStudies from "../CaseStudies/CaseStudies"
import { Grid } from "@mui/material"
import AssessmentButton from "../AssessmentButton/AssessmentButton"
import SectionButton from "../SectionButton/SectionButton"

const CaseStudiesSection = ({ reportInfo }) => (
  <section id="casestudies">
    <div className={styles.CaseStudiesSection} data-testid="CaseStudiesSection">
      <div className={styles.SectionHeader}>Case Studies</div>
      <Grid container rowGap={"1em"}>
        <Grid item xs={12}>
          <CaseStudies />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item>
          <AssessmentButton
            styles={styles}
            arrow="none"
            owner={reportInfo?.hubspot_owner_id}
            url={reportInfo?.hubspot_owner_calendar_url}
          >
            Schedule Free Consultation
          </AssessmentButton>
        </Grid>
        <Grid item className={styles.SectionButton}>
          <SectionButton arrow="true" section="team">
            View Your Team
          </SectionButton>
        </Grid>
      </Grid>
    </div>
  </section>
)

CaseStudiesSection.propTypes = {
  reportInfo: PropTypes.object,
}

CaseStudiesSection.defaultProps = {}

export default CaseStudiesSection
