import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import styles from "./TabSection.module.scss"
import { Tab, Tabs } from "@mui/material"
import { Link } from "react-scroll"
import { makeStyles } from "@mui/styles"
import { useMediaQuery } from "@mui/material"

const TabSection = ({ reportInfo, isMQL, hasIBR, hasProjectManagement }) => {
  const [value, setValue] = useState(0)
  const isSmallScreen = useMediaQuery("(max-width: 900px)")
  let offset = isSmallScreen ? -230 : -210

  const handleChange = (event, newVal) => {
    setValue(newVal)
  }
  const useStyle = makeStyles(() => ({
    flexContainer: {
      justifyContent: "space-between",
    },
    indicator: {
      width: "0 !important",
    },
  }))
  const classes = useStyle()
  return (
    <div className={styles.TabSection} data-testid="TabSection">
      <div className={styles.StickyTab}>
        <Tabs
          value={value}
          indicatorColor="secondary"
          textcolor="inherit"
          variant="scrollable"
          scrollButtons={true}
          onChange={handleChange}
          allowScrollButtonsMobile
          classes={classes}
        >
          <Link
            smooth
            spy
            offset={offset}
            to="overview"
            activeClass={styles.active}
          >
            <Tab label="Overview" key={0} value={0} className={styles.Tab} />
          </Link>
          <Link smooth spy to="sow" offset={offset} activeClass={styles.active}>
            <Tab
              label="Scope of Work"
              key={1}
              value={1}
              className={styles.Tab}
            />
          </Link>
          {!hasIBR && (
            <Link
              smooth
              spy
              to="incentives"
              offset={offset}
              activeClass={styles.active}
            >
              <Tab
                label="Your Incentives"
                key={2}
                value={2}
                className={styles.Tab}
              />
            </Link>
          )}
          {isMQL && hasProjectManagement && (
            <>
              <Link
                smooth
                spy
                to="casestudies"
                offset={offset}
                activeClass={styles.active}
              >
                <Tab
                  label="Case Studies"
                  key={3}
                  value={3}
                  className={styles.Tab}
                />
              </Link>
              <Link
                smooth
                spy
                to="team"
                offset={offset}
                activeClass={styles.active}
              >
                <Tab
                  label="Your Team"
                  key={4}
                  value={4}
                  className={styles.Tab}
                />
              </Link>
            </>
          )}
          <Link smooth spy to="faq" offset={offset} activeClass={styles.active}>
            <Tab label="FAQs" key={5} value={5} className={styles.Tab} />
          </Link>
        </Tabs>
      </div>
    </div>
  )
}

TabSection.propTypes = {
  reportInfo: PropTypes.object,
  isMQL: PropTypes.bool,
  hasIBR: PropTypes.bool,
  hasProjectManagement: PropTypes.bool,
}

TabSection.defaultProps = {}

export default TabSection
