import React from "react"
import PropTypes from "prop-types"
import styles from "./IncentiveDetailsModalFooter.module.scss"
import CTAButton from "components/Reusables/CTAButton"
import { Grid } from "@mui/material"
import Divider from "images/icons/divider.svg"
import { Box } from "@mui/material"

const IncentiveDetailsModalFooter = ({ total_incentives }) => (
  <>
    <Grid
      item
      xs={12}
      md={12}
      lg={12}
      xl={12}
      className={styles.Divider}
      data-testid="IncentiveDetailsModalFooter"
    >
      <Box
        sx={{
          height: "12.5px",
          borderLeft: 1,
          borderTop: 1,
          borderColor: "#BCBCBC",
          width: "auto",
        }}
      />
    </Grid>
    <Grid item xs={8} md={8} lg={8} xl={8}>
      <div className={styles.ModalFooterTitle}>Total Incentives</div>
    </Grid>
    <Grid item xs={4} md={4} lg={4} xl={4}>
      {<div className={styles.IncentiveItemCostRange}>{total_incentives}</div>}
    </Grid>
    <Grid item xs={12} md={12} lg={12} xl={12}>
      <div className={styles.FooterText}>
        <CTAButton styles={styles} arrow={"none"}>
          Schedule a call
        </CTAButton>{" "}
        to maximize and start applying for incentives.
      </div>
    </Grid>
  </>
)

IncentiveDetailsModalFooter.propTypes = {
  total_incentives: PropTypes.string,
}

IncentiveDetailsModalFooter.defaultProps = {}

export default IncentiveDetailsModalFooter
