import React from "react"
import PropTypes from "prop-types"
import styles from "./IncentiveDetailsModalBody.module.scss"
import { Grid } from "@mui/material"
import Accordion from "@mui/material/Accordion"
import MuiAccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp"
import { styled } from "@mui/material/styles"

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    width: "30px",
  },
}))

const IncentiveDetailsModalBody = ({ upgrade_measures }) => {
  const [expanded, setExpanded] = React.useState("none")

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  let upgradeMeasureItems = upgrade_measures
    .filter(upgrade_measure => upgrade_measure.upgrade_measure_incentive != "0")
    .map(upgrade_measure => {
      let incentiveItems = upgrade_measure.incentives?.map(incentive => (
        <>
          <Grid
            item
            className={styles.IncentiveDetailsModalBody}
            xs={12}
            md={8}
            lg={8}
            xl={8}
            key={incentive.incentive_key}
          >
            <div className={styles.IncentiveItemHeader}>
              <div className={styles.IncentiveItemHeaderChild}>
                <div className={styles.IncentiveItemTitle}>
                  {incentive.incentive_display_name}
                </div>
              </div>
              <div className={styles.IncentiveItemHeaderChild}>
                <div className={styles.IncentiveItemType}>
                  {incentive.incentive_type}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <div className={styles.IncentiveItemExplainer}>
              {incentive.incentive_explainer_text}
            </div>
          </Grid>
        </>
      ))

      let incentiveList = (
        <Grid container key={incentiveItems} rowSpacing={2}>
          {incentiveItems}
        </Grid>
      )

      let incentiveCost = ""

      // create ranges where applicable
      if (
        upgrade_measure.upgrade_measure_incentives_min ===
        upgrade_measure.upgrade_measure_incentives_max
      ) {
        incentiveCost = "$" + upgrade_measure.upgrade_measure_incentives_min
      } else {
        incentiveCost =
          "$" +
          upgrade_measure.upgrade_measure_incentives_min +
          "–" +
          "$" +
          upgrade_measure.upgrade_measure_incentives_max
      }

      const ariaControl = upgrade_measure.upgrade_measure_key + "-content"
      const accordionSummaryId = upgrade_measure.upgrade_measure_key + "-header"

      return (
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          xl={12}
          key={upgrade_measure.upgrade_measure_key}
        >
          <Accordion
            expanded={expanded === upgrade_measure.upgrade_measure_key}
            onChange={handleChange(upgrade_measure.upgrade_measure_key)}
            elevation={0}
            sx={{
              color: "#4D00FF",
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              sx={{
                padding: "0px",
              }}
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color: "#4D00FF",
                    rotate: "270deg",
                  }}
                />
              }
              aria-controls={ariaControl}
              id={accordionSummaryId}
            >
              <Grid
                item
                className={styles.IncentiveDetailsModalBody}
                xs={12}
                md={8}
                lg={8}
                xl={8}
                key={upgrade_measure.upgrade_measure_key}
              >
                <div className={styles.IncentiveItemHeader}>
                  <div className={styles.IncentiveItemHeaderChild}>
                    <div className={styles.UpgradeMeasureItemTitle}>
                      {upgrade_measure.upgrade_measure_name}
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={4} md={4} lg={4} xl={4}>
                <div className={styles.IncentiveItemCostRange}>
                  {incentiveCost}
                </div>
              </Grid>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                paddingLeft: "30px",
              }}
            >
              {incentiveList}
            </AccordionDetails>
          </Accordion>
        </Grid>
      )
    })

  let newIncentiveList = (
    <Grid container data-testid="IncentiveDetailsModalBody">
      {upgradeMeasureItems}
    </Grid>
  )

  return newIncentiveList
}

IncentiveDetailsModalBody.propTypes = {
  upgrade_measures: PropTypes.array,
}

IncentiveDetailsModalBody.defaultProps = {}

export default IncentiveDetailsModalBody
