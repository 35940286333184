import React from "react"
import PropTypes from "prop-types"
import BMMGReportTop from "../BMMGReportTop"
import ReportBody from "../ReportBody"
import ReportFooter from "../ReportFooter"
import styles from "./BMMGReportPage.module.scss"

const BMMGReportPage = ({ reportInfo }) => (
  <div className={styles.BMMGReportPage} data-testid="BMMGReportPage">
    <BMMGReportTop reportInfo={reportInfo} />
    <ReportBody reportInfo={reportInfo} />
    <ReportFooter reportInfo={reportInfo} />
  </div>
)

BMMGReportPage.propTypes = {
  reportInfo: PropTypes.object,
}

BMMGReportPage.defaultProps = {}

export default BMMGReportPage
