import React, { useCallback, useEffect, useState } from "react"
import styles from "./Initiate.module.scss"
import Footer from "components/Footer"
import FullBleedHeader from "components/FullBleedHeader"
import AddressCollectionWidget from "components/AddressCollectionWidget"
import { Grid } from "@mui/material"
import { submitBuildingAddress } from "../../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import gridGroup from "../../../images/grid-group.png"
import testBG from "../../../images/testbg.png"
import { useExperiments } from "../../../utils/experiments/experimentContext"

const Initiate = () => {
  const [searchParams] = useSearchParams()
  const { activeExperiments } = useExperiments()
  const [headlineVariant, setHeadlineVariant] = useState(null)
  const address = searchParams.get("address")
  const submission_source = searchParams.get("submission_source")
  const {
    isError,
    data = {},
    status,
  } = useSelector(state => state.buildingAddressSubmission)
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const onSubmit = useCallback(
    data => dispatch(submitBuildingAddress(data)),
    [dispatch]
  )

  useEffect(() => {
    if (address) {
      onSubmit({ address: address, submission_source: submission_source })
    }
  }, [address, onSubmit])
  const goToSanJose = () => {
    navigate("../../sanjoselandingpage", { replace: true })
  }
  const goToBuildingPage = () => {
    navigate("../building/" + data.data.submission_id, {
      state: { buildingInfo: data.data.building_info },
    })
  }
  useEffect(() => {
    if (isError) {
      data.detail
        ? window.alert(data.detail)
        : window.alert("There is an error. Please try again.")
    } else if (status == "succeeded") {
      if (data.data.is_san_jose_ca_lead) {
        goToSanJose()
        return
      }
      goToBuildingPage()
      return
    }
  }, [isError, status])

  // Use useEffect to wait for activeExperiments to be populated
  useEffect(() => {
    if (Object.keys(activeExperiments).includes("BI-7695")) {
      const variant = activeExperiments["BI-7695"]
      setHeadlineVariant(variant)
    }
  }, [activeExperiments])

  // Conditional rendering based on headlineVariant
  if (!headlineVariant) {
    return <div data-testid="Initiate">Loading...</div>
  }

  // Define experiment conditions
  const experimentHeadings = {
    0: {
      heading: "Upgrade Your HVAC System for $0 down",
      subheading: "Get a custom report for your property in 60 seconds",
    },
    1: {
      heading: "GET AN INSTANT PROJECT PROPOSAL",
      subheading: "Preview incentives, scope of work and financing",
    },
  }
  // Get the heading and subheading for the active variant
  const { heading, subheading } = experimentHeadings[headlineVariant]
  const headlineID = `headline-${headlineVariant}`

  return (
    <div className={styles.Initiate} data-testid="Initiate">
      <FullBleedHeader />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "70vh" }}
      >
        <div className={styles.BPBackground}>
          <img src={testBG} alt="" className={styles.Background} />
        </div>
        <img
          src={gridGroup}
          srcSet={`${gridGroup}, ${gridGroup}`}
          alt="Family"
          className={styles.GridGroupHomePage}
        />
        <Grid item xs={12} className={styles.HomePage}>
          <div id={headlineID} className={styles.Heading}>
            {heading}
          </div>
          <div className={styles.SubHeading}>{subheading}</div>
          <AddressCollectionWidget
            onSubmit={onSubmit}
            isLoading={status == "loading"}
          />
        </Grid>
      </Grid>
      <Footer />
    </div>
  )
}

Initiate.propTypes = {}

Initiate.defaultProps = {}

export default Initiate
