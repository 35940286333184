import React, { useState } from "react"
import PropTypes from "prop-types"
import styles from "./AssessmentHeader.module.scss"
import BPLogo from "images/bp-logo.svg"
import MVLogo from "images/mountain-view-logo-white.svg"
import BuildingSummary from "../BuildingSummary/BuildingSummary"
import AssessmentButton from "../AssessmentButton/AssessmentButton"
import { Grid } from "@mui/material"

const AssessmentHeader = ({
  address,
  reportInfo,
  hasIBR,
  printable,
  openPrintPage,
  isMQL,
}) => {
  let title = hasIBR ? "Initial" : "Project"
  let mountainViewLead = reportInfo?.is_mountain_view_lead
  return (
    <div className={styles.AssessmentHeader} data-testid="AssessmentHeader">
      <div className={styles.Logo}>
        {mountainViewLead && (
          <img
            className={styles.MVLogo}
            src={MVLogo}
            alt="City of Mountain View"
          />
        )}
        <img className={styles.BPLogo} src={BPLogo} alt="BlocPower Logo" />
      </div>
      <Grid
        container
        rowSpacing={1}
        className={styles.HeaderArea}
        justifyContent={"left"}
      >
        <Grid
          item
          rowSpacing={1}
          md={6}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={12}>
            <div className={styles.HeaderText}>
              {title} Assessment for {address}
            </div>
          </Grid>
          <Grid item xs={12} className={styles.CTAButton}>
            <AssessmentButton
              styles={styles}
              owner={reportInfo?.hubspot_owner_id}
              arrow="true"
              printable={printable}
              openPrintPage={openPrintPage}
              url={reportInfo?.hubspot_owner_calendar_url}
            >
              Schedule Free Consultation
            </AssessmentButton>
          </Grid>
        </Grid>
        <Grid item display={{ xs: "none", md: "block" }}>
          <BuildingSummary reportInfo={reportInfo} isMQL={isMQL} />
        </Grid>
      </Grid>
    </div>
  )
}

AssessmentHeader.propTypes = {
  address: PropTypes.string,
  reportInfo: PropTypes.object,
  hasIBR: PropTypes.bool,
  printable: PropTypes.bool,
  printing: PropTypes.bool,
  openPrintPage: PropTypes.func,
  isMQL: PropTypes.bool,
}

AssessmentHeader.defaultProps = {}

export default AssessmentHeader
