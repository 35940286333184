import React from "react"
import PropTypes from "prop-types"
import styles from "./TooltipInfoIcon.module.scss"
import InfoSrc from "images/icons/info-icon.svg"
import ReactTooltip from "react-tooltip"

const TooltipInfoIcon = ({ message }) => (
  <div className={styles.TooltipInfoIcon} data-testid="TooltipInfoIcon">
    <img
      alt={"Info"}
      src={InfoSrc}
      data-tip={message}
      style={{ cursor: "pointer" }}
      className={styles.InfoIcon}
    />
    <ReactTooltip className={styles.Tooltip} />
  </div>
)

TooltipInfoIcon.propTypes = {
  message: PropTypes.string,
}

TooltipInfoIcon.defaultProps = {}

export default TooltipInfoIcon
