import React from "react"
import styles from "./DenverLandingPage.module.scss"

const DenverLandingPage = () => (
  <div className={styles.DenverLandingPage} data-testid="DenverLandingPage">
    {window.location.replace(process.env.REACT_APP_DENVER_LANDING_PAGE_URL)}
  </div>
)

DenverLandingPage.propTypes = {}

DenverLandingPage.defaultProps = {}

export default DenverLandingPage
