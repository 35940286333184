import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import { enhancer } from "addon-redux"
import reducer from "redux/reducer"

const composedEnhancer = compose(enhancer, applyMiddleware(thunk))
const store = createStore(reducer, composedEnhancer)

export const initStore = initialState =>
  createStore(reducer, initialState, composedEnhancer)

export default store
