import React from "react"
import PropTypes from "prop-types"
import styles from "./ReportFooter.module.scss"
import FaqSection from "../FaqSection"
import TestimonialsSection from "../TestimonialsSection"
import Footer from "components/Footer"

const ReportFooter = ({ reportInfo, ibr }) => (
  <div className={styles.ReportFooter} data-testid="ReportFooter">
    <FaqSection {...reportInfo} />
    <TestimonialsSection reportInfo={reportInfo} ibr={ibr} />
    <Footer />
  </div>
)

ReportFooter.propTypes = {
  reportInfo: PropTypes.object,
  ibr: PropTypes.string,
}

ReportFooter.defaultProps = {}

export default ReportFooter
