import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Modal } from "react-responsive-modal"
import Button from "components/Reusables/Button"
import IncentiveDetailsModalHeader from "components/IncentiveDetailsModalHeader"
import IncentiveDetailsModalBody from "components/IncentiveDetailsModalBody"
import IncentiveDetailsModalFooter from "components/IncentiveDetailsModalFooter"
import styles from "./IncentiveDetailsModal.module.scss"
import BPLogo from "images/bp-logo-white.svg"
import { Grid } from "@mui/material"

const IncentiveDetailsModal = ({ reportInfo, children, arrow }) => {
  const [open, setOpen] = useState(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  let incentives = {}

  if (reportInfo?.upgrade_measures) {
    incentives = reportInfo
  }

  let total_incentive =
    "$" + incentives.incentives_min + "–" + "$" + incentives.incentives_max

  const closeIcon = (
    <div className={styles.CloseIcon}>
      <div className={styles.CloseIconChild}>Close</div>
      <div className={styles.CloseIconChild}>
        <svg
          width="28"
          height="28"
          viewBox="0 0 36 36"
          data-testid="close-icon"
        >
          <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path>
        </svg>
      </div>
    </div>
  )

  return (
    <div data-testid="IncentiveDetailsModal">
      <Button onClick={onOpenModal} styles={styles} arrow={arrow}>
        {children}
      </Button>
      <Modal
        classNames={{ modal: styles.IncentiveDetailsModal }}
        open={open}
        onClose={onCloseModal}
        closeIcon={closeIcon}
        center
      >
        <div className={styles.ModalHeader}>
          <div className={styles.Logos}>
            <img className={styles.BPLogo} src={BPLogo} alt="BlocPower Logo" />
          </div>
        </div>
        <Grid container className={styles.ModalGridContainer}>
          <Grid item xs={12} md={11} lg={11} xl={8}>
            <IncentiveDetailsModalHeader></IncentiveDetailsModalHeader>
          </Grid>
          <IncentiveDetailsModalBody
            upgrade_measures={incentives.upgrade_measures}
          ></IncentiveDetailsModalBody>
          <Grid container>
            <IncentiveDetailsModalFooter
              total_incentives={total_incentive}
            ></IncentiveDetailsModalFooter>
          </Grid>
        </Grid>
      </Modal>
    </div>
  )
}

IncentiveDetailsModal.propTypes = {
  children: PropTypes.node.isRequired,
  arrow: PropTypes.string,
  reportInfo: PropTypes.object,
}

IncentiveDetailsModal.defaultProps = {
  children: "",
}

export default IncentiveDetailsModal
