import React from "react"
import PropTypes from "prop-types"
import styles from "./YourTeam.module.scss"
import {
  Stack,
  Grid,
  Divider,
  Item,
  AccordionDetails,
  Box,
} from "@mui/material"
import { makeStyles } from "@mui/styles"

const YourTeam = ({ isSanJoseLead }) => {
  return (
    <div className={styles.YourTeam} data-testid="YourTeam">
      {!isSanJoseLead && (
        <Grid
          container
          spacing={2}
          alignItems="baseline"
          style={{ marginTop: 0 }}
        >
          <Grid item sm={12} md={6}>
            <div className={styles.TeamMember}>
              <Grid container>
                <Grid item sm={2} alignSelf={"center"} textAlign={"center"}>
                  <img
                    alt="Ryan"
                    src="https://live-blocpower-api.pantheonsite.io/wp-content/uploads/2023/02/61f99116b44bcb009ee19e81_RyanM-600x600.jpeg"
                    className={styles.EmployeeImage}
                  />
                </Grid>
                <Grid
                  item
                  sm={10}
                  alignSelf={"center"}
                  className={styles.MemberInfo}
                >
                  <Grid item sm={12}>
                    Ryan Merkin
                  </Grid>
                  <Grid item sm={12}>
                    <b>VP, Business Development</b>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} className={styles.MemberAddInfo}>
                <b>Education:</b> MS, Geosystems
              </Grid>
              <Grid item sm={12} className={styles.MemberAddInfo}>
                <b>Experience:</b> 18+ years in the building science and energy
                consulting industry, with expertise in complex, multifamily
                energy efficiency and electrification projects.
              </Grid>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div className={styles.TeamMember}>
              <Grid container>
                <Grid item sm={2} alignSelf={"center"} textAlign={"center"}>
                  <img
                    alt="Brian"
                    src="https://live-blocpower-api.pantheonsite.io/wp-content/uploads/2024/05/brian-lindsey.webp"
                    className={styles.EmployeeImage}
                  />
                </Grid>
                <Grid
                  item
                  sm={10}
                  alignSelf={"center"}
                  className={styles.MemberInfo}
                >
                  <Grid item sm={12}>
                    Brian Lindsey
                  </Grid>
                  <Grid item sm={12}>
                    <b>Construction Operation Lead</b>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} className={styles.MemberAddInfo}>
                <b>Education:</b> BS, Business and Finance
              </Grid>
              <Grid item sm={12} className={styles.MemberAddInfo}>
                <b>Experience:</b> 30+ years in construction management for
                national developers and development leading nationwide growth
                for major retail & multi-use centers.
              </Grid>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div className={styles.TeamMember}>
              <Grid container>
                <Grid item sm={2} alignSelf={"center"} textAlign={"center"}>
                  <img
                    alt="Timothy"
                    src="https://live-blocpower-api.pantheonsite.io/wp-content/uploads/2023/07/5c53635c-3024-40d9-a4b3-7be3af38899a.jpg"
                    className={styles.EmployeeImage}
                  />
                </Grid>
                <Grid
                  item
                  sm={10}
                  alignSelf={"center"}
                  className={styles.MemberInfo}
                >
                  <Grid item sm={12}>
                    Timothy John, P.E.
                  </Grid>
                  <Grid item sm={12}>
                    <b>Head of Construction</b>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} className={styles.MemberAddInfo}>
                <b>Education:</b> MCE, Environmental Engineering
              </Grid>
              <Grid item sm={12} className={styles.MemberAddInfo}>
                <b>Experience:</b> 20+ years of experience leading engineering
                operations and overseeing completion of 300-500+ projects per
                year, including $500M mega-projects.
              </Grid>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div className={styles.TeamMember}>
              <Grid container>
                <Grid item sm={2} alignSelf={"center"} textAlign={"center"}>
                  <img
                    alt="Dom"
                    src="https://live-blocpower-api.pantheonsite.io/wp-content/uploads/2023/02/61f986ce6e48720d68e33d35_Dom-600x600.jpeg"
                    className={styles.EmployeeImage}
                  />
                </Grid>
                <Grid
                  item
                  sm={10}
                  alignSelf={"center"}
                  className={styles.MemberInfo}
                >
                  <Grid item sm={12}>
                    Dom Lempereur
                  </Grid>
                  <Grid item sm={12}>
                    <b>Chief of Engineering</b>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} className={styles.MemberAddInfo}>
                <b>Education:</b> MS, Energy & Environment
              </Grid>
              <Grid item sm={12} className={styles.MemberAddInfo}>
                <b>Experience:</b> 30+ years experience assessing, developing
                and managing energy upgrades for over 200 million square feet of
                residential and commercial buildings.
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}
      {isSanJoseLead && (
        <Grid
          container
          spacing={2}
          alignItems="baseline"
          style={{ marginTop: 0 }}
        >
          <Grid item sm={12} md={6}>
            <div className={styles.TeamMember}>
              <Grid container>
                <Grid item sm={2} alignSelf={"center"} textAlign={"center"}>
                  <img
                    alt="Nick"
                    src="https://live-blocpower-api.pantheonsite.io/wp-content/uploads/2024/05/nick-team-ibr.webp"
                    className={styles.EmployeeImage}
                  />
                </Grid>
                <Grid
                  item
                  sm={10}
                  alignSelf={"center"}
                  className={styles.MemberInfo}
                >
                  <Grid item sm={12}>
                    Nick Marino
                  </Grid>
                  <Grid item sm={12}>
                    <b>San Jose Lead - Market Operations/PM</b>
                  </Grid>
                </Grid>
                <Grid item sm={12} className={styles.MemberAddInfo}>
                  <b>Education:</b> BS, Mechanical Engineering
                </Grid>
              </Grid>
              <Grid item sm={12} className={styles.MemberAddInfo}>
                <b>Experience:</b> 14+ years in the building science and
                sustainability  industry, with expertise in complex energy
                efficiency and electrification projects.
              </Grid>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div className={styles.TeamMember}>
              <Grid container>
                <Grid item sm={2} alignSelf={"center"} textAlign={"center"}>
                  <img
                    alt="Brent"
                    src="https://live-blocpower-api.pantheonsite.io/wp-content/uploads/2024/05/brent-team-ibr.webp"
                    className={styles.EmployeeImage}
                  />
                </Grid>
                <Grid
                  item
                  sm={10}
                  alignSelf={"center"}
                  className={styles.MemberInfo}
                >
                  <Grid item sm={12}>
                    Brent Vigneault
                  </Grid>
                  <Grid item sm={12}>
                    <b>Director Market Operations, West</b>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} className={styles.MemberAddInfo}>
                <b>Education:</b> BS, Communications and Business
              </Grid>
              <Grid item sm={12} className={styles.MemberAddInfo}>
                <b>Experience:</b> 10+ years in the operations management space,
                overseeing city contracts and day-to-day service delivery with
                distributed teams.
              </Grid>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div className={styles.TeamMember}>
              <Grid container>
                <Grid item sm={2} alignSelf={"center"} textAlign={"center"}>
                  <img
                    alt="Caitlin"
                    src="https://live-blocpower-api.pantheonsite.io/wp-content/uploads/2024/05/caitlin-team-ibr.webp"
                    className={styles.EmployeeImage}
                  />
                </Grid>
                <Grid
                  item
                  sm={10}
                  alignSelf={"center"}
                  className={styles.MemberInfo}
                >
                  <Grid item sm={12}>
                    Caitlin Benedict
                  </Grid>
                  <Grid item sm={12}>
                    <b>Clean Energy Associate</b>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} className={styles.MemberAddInfo}>
                <b>Education:</b> MA, Climate Communications
              </Grid>
              <Grid item sm={12} className={styles.MemberAddInfo}>
                <b>Experience:</b> Multidisciplinary communications expert
                specializing in community and stakeholder engagement
              </Grid>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div className={styles.TeamMember}>
              <Grid container>
                <Grid item sm={2} alignSelf={"center"} textAlign={"center"}>
                  <img
                    alt="Dom"
                    src="https://live-blocpower-api.pantheonsite.io/wp-content/uploads/2023/02/61f986ce6e48720d68e33d35_Dom-600x600.jpeg"
                    className={styles.EmployeeImage}
                  />
                </Grid>
                <Grid
                  item
                  sm={10}
                  alignSelf={"center"}
                  className={styles.MemberInfo}
                >
                  <Grid item sm={12}>
                    Dom Lempereur
                  </Grid>
                  <Grid item sm={12}>
                    <b>Chief of Engineering</b>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} className={styles.MemberAddInfo}>
                <b>Education:</b> MS, Energy & Environment
              </Grid>
              <Grid item sm={12} className={styles.MemberAddInfo}>
                <b>Experience:</b> 30+ years experience assessing, developing
                and managing energy upgrades for over 200 million square feet of
                residential and commercial buildings.
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

YourTeam.propTypes = {
  isSanJoseLead: PropTypes.bool,
}

YourTeam.defaultProps = {}

export default YourTeam
