import React from "react"
import PropTypes from "prop-types"
import styles from "./BuildingSummary.module.scss"
import BuildingTypeIcon from "images/icons/report-building-type.svg"
import NumUnitsIcon from "images/icons/report-num-unit.svg"
import SqFtIcon from "images/icons/report-sqft.svg"
import SystemIcon from "images/icons/report-system.svg"
import { Grid } from "@mui/material"

const BuildingSummary = ({ reportInfo, isMQL }) => {
  const buildingTypeMapping = {
    SINGLE_FAM: "Single Family",
    MULTI_FAM: "Multifamily (2+ Units)",
    MIXED_USE: "Mixed Use (Residential / Commercial)",
    HOTEL: "Hotel",
    HOW: "House of Worship / Religious Institution",
    COMM: "Commercial (Non-Hotel)",
    SCHOOL: "School",
    LARGE_CRE: "Large CRE / Industrial",
    OTHER: "Other",
  }
  const heatingSystemMapping = {
    STEAM: "Steam",
    HOT_WATER: "Hot Water",
    FORCED_AIR: "Forced Air",
    ELECT_RESIST: "Electric Baseboard",
    HEAT_PUMP: "Heat Pump",
    PTAC: "PTAC",
    NOT_SURE: "Unknown",
    NO_HEATING_SYS: "No Heating System",
  }
  const heatingSourcelMapping = {
    OIL: "Oil",
    NATURAL_GAS: "Natural Gas",
    DUAL_FUEL: "Duel Fuel",
    ELECT_RESIST: "Electric",
    OTHER: "Other",
    NOT_SURE: "Unknown",
    NO_HEATING_SYS: "No Heating System",
  }

  const buildingAgeMapping = {
    PRE_WAR: "Pre-War (Prior to 1940)",
    POST_WAR: "Post War (1941-1980)",
    POST_1980: "Post-1980",
  }

  const addHeatingSource = !["NOT_SURE", "NO_HEATING_SYS"].includes(
    reportInfo?.heating_source_type
  )

  return (
    <div className={styles.BuildingSummary} data-testid="BuildingSummary">
      <div className={styles.Header}>Building Summary</div>
      <div className={styles.Summary}>
        <Grid container alignItems={"center"} columnSpacing={1}>
          <Grid item justifyContent={"center"} alignItems={"center"}>
            <img
              className={styles.Icon}
              alt={"BuildingType"}
              src={BuildingTypeIcon}
            />
          </Grid>
          <Grid item justifyContent={"center"} alignItems={"center"}>
            {buildingTypeMapping[reportInfo?.building_type]}
          </Grid>
        </Grid>
        {isMQL && (
          <>
            <Grid container alignItems={"center"} columnSpacing={1}>
              <Grid item justifyContent={"center"} alignItems={"center"}>
                <img
                  className={styles.Icon}
                  alt={"NumUnitsIcon"}
                  src={NumUnitsIcon}
                />
              </Grid>
              <Grid item justifyContent={"center"} alignItems={"center"}>
                {reportInfo?.num_units} units
              </Grid>
            </Grid>
            <Grid container alignItems={"center"} columnSpacing={1}>
              <Grid item justifyContent={"center"} alignItems={"center"}>
                <img
                  className={styles.Icon}
                  alt={"Square Footage Icon"}
                  src={SqFtIcon}
                />
              </Grid>
              <Grid item justifyContent={"center"} alignItems={"center"}>
                {reportInfo?.square_footage} Sq. Ft.
              </Grid>
            </Grid>
          </>
        )}
        {!isMQL && (
          <>
            <Grid container alignItems={"center"} columnSpacing={1}>
              <Grid item justifyContent={"center"} alignItems={"center"}>
                <img
                  className={styles.Icon}
                  alt={"BuildingType"}
                  src={BuildingTypeIcon}
                />
              </Grid>
              <Grid item justifyContent={"center"} alignItems={"center"}>
                {buildingAgeMapping[reportInfo?.building_age]}
              </Grid>
            </Grid>
            <Grid container alignItems={"center"} columnSpacing={1}>
              <Grid item justifyContent={"center"} alignItems={"center"}>
                <img
                  className={styles.Icon}
                  alt={"Square Footage Icon"}
                  src={SqFtIcon}
                />
              </Grid>
              <Grid item justifyContent={"center"} alignItems={"center"}>
                {reportInfo?.num_units} unit(s),{" "}
                {reportInfo?.square_footage?.toLocaleString()} Sq. Ft.
              </Grid>
            </Grid>
          </>
        )}
        <Grid container alignItems={"center"} columnSpacing={1}>
          <Grid item justifyContent={"center"} alignItems={"center"}>
            <img
              className={styles.Icon}
              alt={"Heating System Icon"}
              src={SystemIcon}
            />
          </Grid>
          <Grid item justifyContent={"center"} alignItems={"center"}>
            {heatingSystemMapping[reportInfo?.heating_system_type]}
            {addHeatingSource && <> System fueled by </>}
            {addHeatingSource &&
              heatingSourcelMapping[reportInfo?.heating_source_type]}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

BuildingSummary.propTypes = {
  reportInfo: PropTypes.object,
  isMQL: PropTypes.bool,
}

BuildingSummary.defaultProps = {}

export default BuildingSummary
