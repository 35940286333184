import React, { useCallback, useEffect } from "react"
import styles from "./IntentPage.module.scss"
import IntentPageForm from "../IntentPageForm"
import { submitProjectSubmissionInfo } from "../../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import Footer from "components/Footer"
import FullBleedHeader from "components/FullBleedHeader"
import largeImage from "../../../images/verify-large-image.png"
import { Grid, Box } from "@mui/material"

const IntentPage = () => {
  const {
    isError,
    data = {},
    status,
  } = useSelector(state => state.projectInfoSubmission)
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const { submissionId } = useParams()
  const onSubmit = useCallback(
    data => {
      dispatch(submitProjectSubmissionInfo(submissionId, data))
    },
    [dispatch, submissionId]
  )

  const goToAssessment = id => {
    navigate("../../assessment/" + id, { replace: true })
  }
  const goToInMarketSF = id => {
    navigate("../../assessment/" + id, { replace: true })
  }
  const goToInMarketLMF = id => {
    navigate("../../assessment/" + id, { replace: true })
  }
  const goToFinancing = () => {
    navigate("../../financing", { replace: true })
  }
  const goToDenverWaitlist = () => {
    navigate("../../denversflandingpage", { replace: true })
  }
  const goToWaitlist = () => {
    navigate("../../waitlist", { replace: true })
  }

  useEffect(() => {
    if (isError) {
      data.detail
        ? window.alert(data.detail)
        : window.alert("There is an error. Please try again.")
    } else if (status == "succeeded") {
      const landingPage = data.data.is_landing_page
      if (landingPage == "in_market_sf") {
        goToInMarketSF(submissionId)
        return
      }
      if (landingPage == "in_market_lmf") {
        goToInMarketLMF(submissionId)
        return
      }
      if (landingPage == "waitlist") {
        goToWaitlist()
        return
      }
      if (landingPage == "financing") {
        goToFinancing()
        return
      }
      if (landingPage == "assessment") {
        goToAssessment(submissionId)
        return
      }
      if (landingPage == "denver_waitlist") {
        goToDenverWaitlist()
        return
      }
      goToWaitlist()
      return
    }
  }, [isError, status])

  return (
    <div className={styles.IntentPage} data-testid="IntentPage">
      <FullBleedHeader />
      <Grid container spacing={3}>
        <Grid item sm={10} md={4}>
          <div className={styles.IntentPageForm}>
            <IntentPageForm
              onSubmit={onSubmit}
              isLoading={status == "loading"}
            />
          </div>
        </Grid>
        <Box
          component={Grid}
          item
          md={8}
          display={{ xs: "none", md: "flex" }}
          style={{
            alignSelf: "center",
            justifyContent: "right",
            zIndex: "-2",
          }}
        >
          <div className={styles.IntentPageImage}>
            <img
              src={largeImage}
              srcSet={`${largeImage}, ${largeImage}`}
              alt="Family"
              className={styles.LargeImage}
            />
          </div>
        </Box>
      </Grid>
      <Footer />
    </div>
  )
}

IntentPage.propTypes = {}

IntentPage.defaultProps = {}

export default IntentPage
