import React from "react"
import PropTypes from "prop-types"
import styles from "./EstimatedEquipmentCost.module.scss"
import { Grid, Divider } from "@mui/material"
import { makeStyles } from "@mui/styles"

const EstimatedEquipmentCost = props => {
  const sowUpgradeCosts = props?.upgradeMeasures?.map(upgradeMeasure => {
    return {
      title: upgradeMeasure.upgrade_measure_name,
      estimatedEquipmentCost: upgradeMeasure.upgrade_measure_cost,
    }
  })
  return (
    <div
      className={styles.EstimatedEquipmentCost}
      data-testid="EstimatedEquipmentCost"
    >
      <Grid
        item
        sm={12}
        justifyContent={"newline"}
        className={styles.EstimatedCostTable}
        flexWrap={"wrap"}
      >
        {sowUpgradeCosts?.map((value, key) => (
          <>
            <Grid
              key={"EquipmentUpgradeCost_" + key}
              container
              spacing={8}
              justifyContent={"space-between"}
              flexWrap={"nowrap"}
            >
              <Grid
                item
                id={"EquipmentUpgradeCostItem_" + key}
                className={styles.UpgradeMeasureName}
              >
                <Grid item id={"EquipmentUpgradeCostItemTitle_" + key}>
                  {value.title}
                </Grid>
              </Grid>
              <Grid
                item
                id={"EquipmentUpgradeCostItemCost_" + key}
                spacing={4}
                className={styles.UpgradeMeasureCost}
              >
                ${value.estimatedEquipmentCost.toLocaleString()}
              </Grid>
            </Grid>
            <Divider className={styles.Divider} />
          </>
        ))}
      </Grid>
    </div>
  )
}

EstimatedEquipmentCost.propTypes = {
  upgradeMeasures: PropTypes.array,
}

EstimatedEquipmentCost.defaultProps = {}

export default EstimatedEquipmentCost
