import React from "react"
import PropTypes from "prop-types"
import styles from "./ComEdLandingPage.module.scss"

const ComEdLandingPage = () => (
  <div className={styles.ComEdLandingPage} data-testid="ComEdLandingPage">
    {window.location.replace(process.env.REACT_APP_COMED_LANDING_PAGE_URL)}
  </div>
)

ComEdLandingPage.propTypes = {}

ComEdLandingPage.defaultProps = {}

export default ComEdLandingPage
