import React from "react"
import PropTypes from "prop-types"
import styles from "./SoWSection.module.scss"
import AssessmentButton from "../AssessmentButton/AssessmentButton"
import EquipmentUpgrades from "../EquipmentUpgrades/EquipmentUpgrades"
import EstimatedEquipmentCost from "../EstimatedEquipmentCost/EstimatedEquipmentCost"
import ProjectManagement from "../ProjectManagement/ProjectManagement"
import {
  Grid,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import ArrowRightIcon from "@mui/icons-material/ArrowRight"
import Finance1 from "images/finance1.svg"
import Finance2 from "images/finance2.svg"
import Finance3 from "images/finance3.svg"
import Finance4 from "images/finance4.svg"
import Finance5 from "images/finance5.svg"
import SectionButton from "../SectionButton/SectionButton"
import { useMediaQuery } from "@mui/material"

const SoWSection = ({
  reportInfo,
  hasIBR,
  hasFinancing,
  isMQL,
  printable,
  hasProjectManagement,
  openPrintPage,
}) => {
  const isSchedulingLead =
    reportInfo?.customer_marketing_status === "MQL - No IBR" ||
    !reportInfo?.upgrade_measures
  const nextSectionButtonText = hasIBR
    ? "View Case Studies"
    : "View Your Incentives"
  const isSmallScreen = useMediaQuery("(max-width: 900px)")
  let rootPadding = isSmallScreen ? "55px" : "100px"
  const [expanded, setExpanded] = React.useState(false)
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const useStyle = makeStyles(() => ({
    content: {
      flexDirection: "column",
    },
    root: {
      flexDirection: "row-reverse",
      boxShadow: "none",
      padding: 0,
      "&:hover": {
        borderRadius: "4px",
        background: "rgba(75, 51, 226, 0.08)",
      },
    },
    expanded: {
      "&:before": {
        opacity: 1,
      },
    },
  }))
  const useAccStyle = makeStyles(() => ({
    root: {
      boxShadow: "none",
    },
  }))
  const useStyleAcc = makeStyles(() => ({
    root: {
      paddingLeft: { rootPadding },
      paddingTop: 0,
      paddingBottom: 0,
    },
  }))
  const classesAcc = useAccStyle()
  const classes = useStyle()
  const Accclasses = useStyleAcc()
  let monthlyLease = 0
  if (reportInfo?.monthly_lease_price) {
    monthlyLease = reportInfo?.monthly_lease_price?.toLocaleString()
  }
  return (
    <section id="sow">
      <div className={styles.SoWSection} data-testid="SoWSection">
        <div className={styles.SectionHeader}>Scope of Work</div>
        <Grid container spacing={2} alignItems="center">
          <Grid item sm={12}>
            <div className={styles.HeaderText}>BlocPower&apos;s Services</div>
          </Grid>
        </Grid>
        <Divider className={styles.Divider} />
        <Grid container spacing={2} alignItems="baseline">
          <Accordion
            onChange={handleChange("panel1")}
            classes={classesAcc}
            defaultExpanded={true}
          >
            {isMQL ? (
              <AccordionSummary
                expandIcon={
                  <ArrowRightIcon style={{ fontSize: 55, color: "#4B33E2" }} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={classes}
                sx={{
                  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                    transform: "rotate(90deg)",
                  },
                }}
              >
                <div className={styles.AccHeader}>Retrofit Installation</div>
              </AccordionSummary>
            ) : (
              <AccordionSummary
                expandIcon={
                  <ArrowRightIcon style={{ fontSize: 55, color: "#4B33E2" }} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={classes}
                sx={{
                  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                    transform: "rotate(90deg)",
                  },
                }}
              >
                <div className={styles.AccHeader}>Retrofits</div>
              </AccordionSummary>
            )}
            <AccordionDetails classes={Accclasses}>
              {hasProjectManagement && (
                <Grid item sm={12}>
                  <div className={styles.SubHeaderSubText}>
                    BlocPower is your singular, trusted point of contact through
                    the project&apos;s completion and beyond.
                  </div>
                </Grid>
              )}

              <Grid container spacing={2} alignItems="flex-start">
                <Grid item sm={12} md={6} className={styles.GridStyle}>
                  <EquipmentUpgrades reportInfo={reportInfo?.scope_of_work} />
                </Grid>
                {hasProjectManagement && (
                  <Grid item sm={12} md={6}>
                    <ProjectManagement />
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        {!isMQL && (
          <>
            <Divider className={styles.Divider} />
            <Grid container spacing={2} alignItems="baseline">
              <Accordion
                onChange={handleChange("panel1")}
                classes={classesAcc}
                defaultExpanded={true}
              >
                <AccordionSummary
                  expandIcon={
                    <ArrowRightIcon
                      style={{ fontSize: 55, color: "#4B33E2" }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  classes={classes}
                  sx={{
                    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                      transform: "rotate(90deg)",
                    },
                  }}
                >
                  <div className={styles.AccHeader}>Estimated Cost</div>
                </AccordionSummary>
                <AccordionDetails classes={Accclasses}>
                  <Grid
                    container
                    alignItems="flex-start"
                    // className={styles.EstCostContent}
                  >
                    <Grid item className={styles.EstCostContent}>
                      <EstimatedEquipmentCost
                        upgradeMeasures={reportInfo?.upgrade_measures}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </>
        )}
        {hasFinancing && isMQL && (
          <>
            <Divider className={styles.Divider} />
            <Grid container spacing={2} alignItems="center">
              <Divider className={styles.Divider} />
              <Accordion
                onChange={handleChange("panel1")}
                classes={classesAcc}
                defaultExpanded={true}
              >
                <AccordionSummary
                  expandIcon={
                    <ArrowRightIcon
                      style={{ fontSize: 55, color: "#4B33E2" }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  classes={classes}
                  sx={{
                    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                      transform: "rotate(90deg)",
                    },
                  }}
                >
                  <div className={styles.AccHeader}>Project Finance</div>
                </AccordionSummary>
                <AccordionDetails classes={Accclasses}>
                  {!hasIBR && (
                    <Grid item sm={12}>
                      <div className={styles.SubHeaderText}>
                        Project Finance for as little as <b>$0 down</b> and{" "}
                        <b>${monthlyLease}/month</b>
                      </div>
                      <div className={styles.SubHeaderText}>
                        Financing includes system maintenance for the lifetime
                        of your lease.
                      </div>
                    </Grid>
                  )}
                  <Grid item sm={12}>
                    <div className={styles.SectionHeaderText}>
                      Affordable Upgrades
                    </div>
                    <div className={styles.SubSectionText}>
                      Finance major capital improvements at competitive rates
                      for as little as $0 down.
                    </div>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className={styles.FinanceGridSection}
                  >
                    <Grid item lg={12 / 5} className={styles.FinanceGrid}>
                      <div>
                        <img
                          alt="fin1"
                          src={Finance1}
                          className={styles.FinanceIcon}
                        />
                      </div>
                      <div className={styles.FinanceText}>
                        <p>
                          $0 down financing with holistic approach to approval
                        </p>
                      </div>
                    </Grid>
                    <Grid item md={12 / 5} className={styles.FinanceGrid}>
                      <div>
                        <img
                          alt="fin2"
                          src={Finance2}
                          className={styles.FinanceIcon}
                        />
                      </div>
                      <div className={styles.FinanceText}>
                        <p>
                          <b>15 year term</b> for affordable monthly payments
                        </p>
                      </div>
                    </Grid>
                    <Grid item lg={12 / 5} className={styles.FinanceGrid}>
                      <div>
                        <img
                          alt="fin3"
                          src={Finance3}
                          className={styles.FinanceIcon}
                        />
                      </div>
                      <div className={styles.FinanceText}>
                        <p>
                          No fees for underwriting, origination or prepayment
                        </p>
                      </div>
                    </Grid>
                    <Grid item lg={12 / 5} className={styles.FinanceGrid}>
                      <div>
                        <img
                          alt="fin4"
                          src={Finance4}
                          className={styles.FinanceIcon}
                        />
                      </div>
                      <div className={styles.FinanceText}>
                        <p>
                          No lien capital lease with potential accelerated
                          depreciation tax benefit
                        </p>
                      </div>
                    </Grid>
                    <Grid item lg={12 / 5} className={styles.FinanceGrid}>
                      <div>
                        <img
                          alt="fin5"
                          src={Finance5}
                          className={styles.FinanceIcon}
                        />
                      </div>
                      <div className={styles.FinanceText}>
                        <p>
                          Flexible capital to address energy related upgrades &
                          remediation
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </>
        )}
        <Divider className={styles.Divider} />
        <Grid container spacing={1}>
          <Grid item>
            <AssessmentButton
              styles={styles}
              arrow="none"
              owner={reportInfo?.hubspot_owner_id}
              url={reportInfo?.hubspot_owner_calendar_url}
              printable={printable}
              openPrintPage={openPrintPage}
            >
              Schedule Free Consultation
            </AssessmentButton>
          </Grid>
          <Grid item className={styles.SectionButton}>
            <SectionButton arrow="true" section="incentives">
              {nextSectionButtonText}
            </SectionButton>
          </Grid>
        </Grid>
      </div>
    </section>
  )
}

SoWSection.propTypes = {
  reportInfo: PropTypes.object,
  hasIBR: PropTypes.bool,
  hasFinancing: PropTypes.bool,
  isMQL: PropTypes.bool,
  printable: PropTypes.bool,
  hasProjectManagement: PropTypes.bool,
  openPrintPage: PropTypes.func,
}

SoWSection.defaultProps = {}

export default SoWSection
