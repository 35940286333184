import React from "react"
import PropTypes from "prop-types"
import styles from "./BMMGBackdrop.module.scss"
import gridGroup from "../../../images/grid-group.png"
import CommMixedUse from "../../../images/CommMixedUse.jpeg"
import HoW from "../../../images/House-of-worship.jpg"
import MultiFamily from "../../../images/MultiFamily.jpeg"
import MultiFamilyLarge from "../../../images/MultiFamilyLarge.jpeg"
import SingleFam from "../../../images/Single-Family-v2.jpg"

const BMMGBackdrop = reportInfo => {
  const fetchBackground = building_type => {
    switch (building_type) {
      case "SINGLE_FAM":
        return SingleFam
      case "SMALL_RES":
      case "CO_OP":
      case "OTHER":
        return MultiFamily
      case "HOW":
        return HoW
      case "MIXED_USE":
        return CommMixedUse
      case "MULTI_FAM":
        return MultiFamilyLarge
      default:
        return MultiFamilyLarge
    }
  }
  let background = fetchBackground(reportInfo.building_type)
  var divImage = {
    backgroundImage: `url(${background})`,
    backgroundPositionY: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  }
  return (
    <div className={styles.BMMGBackdrop} data-testid="BMMGBackdrop">
      <div className={styles.Backdrop} style={divImage}>
        <div className={styles.FloatingCells}>
          <img
            src={gridGroup}
            srcSet={`${gridGroup}, ${gridGroup}`}
            alt="Grids"
            className={styles.GridGroupReportMetricsPage}
          />
        </div>
      </div>
    </div>
  )
}

BMMGBackdrop.propTypes = {}

BMMGBackdrop.defaultProps = {}

export default BMMGBackdrop
