import React from "react"
import PropTypes from "prop-types"
import styles from "./Incentives.module.scss"
import {
  Grid,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import ArrowRightIcon from "@mui/icons-material/ArrowRight"

const Incentives = ({ reportInfo }) => {
  const [expanded, setExpanded] = React.useState(false)
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const useStyle = makeStyles(() => ({
    content: {
      flexDirection: "column",
    },
    root: {
      flexDirection: "row-reverse",
      boxShadow: "none",
      padding: 0,
      "&:hover": {
        borderRadius: "4px",
        background: "rgba(75, 51, 226, 0.08)",
      },
    },
    expanded: {
      "&:before": {
        opacity: 1,
      },
    },
  }))
  const useAccStyle = makeStyles(() => ({
    root: {
      boxShadow: "none",
    },
  }))
  const useStyleAcc = makeStyles(() => ({
    root: {
      paddingLeft: "55px",
      paddingTop: 0,
      paddingBottom: 0,
    },
  }))
  const classesAcc = useAccStyle()
  const classes = useStyle()
  const Accclasses = useStyleAcc()
  let upgradeMeasureItems = reportInfo?.upgrade_measures.filter(
    upgrade_measure => upgrade_measure.upgrade_measure_incentive != "0"
  )
  return (
    <div className={styles.Incentives} data-testid="Incentives">
      <div className={styles.HeaderText}>Your Incentives</div>
      <div className={styles.SubHeaderText}>
        Incentives from federal, state and local governments lower the cost of
        equipment. BlocPower works to maximize the dollar value of the
        incentives for which you or your property are eligible. Some incentives,
        such as the Inflation Reduction Act rebates mentioned below, are not yet
        available. Actual incentives may differ.
      </div>
      <Grid container spacing={2} alignItems="center">
        <Grid item sm={12}>
          <div className={styles.IncentivesList}>
            {upgradeMeasureItems?.map((um, i) => (
              <Accordion
                classes={classesAcc}
                expanded={expanded === um.upgrade_measure_key}
                onChange={handleChange(um.upgrade_measure_key)}
                key={um.upgrade_measure_key}
              >
                <AccordionSummary
                  expandIcon={<ArrowRightIcon className={styles.ArrowIcon} />}
                  aria-controls={um.upgrade_measure_key}
                  id={um.upgrade_measure_key}
                  classes={classes}
                  sx={{
                    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                      transform: "rotate(90deg)",
                    },
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    justifyContent={"space-between"}
                    flexWrap={"nowrap"}
                  >
                    <Grid item>
                      <div className={styles.UMTitle}>
                        {um.upgrade_measure_name}
                      </div>
                    </Grid>
                    <Grid item>
                      <div className={styles.UMAmount}>
                        ${um.upgrade_measure_incentives_min}&ndash;$
                        {um.upgrade_measure_incentives_max}
                      </div>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails classes={Accclasses}>
                  {um.incentives.map(incentive => (
                    <>
                      <Grid
                        container
                        spacing={1}
                        flexWrap={"nowrap"}
                        alignItems={"center"}
                      >
                        <Grid item>
                          <div className={styles.UMText}>
                            {incentive.incentive_display_name}
                          </div>
                        </Grid>
                        <Grid item>
                          <div className={styles.UMType}>
                            {incentive.incentive_type}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <p className={styles.UMContent}>
                          {incentive.incentive_explainer_text}
                        </p>
                      </Grid>
                    </>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
            <Divider className={styles.Divider} />
            <Grid
              container
              spacing={2}
              justifyContent={"space-between"}
              flexWrap={"nowrap"}
              className={styles.TotalSection}
            >
              <Grid item>
                <div className={styles.TotalIncentivesText}>
                  Total Incentives
                </div>
              </Grid>
              <Grid item>
                <div className={styles.TotalIncentivesAmount}>
                  ${reportInfo?.incentives_min}&ndash;$
                  {reportInfo?.incentives_max}
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

Incentives.propTypes = {
  reportInfo: PropTypes.object,
}

Incentives.defaultProps = {}

export default Incentives
