import React from "react"
import PropTypes from "prop-types"
import styles from "./IncentiveDetailsModalHeader.module.scss"
import CTAButton from "components/Reusables/CTAButton"

const IncentiveDetailsModalHeader = () => (
  <div
    className={styles.IncentiveDetailsModalHeader}
    data-testid="IncentiveDetailsModalHeader"
  >
    <div className={styles.IncentiveDetailTitle}>Estimated Incentives</div>
    <div className={styles.IncentiveDisclaimer}>
      This list includes incentives from the Federal IRA (available by the end
      of 2023) and some local programs. We do our best to ensure an accurate
      list of incentives, but actual incentives may differ.{" "}
    </div>
    <div>
      <CTAButton styles={styles} arrow={"none"}>
        Schedule a call
      </CTAButton>{" "}
      to maximize and start applying for incentives.
    </div>
  </div>
)

IncentiveDetailsModalHeader.propTypes = {}

IncentiveDetailsModalHeader.defaultProps = {}

export default IncentiveDetailsModalHeader
