import React from "react"
import PropTypes from "prop-types"
import styles from "./Icons.module.scss"

import classNames from "classnames"

import ElectricitySrc from "images/icons/electricity.svg"
import FinancialReturnSrc from "images/icons/financial-return.svg"
import HealthSrc from "images/icons/health.svg"
import LedLightSrc from "images/icons/led-light.svg"
import RoomsSrc from "images/icons/rooms.svg"
import SeasonsSrc from "images/icons/seasons.svg"
import SolarSrc from "images/icons/solar.svg"
import WindowsSrc from "images/icons/windows.svg"

import GuaranteeIconSrc from "images/icons/guarantee_icon.svg"
import MaintenanceIconSrc from "images/icons/maintenance_icon.svg"
import MaintenanceIconPinkSrc from "images/icons/maintenance_icon_pink.svg"
import NoLienIconSrc from "images/icons/no_lien_icon.svg"
import NoMoneyDownIconSrc from "images/icons/no_money_down_icon.svg"
import PaymentsIconSrc from "images/icons/payments_icon.svg"
import AllSeasonIconSrc from "images/icons/all_season_icon.svg"
import ElectricEfficiencyIconSrc from "images/icons/electric_efficiency_icon.svg"
import FilterIconSrc from "images/icons/filter_icon.svg"
import PreciseIconSrc from "images/icons/precise_icon.svg"
import GreenHandIconSrc from "images/icons/green_hand_icon.svg"
import PartnerForLifeIconSrc from "images/icons/partner_for_life_icon.svg"
import ArrowRightSrc from "images/icons/arrow_right.svg"
import ButtonArraowIconSrc from "images/icons/RightArrow.svg"
import ButtonBlueArraowIconSrc from "images/icons/RightArrowBlue.svg"
import ButtonArraowLightIconSrc from "images/icons/RightArrowLight.svg"
import DashIconSrc from "images/icons/dash.svg"
import StoveIconSrc from "images/icons/stove.svg"
import InsulationIconSrc from "images/icons/insulation.svg"
import ElectricApplianceIconSrc from "images/icons/electric_appliance.svg"

export const InsulationIcon = ({ className }) => (
  <img
    alt={"Insulation icon"}
    src={InsulationIconSrc}
    className={classNames(styles.Icon, className)}
  />
)

InsulationIcon.propTypes = {
  className: PropTypes.string,
}

InsulationIcon.defaultProps = {}

export const ElectricApplianceIcon = ({ className }) => (
  <img
    alt={"Electric Appliance icon"}
    src={ElectricApplianceIconSrc}
    className={classNames(styles.Icon, className)}
  />
)

ElectricApplianceIcon.propTypes = {
  className: PropTypes.string,
}

ElectricApplianceIcon.defaultProps = {}

export const PartnerForLifeIcon = ({ className }) => (
  <img
    alt={"Partner for life icon"}
    src={PartnerForLifeIconSrc}
    className={classNames(styles.Icon, className)}
  />
)

PartnerForLifeIcon.propTypes = {
  className: PropTypes.string,
}

PartnerForLifeIcon.defaultProps = {}

export const DashIcon = ({ className }) => (
  <img alt={"Dash icon"} src={DashIconSrc} />
)

DashIcon.propTypes = {
  className: PropTypes.string,
}

DashIcon.defaultProps = {}

export const ButtonArraowIcon = ({ className }) => (
  <img
    alt={"Button icon"}
    src={ButtonArraowIconSrc}
    className={classNames(styles.SmallIcon, className)}
  />
)

ButtonArraowIcon.propTypes = {
  className: PropTypes.string,
}

ButtonArraowIcon.defaultProps = {}

export const ButtonBlueArraowIcon = ({ className }) => (
  <img
    alt={"Button Blue"}
    src={ButtonBlueArraowIconSrc}
    className={classNames(styles.SmallIcon, className)}
  />
)

ButtonBlueArraowIcon.propTypes = {
  className: PropTypes.string,
}

ButtonBlueArraowIcon.defaultProps = {}

export const ButtonArraowLightIcon = ({ className }) => (
  <img
    alt={"Button icon"}
    src={ButtonArraowLightIconSrc}
    className={classNames(styles.SmallIcon, className)}
  />
)

ButtonArraowLightIcon.propTypes = {
  className: PropTypes.string,
}

ButtonArraowLightIcon.defaultProps = {}

export const ArrowRight = ({ className }) => (
  <img
    alt={"Arrow right"}
    src={ArrowRightSrc}
    className={classNames(styles.Icon, className)}
  />
)

ArrowRight.propTypes = {
  className: PropTypes.string,
}

ArrowRight.defaultProps = {}

export const AllSeasonIcon = ({ className }) => (
  <img alt={"AllSeason icon"} src={AllSeasonIconSrc} />
)

AllSeasonIcon.propTypes = {
  className: PropTypes.string,
}

AllSeasonIcon.defaultProps = {}

export const GreenHandIcon = ({ className }) => (
  <img alt={"GreenHand icon"} src={GreenHandIconSrc} />
)

GreenHandIcon.propTypes = {
  className: PropTypes.string,
}

GreenHandIcon.defaultProps = {}

export const InductionIcon = ({ className }) => (
  <img
    alt={"Stove icon"}
    src={StoveIconSrc}
    className={classNames(styles.Icon, className)}
  />
)

InductionIcon.propTypes = {
  className: PropTypes.string,
}

InductionIcon.defaultProps = {}

export const ElectricEfficiencyIcon = ({ className }) => (
  <img alt={"Electricity icon"} src={ElectricEfficiencyIconSrc} />
)

ElectricEfficiencyIcon.propTypes = {
  className: PropTypes.string,
}

ElectricEfficiencyIcon.defaultProps = {}

export const FilterIcon = ({ className }) => (
  <img alt={"Filter icon"} src={FilterIconSrc} />
)

FilterIcon.propTypes = {
  className: PropTypes.string,
}

FilterIcon.defaultProps = {}

export const PreciseIcon = ({ className }) => (
  <img alt={"Precise icon"} src={PreciseIconSrc} />
)

PreciseIcon.propTypes = {
  className: PropTypes.string,
}

PreciseIcon.defaultProps = {}

import HeatPumpIconSrc from "images/icons/heat_pump_icon.svg"
import SolarIconSrc from "images/icons/solar_icon.svg"
import WaterHeaterIconSrc from "images/icons/water_heater_icon.svg"
import WindowIconSrc from "images/icons/window_icon.svg"
import LightbulbIconSrc from "images/icons/lightbulb_icon.svg"

export const HeatPumpIcon = ({ className }) => (
  <img
    alt={"HeatPump icon"}
    src={HeatPumpIconSrc}
    className={classNames(styles.Icon, className)}
  />
)

HeatPumpIcon.propTypes = {
  className: PropTypes.string,
}

HeatPumpIcon.defaultProps = {}

export const SolarIconWhite = ({ className }) => (
  <img
    alt={"Solar icon"}
    src={SolarIconSrc}
    className={classNames(styles.Icon, className)}
  />
)

SolarIconWhite.propTypes = {
  className: PropTypes.string,
}

SolarIconWhite.defaultProps = {}

export const WaterHeaterIcon = ({ className }) => (
  <img
    alt={"WaterHeater icon"}
    src={WaterHeaterIconSrc}
    className={classNames(styles.Icon, className)}
  />
)

WaterHeaterIcon.propTypes = {
  className: PropTypes.string,
}

WaterHeaterIcon.defaultProps = {}

export const WindowIcon = ({ className }) => (
  <img
    alt={"Window icon"}
    src={WindowIconSrc}
    className={classNames(styles.Icon, className)}
  />
)

WindowIcon.propTypes = {
  className: PropTypes.string,
}

WindowIcon.defaultProps = {}

export const LightbulbIcon = ({ className }) => (
  <img
    alt={"Lightbulb icon"}
    src={LightbulbIconSrc}
    className={classNames(styles.Icon, className)}
  />
)

LightbulbIcon.propTypes = {
  className: PropTypes.string,
}

LightbulbIcon.defaultProps = {}

export const ElectricityIcon = ({ className }) => (
  <img
    alt={"Electricity icon"}
    src={ElectricitySrc}
    className={classNames(styles.Icon, className)}
  />
)

ElectricityIcon.propTypes = {
  className: PropTypes.string,
}

ElectricityIcon.defaultProps = {}

export const FinancialReturnIcon = ({ className }) => (
  <img
    alt={"FinancialReturn icon"}
    src={FinancialReturnSrc}
    className={classNames(styles.Icon, className)}
  />
)

FinancialReturnIcon.propTypes = {
  className: PropTypes.string,
}

FinancialReturnIcon.defaultProps = {}

export const HealthIcon = ({ className }) => (
  <img
    alt={"Health icon"}
    src={HealthSrc}
    className={classNames(styles.Icon, className)}
  />
)

HealthIcon.propTypes = {
  className: PropTypes.string,
}

HealthIcon.defaultProps = {}

export const LedLightIcon = ({ className }) => (
  <img
    alt={"LedLight icon"}
    src={LedLightSrc}
    className={classNames(styles.Icon, className)}
  />
)

LedLightIcon.propTypes = {
  className: PropTypes.string,
}

LedLightIcon.defaultProps = {}

export const RoomsIcon = ({ className }) => (
  <img
    alt={"Rooms icon"}
    src={RoomsSrc}
    className={classNames(styles.Icon, className)}
  />
)

RoomsIcon.propTypes = {
  className: PropTypes.string,
}

RoomsIcon.defaultProps = {}

export const SeasonsIcon = ({ className }) => (
  <img
    alt={"Seasons icon"}
    src={SeasonsSrc}
    className={classNames(styles.Icon, className)}
  />
)

SeasonsIcon.propTypes = {
  className: PropTypes.string,
}

SeasonsIcon.defaultProps = {}

export const SolarIcon = ({ className }) => (
  <img
    alt={"Solar icon"}
    src={SolarSrc}
    className={classNames(styles.Icon, className)}
  />
)

SolarIcon.propTypes = {
  className: PropTypes.string,
}

SolarIcon.defaultProps = {}

export const WindowsIcon = ({ className }) => (
  <img
    alt={"Windows icon"}
    src={WindowsSrc}
    className={classNames(styles.Icon, className)}
  />
)

WindowsIcon.propTypes = {
  className: PropTypes.string,
}

WindowsIcon.defaultProps = {}

export const GuaranteeIcon = ({ className }) => (
  <img
    alt={"Guarantee icon"}
    src={GuaranteeIconSrc}
    className={classNames(styles.Icon, className)}
  />
)

GuaranteeIcon.propTypes = {
  className: PropTypes.string,
}

GuaranteeIcon.defaultProps = {}

export const MaintenanceIcon = ({ className }) => (
  <img alt={"Maintenance icon"} src={MaintenanceIconSrc} />
)

MaintenanceIcon.propTypes = {
  className: PropTypes.string,
}

MaintenanceIcon.defaultProps = {}

export const MaintenanceIconPink = () => (
  <img alt={"Maintenance icon"} src={MaintenanceIconPinkSrc} />
)

MaintenanceIconPink.propTypes = {}

MaintenanceIconPink.defaultProps = {}

export const NoLienIcon = ({ className }) => (
  <img
    alt={"NoLien icon"}
    src={NoLienIconSrc}
    className={classNames(styles.Icon, className)}
  />
)

NoLienIcon.propTypes = {
  className: PropTypes.string,
}

NoLienIcon.defaultProps = {}

export const NoMoneyDownIcon = ({ className }) => (
  <img
    alt={"NoMoneyDown icon"}
    src={NoMoneyDownIconSrc}
    className={classNames(styles.Icon, className)}
  />
)

NoMoneyDownIcon.propTypes = {
  className: PropTypes.string,
}

NoMoneyDownIcon.defaultProps = {}

export const PaymentsIcon = ({ className }) => (
  <img
    alt={"PaymentsIcon icon"}
    src={PaymentsIconSrc}
    className={classNames(styles.Icon, className)}
  />
)

PaymentsIcon.propTypes = {
  className: PropTypes.string,
}

PaymentsIcon.defaultProps = {}
