import React from "react"
import styles from "./ProjectBenefitsSection.module.scss"
import {
  AllSeasonIcon,
  MaintenanceIcon,
  ElectricEfficiencyIcon,
  FilterIcon,
  PreciseIcon,
  GreenHandIcon,
} from "components/Reusables/Icons"

const ProjectBenefitsSection = () => (
  <div
    className={styles.ProjectBenefitsSection}
    data-testid="ProjectBenefitsSection"
  >
    <div className={styles.Title}>Project Benefits</div>
    <div className={styles.FloatingCells}>
      <div className={styles.BenefitBox}>
        <AllSeasonIcon />
        <p>All-Season indoor comfort</p>
      </div>
      <div className={styles.BenefitBox}>
        <FilterIcon />
        <p>Built-in filtration system to improve air quality</p>
      </div>
      <div className={styles.BenefitBox}>
        <MaintenanceIcon />
        <p>Easy maintenance with no single point of failure</p>
      </div>
      <div className={styles.BenefitBox}>
        <PreciseIcon />
        <p>Precise, room-by-room temperature control</p>
      </div>
      <div className={styles.BenefitBox}>
        <ElectricEfficiencyIcon />
        <p>2-5x more efficient than furnaces, boilers, or window A/Cs</p>
      </div>
      <div className={styles.BenefitBox}>
        <GreenHandIcon />
        <p>Reduce your building&apos;s greenhouse gas emission</p>
      </div>
    </div>
  </div>
)

ProjectBenefitsSection.propTypes = {}

ProjectBenefitsSection.defaultProps = {}

export default ProjectBenefitsSection
