import React from "react"
import PropTypes from "prop-types"
import styles from "./CambridgeLandingPage.module.scss"

const CambridgeLandingPage = () => (
  <div
    className={styles.CambridgeLandingPage}
    data-testid="CambridgeLandingPage"
  >
    {window.location.replace(process.env.REACT_APP_CAMBRIDGE_LANDING_PAGE_URL)}
  </div>
)

CambridgeLandingPage.propTypes = {}

CambridgeLandingPage.defaultProps = {}

export default CambridgeLandingPage
