import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import "react-responsive-modal/styles.css"
import { Modal } from "react-responsive-modal"
import Button from "components/Reusables/Button"
import { ButtonArraowIcon } from "components/Reusables/Icons"

const CTAButton = ({ children, styles, arrow }) => {
  const [open, setOpen] = useState(false)

  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  useEffect(() => {
    if (window.hbspt && open) {
      window.hbspt.forms.create({
        portalId: "20299415",
        formId: "9e777388-c13c-4e28-aca9-aa9595ac4d0b",
        target: "#hubspotForm",
      })
    }
  }, [open])

  return (
    <>
      <Button onClick={onOpenModal} styles={styles} arrow={arrow}>
        {children}
      </Button>
      <Modal open={open} onClose={onCloseModal} center>
        <div id="hubspotForm"></div>
      </Modal>
    </>
  )
}

CTAButton.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.object,
  arrow: PropTypes.string,
}

CTAButton.defaultProps = {
  styles: {},
}

export default CTAButton
