import React from "react"
import PropTypes from "prop-types"
import styles from "./AssessmentPage.module.scss"
import Assessment from "../Assessment"

const AssessmentPage = () => (
  <div className={styles.AssessmentPage} data-testid="AssessmentPage">
    <Assessment />
  </div>
)

AssessmentPage.propTypes = {}

AssessmentPage.defaultProps = {}

export default AssessmentPage
