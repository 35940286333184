import React from "react"
import PropTypes from "prop-types"
import styles from "./OverviewSection.module.scss"
import OpportunityScore from "../OpportunityScore/OpportunityScore"
import ProjectAffordability from "../ProjectAffordability/ProjectAffordability"
import AccuracyStatement from "../AccuracyStatement/AccuracyStatement"
import AssessmentButton from "../AssessmentButton/AssessmentButton"
import SectionButton from "../SectionButton/SectionButton"
import { Grid } from "@mui/material"

const OverviewSection = ({
  reportInfo,
  hasIBR,
  isMQL,
  hasFinancing,
  printable,
  openPrintPage,
}) => {
  return (
    <section id="overview">
      <div className={styles.OverviewSection} data-testid="OverviewSection">
        <div className={styles.SectionHeader}>Overview</div>
        <Grid container rowGap={"1em"}>
          <Grid item md={7}>
            <OpportunityScore
              reportInfo={reportInfo}
              hasFinancing={hasFinancing}
            />
          </Grid>
          <Grid item md={5} className={styles.RightPanel}>
            <ProjectAffordability
              reportInfo={reportInfo}
              hasIBR={hasIBR}
              isMQL={isMQL}
              hasFinancing={hasFinancing}
            />
            {!hasIBR && <AccuracyStatement />}
          </Grid>
          <Grid container spacing={1}>
            <Grid item>
              <AssessmentButton
                styles={styles}
                arrow="none"
                owner={reportInfo?.hubspot_owner_id}
                url={reportInfo?.hubspot_owner_calendar_url}
                printable={printable}
                openPrintPage={openPrintPage}
              >
                Schedule Free Consultation
              </AssessmentButton>
            </Grid>
            <Grid item className={styles.SectionButton}>
              <SectionButton arrow="true" section="sow">
                View Scope of Work
              </SectionButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </section>
  )
}

OverviewSection.propTypes = {
  reportInfo: PropTypes.object,
  hasIBR: PropTypes.bool,
  isMQL: PropTypes.bool,
  hasFinancing: PropTypes.bool,
  printable: PropTypes.bool,
  openPrintPage: PropTypes.func,
}

OverviewSection.defaultProps = {}

export default OverviewSection
