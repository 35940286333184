import React from "react"
import PropTypes from "prop-types"
import styles from "./FAQsSection.module.scss"
import FAQs from "../FAQs/FAQs"
import { Grid } from "@mui/material"
import AssessmentButton from "../AssessmentButton/AssessmentButton"

const FAQsSection = props => {
  return (
    <section id="faqs">
      <div
        className={styles.FAQsSection}
        data-testid="FAQsSection"
        ref={FAQsSection}
      >
        <Grid container rowGap={"1em"}>
          <Grid item xs={12}>
            <div className={styles.SectionHeader}>FAQs</div>
            <FAQs
              isMQL={props?.isMQL}
              hasFinancing={props?.hasFinancing}
              hasProjectManagement={props?.hasProjectManagement}
            />
          </Grid>
          <Grid item xs={12}>
            <AssessmentButton
              styles={styles}
              arrow="light"
              owner={props?.hubspotOwnerID}
              url={props?.hubspotOwnerURL}
              printable={props?.printable}
              openPrintPage={props?.openPrintPage}
            >
              Schedule Free Consultation
            </AssessmentButton>
          </Grid>
        </Grid>
      </div>
    </section>
  )
}

FAQsSection.propTypes = {
  hubspotOwnerID: PropTypes.number,
  isMQL: PropTypes.bool,
  hasFinancing: PropTypes.bool,
  printable: PropTypes.bool,
  hasProjectManagement: PropTypes.bool,
  openPrintPage: PropTypes.func,
  hubspotOwnerURL: PropTypes.string,
}

FAQsSection.defaultProps = {}

export default FAQsSection
