import React from "react"
import PropTypes from "prop-types"
import styles from "./DenverSFLandingPage.module.scss"

const DenverSFLandingPage = () => (
  <div className={styles.DenverSFLandingPage} data-testid="DenverSFLandingPage">
    {window.location.replace(process.env.REACT_APP_DENVER_SF_LANDING_PAGE_URL)}
  </div>
)

DenverSFLandingPage.propTypes = {}

DenverSFLandingPage.defaultProps = {}

export default DenverSFLandingPage
