import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import styles from "./BMMGReport.module.scss"
import { useSearchParams } from "react-router-dom"
import { fetchBmmgReportInfo } from "redux/actions"
import ReportLoadingScreen from "components/Report/ReportLoadingScreen"
import ReportPage from "components/Report/ReportChallenger"
import BMMGReportPage from "components/Report/BMMGReportPage"

const BMMGReport = () => {
  const [searchParams] = useSearchParams()
  const bmmgId = searchParams.get("bmmgid")
  const dispatch = useDispatch()
  const [isFakeLoading, setFakeLoading] = useState(true)

  useEffect(() => dispatch(fetchBmmgReportInfo(bmmgId)), [bmmgId])
  useEffect(() => {
    setTimeout(() => setFakeLoading(false), 3000)
  }, [])

  const {
    status,
    data: bmmgReportInfo,
    isError,
  } = useSelector(state => state.bmmgReportInfo)

  const isDataLoading = status === "loading" || status === "idle"
  const showLoading = isFakeLoading || isDataLoading

  if (showLoading) {
    return (
      <div className={styles.App}>
        <ReportLoadingScreen />
      </div>
    )
  }

  if (isError) {
    bmmgReportInfo.message
      ? window.alert(JSON.stringify(bmmgReportInfo.message))
      : window.alert("There is an error. Please try again.")
    {
      window.location.replace("http://blocpower.io/")
    }
  }

  const metrics = []

  metrics.push({
    label: "Estimated Savings",
    value:
      bmmgReportInfo.savings_min === 0 && bmmgReportInfo.savings_max === 0
        ? "Not Applicable"
        : `${bmmgReportInfo.savings_min}\u2013${bmmgReportInfo.savings_max}%`,
    unit: "decrease on monthly energy bill",
  })

  if (
    bmmgReportInfo.incentives_min !== "0" &&
    bmmgReportInfo.incentives_max !== "0"
  ) {
    metrics.push({
      label: "Estimated Incentives",
      value: `$${bmmgReportInfo.incentives_min}\u2013$${bmmgReportInfo.incentives_max}`,
      unit: "potential rebates from state, local, federal incentives",
    })
  }

  return (
    <BMMGReportPage
      address={bmmgReportInfo.address}
      metrics={metrics}
      reportInfo={bmmgReportInfo}
    />
  )
}

BMMGReport.propTypes = {}

BMMGReport.defaultProps = {}

export default BMMGReport
