import React from "react"
import PropTypes from "prop-types"
import styles from "./IntentPageForm.module.scss"
import { Controller, useForm } from "react-hook-form"
import Button from "components/Reusables/Button"
import rectangleLine from "../../../images/verify-rectangle-line.png"
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Grid,
  TextField,
  Box,
  OutlinedInput,
  Chip,
  Checkbox,
  ListItemText,
} from "@mui/material"
import MuiPhoneNumber from "material-ui-phone-number"

const IntentPageForm = ({ onSubmit, isLoading }) => {
  const { register, handleSubmit, control, setValue, watch } = useForm()
  let selected_outcomes = watch("project_outcomes")
    ? watch("project_outcomes")
    : []
  let selected_upgrades = watch("building_upgrades")
    ? watch("building_upgrades")
    : []
  const phoneCountries = ["us"]
  const phoneRegion = ["north-america"]
  const projectOutcomes = {
    IMPROVE_COMFORT: "Improve occupant comfort",
    IMPROVE_HEALTH: "Improve occupant health",
    LOWER_COST: "Lower operating costs / energy bills",
    FINANCE_UPGRADES: "Finance major building upgrades",
    REDUCE_GAS_EMISSIONS: "Reduce greenhouse gas emissions",
    INCREASE_VALUE: "Increase building value",
    REGULATORY_COMPLIANCE: "Regulatory compliance",
  }
  const buildingUpgrades = {
    HEATING_SYSTEM: "Heating System",
    COOLING_SYSTEM: "Cooling System",
    HOT_WATER_HEATER: "Hot Water Heater",
    SOLAR_PANELS: "Solar Panels",
    ELECTRIC_INDUCTION_RANGE: "Electric Induction Range",
    AIR_SEALING_AND_INSULATION: "Building Envelope: Air Sealing and Insulation",
    EV_CHARGING: "EV Charging",
    NOT_SURE: "I'm not sure, please recommend a scope of work",
  }
  function handleOnPhoneChange(value) {
    setValue("phone", value)
  }
  return (
    <div className={styles.IntentPageForm} data-testid="IntentPageForm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          justifyContent="left"
          alignItems="center"
          className={styles.FormHeadingSection}
        >
          <Grid item className={styles.FormHeadingImage}>
            <img
              src={rectangleLine}
              srcSet={`${rectangleLine}, ${rectangleLine}`}
              alt="Family"
              className={styles.rectangleLine}
            />
          </Grid>
          <Grid item>
            <div className={styles.FormHeading}>
              <span className={styles.FormHeadingColorPart}>
                Project information
              </span>{" "}
              [Step 3 of 3]{" "}
            </div>
          </Grid>
        </Grid>
        <FormControl sx={{ m: 1 }} className={styles.FormControl}>
          <InputLabel
            id="relation_to_building"
            className={styles.InputLabel}
            size="small"
          >
            What&apos;s your relationship to this home or building?
          </InputLabel>
          <Select
            labelId="relation_to_building"
            name="relation_to_building"
            id="relation_to_building"
            autoComplete="off"
            size="small"
            label="What's your relationship to this home or building?"
            defaultValue=""
            required
            {...register("relation_to_building")}
          >
            <MenuItem value={"OWNER_LIVE"}>
              I own and occupy this property (no renters)
            </MenuItem>
            <MenuItem value={"OWNER_LIVE_RENT"}>
              I own and occupy this property and also rent to tenants
            </MenuItem>
            <MenuItem value={"OWNER_RENT"}>
              I own this property, but I rent it to tenants
            </MenuItem>
            <MenuItem value={"OWNER_EMPTY_HOME"}>
              I own this property and it&apos;s unoccupied
            </MenuItem>
            <MenuItem value={"OWNER_CONDO_LIVE"}>
              I own and occupy my unit in a condominium or co-op
            </MenuItem>
            <MenuItem value={"OWNER_CONDO_RENT"}>
              I own a unit in a condominium or co-op and rent it to tenants
            </MenuItem>
            <MenuItem value={"TENANT"}>
              I am a tenant of this property (do not own it)
            </MenuItem>
            <MenuItem value={"MANAGER"}>
              I manage the property&apos;s building operations and maintenance
            </MenuItem>
            <MenuItem value={"MANAGEMENT_FIRM"}>
              I work for a company that owns or manages this property
            </MenuItem>
          </Select>
        </FormControl>
        {/* Project Outcomes */}
        <Controller
          name="project_outcomes"
          control={control}
          type="text"
          defaultValue={[]}
          render={({ field }) => (
            <FormControl sx={{ m: 1 }} className={styles.FormControl}>
              <InputLabel
                id="project_outcomes"
                className={styles.InputLabel}
                size="small"
              >
                What are the most important project outcomes? (Select all that
                apply.)
              </InputLabel>
              <Select
                {...field}
                labelId="project_outcomes"
                label="What are the most important project outcomes? (Select all that apply.)"
                multiple
                required
                size="small"
                autoComplete="off"
                defaultValue={[]}
                input={
                  <OutlinedInput
                    id="select-multiple-chip-po"
                    label="What are the most important project outcomes? (Select all that apply.)"
                  />
                }
                renderValue={selected => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map(value => (
                      <Chip key={value} label={projectOutcomes[value]} />
                    ))}
                  </Box>
                )}
              >
                {Object.entries(projectOutcomes).map(([key, value]) => (
                  <MenuItem value={key} key={key}>
                    <Checkbox checked={selected_outcomes.indexOf(key) > -1} />
                    <ListItemText primary={value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          name="building_upgrades"
          control={control}
          type="text"
          defaultValue={[]}
          render={({ field }) => (
            <FormControl sx={{ m: 1 }} className={styles.FormControl}>
              <InputLabel
                id="building_upgrades"
                className={styles.InputLabel}
                size="small"
              >
                What upgrades do you want to make to your building? (Select all
                that apply.)
              </InputLabel>
              <Select
                {...field}
                labelId="building_upgrades"
                label="What upgrades do you want to make to your building? (Select all that apply.)"
                multiple
                required
                size="small"
                autoComplete="off"
                defaultValue={[]}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="What upgrades do you want to make to your building? (Select all that apply.)"
                  />
                }
                renderValue={selected => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map(value => (
                      <Chip key={value} label={buildingUpgrades[value]} />
                    ))}
                  </Box>
                )}
              >
                {Object.entries(buildingUpgrades).map(([key, value]) => (
                  <MenuItem value={key} key={key}>
                    <Checkbox checked={selected_upgrades.indexOf(key) > -1} />
                    <ListItemText primary={value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <FormControl sx={{ m: 1 }} className={styles.FormControl}>
          <InputLabel
            id="has_budget"
            className={styles.InputLabel}
            size="small"
          >
            Do you have a budget?
          </InputLabel>
          <Select
            labelId="has_budget"
            name="has_budget"
            size="small"
            id="has_budget"
            autoComplete="off"
            label="Do you have a budget?"
            defaultValue=""
            required
            {...register("has_budget")}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1 }} className={styles.FormControl}>
          <TextField
            InputLabelProps={{
              style: { color: "#413543" },
              required: false,
            }}
            type="email"
            variant="outlined"
            size="small"
            label="Email Address"
            color="primary"
            {...register("email")}
            fullWidth
            required
          />
        </FormControl>
        <FormControl sx={{ m: 1 }} className={styles.FormControl}>
          <MuiPhoneNumber
            defaultCountry={"us"}
            onlyCountries={phoneCountries}
            regions={phoneRegion}
            name="phone"
            size="small"
            variant="outlined"
            label="Phone Number (Optional)"
            disableAreaCodes={true}
            onChange={handleOnPhoneChange}
          />
        </FormControl>
        <Button styles={styles} isLoading={isLoading}>
          Submit
        </Button>
      </form>
    </div>
  )
}

IntentPageForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

IntentPageForm.defaultProps = {
  onSubmit: () => {},
}

export default IntentPageForm
