import React from "react"
import PropTypes from "prop-types"
import styles from "./BuildingPageForm.module.scss"
import { useForm } from "react-hook-form"
import Button from "components/Reusables/Button"
import rectangleLine from "../../../images/verify-rectangle-line.png"
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Grid,
  TextField,
} from "@mui/material"

const BuildingPageForm = ({ buildingInfo, onSubmit, isLoading }) => {
  const { register, handleSubmit, watch, setValue } = useForm()
  const building_type = watch("building_type")
  const heating_system_type = watch("heating_system_type")
  let smallBuildings = [
    "SINGLE_FAM",
    "HOW",
    "COMM",
    "SCHOOL",
    "HOTEL",
    "LARGE_CRE",
  ].includes(building_type)

  if (smallBuildings) {
    setValue("num_units", 1)
  }
  if (heating_system_type == "PTAC") {
    setValue("cooling_system_type", "PTAC")
    setValue("heating_source_type", "ELECT_RESIST")
  }
  if (heating_system_type == "NOT_SURE") {
    setValue("heating_source_type", "NOT_SURE")
    setValue("heating_system_condition", "NOT_SURE")
  }
  if (heating_system_type == "NO_HEATING_SYS") {
    setValue("heating_source_type", "NO_HEATING_SYS")
    setValue("heating_system_condition", "NO_HEATING_SYS")
  }

  function NumberofUnits() {
    return (
      <>
        <FormControl sx={{ m: 1 }} className={styles.FormControl}>
          <TextField
            InputProps={{
              type: "number",
              sx: {
                touchAction: false,
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              },
            }}
            InputLabelProps={{
              style: { color: "#413543" },
              required: false,
            }}
            onKeyDown={e => {
              if (
                e.key === "e" ||
                e.key === "E" ||
                e.key === "-" ||
                e.key === "+" ||
                e.key === "ArrowUp" ||
                e.key === "ArrowDown"
              ) {
                e.preventDefault()
              }
            }}
            type="number"
            variant="outlined"
            label="Number of Units"
            color="primary"
            autoComplete="off"
            {...register("num_units")}
            fullWidth
            size="small"
            required
          />
        </FormControl>
      </>
    )
  }

  let isHeatingSourceTypeVisible = [
    "STEAM",
    "HOT_WATER",
    "FORCED_AIR",
    "ELECT_RESIST",
    "HEAT_PUMP",
  ].includes(heating_system_type)

  let isHeatingSystemConditionVisible = [
    "STEAM",
    "HOT_WATER",
    "FORCED_AIR",
    "ELECT_RESIST",
    "HEAT_PUMP",
    "PTAC",
  ].includes(heating_system_type)

  let isCoolingSystemTypeVisible = [
    "STEAM",
    "HOT_WATER",
    "FORCED_AIR",
    "ELECT_RESIST",
    "HEAT_PUMP",
    "NOT_SURE",
    "NO_HEATING_SYS",
  ].includes(heating_system_type)

  let isNumUnitsVisible = ["MULTI_FAM", "MIXED_USE", "OTHER"].includes(
    building_type
  )

  return (
    <div className={styles.BuildingPageForm} data-testid="BuildingPageForm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          justifyContent="left"
          alignItems="center"
          className={styles.FormHeadingSection}
        >
          <Grid item className={styles.FormHeadingImage}>
            <img
              src={rectangleLine}
              srcSet={`${rectangleLine}, ${rectangleLine}`}
              alt="Family"
              className={styles.rectangleLine}
            />
          </Grid>
          <Grid item>
            <div className={styles.FormHeading}>
              <span className={styles.FormHeadingColorPart}>
                Building information
              </span>{" "}
              [Step 2 of 3]{" "}
            </div>
            {buildingInfo?.building_type && (
              <div>
                Pre-filled information is a best guess from third party property
                data. Please review, correct errors, and fill in gaps.
              </div>
            )}
          </Grid>
        </Grid>
        {/* Building Type */}
        <FormControl sx={{ m: 1 }} className={styles.FormControl}>
          <InputLabel
            id="building_type"
            className={styles.InputLabel}
            size="small"
          >
            Building Type
          </InputLabel>
          <Select
            labelId="building_type"
            name="building_type"
            id="building_type"
            label="Building Type"
            size="small"
            defaultValue={""}
            required
            autoComplete="off"
            {...register("building_type")}
          >
            <MenuItem value={"SINGLE_FAM"}>Single Family</MenuItem>
            <MenuItem value={"MULTI_FAM"}>Multifamily (2+ Units)</MenuItem>
            <MenuItem value={"MIXED_USE"}>
              Mixed Use (Residential / Commercial)
            </MenuItem>
            <MenuItem value={"HOTEL"}>Hotel</MenuItem>
            <MenuItem value={"HOW"}>
              House of Worship / Religious Institution
            </MenuItem>
            <MenuItem value={"COMM"}>Commercial (Non&ndash;Hotel)</MenuItem>
            <MenuItem value={"SCHOOL"}>School</MenuItem>
            <MenuItem value={"LARGE_CRE"}>Large CRE / Industrial</MenuItem>
            <MenuItem value={"OTHER"}>Other</MenuItem>
          </Select>
        </FormControl>
        {/* Number of Units */}
        <FormControl
          sx={{ m: 1 }}
          className={
            isNumUnitsVisible ? styles.FormControl : styles.HiddenField
          }
        >
          <TextField
            InputProps={{
              type: "number",
              sx: {
                touchAction: false,
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              },
            }}
            InputLabelProps={{
              style: { color: "#413543" },
              required: false,
            }}
            onKeyDown={e => {
              if (
                e.key === "e" ||
                e.key === "E" ||
                e.key === "-" ||
                e.key === "+" ||
                e.key === "ArrowUp" ||
                e.key === "ArrowDown"
              ) {
                e.preventDefault()
              }
            }}
            type="number"
            variant="outlined"
            label="Number of Units"
            color="primary"
            autoComplete="off"
            {...register("num_units")}
            fullWidth
            size="small"
            required
          />
        </FormControl>
        {/* Building Age */}
        <FormControl sx={{ m: 1 }} className={styles.FormControl}>
          <InputLabel
            id="building_age"
            className={styles.InputLabel}
            size="small"
          >
            Building Age
          </InputLabel>
          <Select
            labelId="building_age"
            name="building_age"
            id="building_age"
            label="Building Age"
            size="small"
            defaultValue={""}
            required
            autoComplete="off"
            {...register("building_age")}
          >
            <MenuItem value={"PRE_WAR"}>Pre-War (1940 and Prior)</MenuItem>
            <MenuItem value={"POST_WAR"}>Post War (1941 &ndash; 1980)</MenuItem>
            <MenuItem value={"POST_1980"}>Post 1980</MenuItem>
          </Select>
        </FormControl>
        {/* Square Footage */}
        <FormControl sx={{ m: 1 }} className={styles.FormControl}>
          <TextField
            InputProps={{
              sx: {
                touchAction: false,
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              },
            }}
            InputLabelProps={{
              style: { color: "#413543" },
              required: false,
            }}
            type="number"
            variant="outlined"
            label="Square Footage"
            color="primary"
            size="small"
            autoComplete="off"
            onKeyDown={e => {
              if (
                e.key === "e" ||
                e.key === "E" ||
                e.key === "-" ||
                e.key === "+" ||
                e.key === "ArrowUp" ||
                e.key === "ArrowDown"
              ) {
                e.preventDefault()
              }
            }}
            {...register("square_footage")}
            fullWidth
            required
          />
        </FormControl>
        {/* Number of Stories */}
        <FormControl sx={{ m: 1 }} className={styles.FormControl}>
          <TextField
            InputProps={{
              type: "number",
              sx: {
                touchAction: false,
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              },
            }}
            InputLabelProps={{
              style: { color: "#413543" },
              required: false,
            }}
            onKeyDown={e => {
              if (
                e.key === "e" ||
                e.key === "E" ||
                e.key === "-" ||
                e.key === "+" ||
                e.key === "ArrowUp" ||
                e.key === "ArrowDown"
              ) {
                e.preventDefault()
              }
            }}
            type="number"
            variant="outlined"
            label="Number of Stories"
            color="primary"
            size="small"
            {...register("num_stories")}
            fullWidth
            autoComplete="off"
            required
          />
        </FormControl>
        {/* Heating System Type */}
        <FormControl sx={{ m: 1 }} className={styles.FormControl}>
          <InputLabel
            id="demo-simple-select-label"
            className={styles.InputLabel}
            size="small"
          >
            Heating System Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Heating System Type"
            autoComplete="off"
            size="small"
            defaultValue={""}
            {...register("heating_system_type")}
            required
          >
            <MenuItem value={"STEAM"}>Steam</MenuItem>
            <MenuItem value={"HOT_WATER"}>Hot Water</MenuItem>
            <MenuItem value={"FORCED_AIR"}>Forced Air</MenuItem>
            <MenuItem value={"ELECT_RESIST"}>Electric Baseboard</MenuItem>
            <MenuItem value={"HEAT_PUMP"}>Heat Pump</MenuItem>
            <MenuItem value={"PTAC"}>PTAC</MenuItem>
            <MenuItem value={"NOT_SURE"}>I don&apos;t know</MenuItem>
            <MenuItem value={"NO_HEATING_SYS"}>
              I don&apos;t have a heating system
            </MenuItem>
          </Select>
        </FormControl>
        {/* Heating Source Type */}
        <FormControl
          sx={{ m: 1 }}
          className={
            isHeatingSourceTypeVisible ? styles.FormControl : styles.HiddenField
          }
        >
          <InputLabel
            id="demo-simple-select-label"
            className={styles.InputLabel}
            size="small"
          >
            Heating Source Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Heating Source Type"
            autoComplete="off"
            defaultValue={""}
            {...register("heating_source_type")}
            size="small"
            required
          >
            <MenuItem value={"OIL"}>Oil</MenuItem>
            <MenuItem value={"NATURAL_GAS"}>Natural Gas</MenuItem>
            <MenuItem value={"DUAL_FUEL"}>Dual Fuel</MenuItem>
            <MenuItem value={"ELECT_RESIST"}>Electric</MenuItem>
            <MenuItem value={"OTHER"}>Other</MenuItem>
          </Select>
        </FormControl>
        {/* Heating System Condition */}
        <FormControl
          sx={{ m: 1 }}
          className={
            isHeatingSystemConditionVisible
              ? styles.FormControl
              : styles.HiddenField
          }
        >
          <InputLabel
            id="demo-simple-select-label"
            className={styles.InputLabel}
            size="small"
          >
            Heating System Condition
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Heating System Condition"
            defaultValue={""}
            {...register("heating_system_condition")}
            size="small"
            autoComplete="off"
            required
          >
            <MenuItem value={"GOOD"}>Good &ndash; Good working order</MenuItem>
            <MenuItem value={"FAIR"}>
              Fair &ndash; Needs frequent maintenance
            </MenuItem>
            <MenuItem value={"POOR"}>
              Poor &ndash; Nearing end of useful life
            </MenuItem>
            <MenuItem value={"BROKEN"}>
              Broken &ndash; Disrepair or broken
            </MenuItem>
          </Select>
        </FormControl>
        {/* Cooling System Type */}
        <FormControl
          sx={{ m: 1 }}
          className={
            isCoolingSystemTypeVisible ? styles.FormControl : styles.HiddenField
          }
        >
          <InputLabel
            id="demo-simple-select-label"
            className={styles.InputLabel}
            size="small"
          >
            Cooling System Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Cooling System Type"
            autoComplete="off"
            defaultValue={""}
            size="small"
            {...register("cooling_system_type")}
            required
          >
            <MenuItem value={"WINDOW_UNIT"}>Window Units</MenuItem>
            <MenuItem value={"WALL_SLEEVE"}>Through Wall Sleeve Unit</MenuItem>
            <MenuItem value={"CENTRAL_SYS"}>Central System</MenuItem>
            <MenuItem value={"PTAC"}>PTAC</MenuItem>
            <MenuItem value={"NONE"}>None</MenuItem>
          </Select>
        </FormControl>
        <Button styles={styles} isLoading={isLoading}>
          Next
        </Button>
      </form>
    </div>
  )
}

BuildingPageForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  buildingInfo: PropTypes.object,
}

BuildingPageForm.defaultProps = {
  buildingInfo: {},
  onSubmit: () => {},
}

export default BuildingPageForm
