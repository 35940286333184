import React from "react"
import PropTypes from "prop-types"
import styles from "./Footer.module.scss"

const Footer = () => (
  <div className={styles.Footer} data-testid="Footer">
    <a
      href="https://www.blocpower.io/terms-conditions"
      target="_blank"
      rel="noreferrer"
    >
      Terms of Service
    </a>{" "}
    |{" "}
    <a
      href="https://www.blocpower.io/privacy-policy-2"
      target="_blank"
      rel="noreferrer"
    >
      Privacy Policy
    </a>
  </div>
)

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
