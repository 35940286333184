import { React, useEffect } from "react"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom"
import "./css/App.css"
import TagManager from "react-gtm-module"
import ReportPage from "./components/Report/ReportPage"
import BMMGReport from "./components/Report/BMMGReport"
import DenverIncomeQualificationPage from "./components/IncomeQulaificationPage/DenverIncomeQualificationPage"
import DenverLandingPage from "./components/LandingPages/DenverLandingPage"
import DenverSFLandingPage from "./components/LandingPages/DenverSFLandingPage"
import SanJoseLandingPage from "./components/LandingPages/SanJoseLandingPage"
import ComedLandingPage from "./components/LandingPages/ComEdLandingPage"
import ComEdQualificationPage from "./components/IncomeQulaificationPage/ComEdQualificationPage"
import CambridgeLandingPage from "./components/LandingPages/CambridgeLandingPage"
import FinancingLandingPage from "./components/LandingPages/FinancingLandingPage/FinancingLandingPage"
import Subscriber from "./components/LandingPages/Subscriber"
import Initiate from "./components/IBRv2/Initiate/Initiate"
import Waitlist from "./components/LandingPages/Waitlist"
import BuildingPage from "./components/IBRv2/BuildingPage/BuildingPage"
import IntentPage from "./components/IBRv2/IntentPage/IntentPage"
import InMarketLMF from "./components/LandingPages/InMarketLMF/InMarketLMF"
import InMarketSF from "./components/LandingPages/InMarketSF/InMarketSF"
import Assessment from "./components/IBRv2/Assessment/Assessment"
import DownloadAssessmentPage from "./components/IBRv2/DownloadAssessmentPage/DownloadAssessmentPage"
import { Helmet } from "react-helmet"
import InMarketLandingPage from "./components/LandingPages/InMarketLandingPage"
import * as Sentry from "@sentry/react"
import AssessmentPage from "components/IBRv2/ReportV2/AssessmentPage/AssessmentPage"

const App = () => {
  Sentry.init({
    dsn: "https://4cd4d25bfff14571b74ffc2662569fc1@o1253910.ingest.sentry.io/6421551",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    debug: false,
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
  })

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)
  let tagManagerArgs = {
    gtmId: "GTM-PKJ8WV2",
  }
  // Set up Tag Manager for different environments
  if (process.env.NODE_ENV == "staging") {
    tagManagerArgs = {
      ...tagManagerArgs,
      auth: "-0TIx3jPKPaLJ1IAsj9CCA",
      preview: "env-105",
      cookiesWin: "x",
    }
    TagManager.initialize(tagManagerArgs)
  } else if (
    process.env.NODE_ENV == "development" ||
    process.env.NODE_ENV != "production"
  ) {
    tagManagerArgs = {
      ...tagManagerArgs,
      auth: "Ogj7j4nAyjhZeJhiEUG-kQ",
      preview: "env-106",
      cookiesWin: "x",
    }
    TagManager.initialize(tagManagerArgs)
  } else {
    TagManager.initialize(tagManagerArgs)
  }
  const hubspotKey = process.env.REACT_APP_HUBSPOT_KEY

  return (
    <>
      {hubspotKey && (
        <Helmet>
          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js.hs-scripts.com/20299415.js"
          ></script>
        </Helmet>
      )}
      <div>
        <Router>
          <SentryRoutes>
            <Route index element={<Initiate />} />
            <Route path="building">
              <Route path=":submissionId" element={<BuildingPage />} />
            </Route>
            <Route path="intent">
              <Route path=":submissionId" element={<IntentPage />} />
            </Route>
            <Route path="assessment">
              <Route path=":submissionId" element={<Assessment />} />
            </Route>
            <Route path="assessmentdownload">
              <Route
                path=":submissionId"
                element={<DownloadAssessmentPage />}
              />
            </Route>
            <Route path="denvereligibility">
              <Route
                path=":buildingId"
                element={<DenverIncomeQualificationPage />}
              />
            </Route>
            <Route path="comedeligibility">
              <Route path=":buildingId" element={<ComEdQualificationPage />} />
            </Route>
            <Route path="DenverLandingPage" element={<DenverLandingPage />} />
            <Route
              path="cambridgelandingpage"
              element={<CambridgeLandingPage />}
            />
            <Route path="ComedLandingPage" element={<ComedLandingPage />} />
            <Route path="waitlist" element={<Waitlist />} />
            <Route path="subscriber" element={<Subscriber />} />
            <Route path="report/" element={<BMMGReport />} />
            <Route path="inmarketsf" element={<InMarketSF />} />
            <Route path="inmarketlmf" element={<InMarketLMF />} />
            <Route path="report/:buildingId" element={<ReportPage />} />
            <Route path="inmarket" element={<InMarketLandingPage />} />
            <Route path="denvereligibility">
              <Route
                path=":buildingId"
                element={<DenverIncomeQualificationPage />}
              />
            </Route>
            <Route
              path="cambridgelandingpage"
              element={<CambridgeLandingPage />}
            />
            <Route path="financing" element={<FinancingLandingPage />} />
            <Route path="comedeligibility">
              <Route path=":buildingId" element={<ComEdQualificationPage />} />
            </Route>
            <Route path="denverLandingPage" element={<DenverLandingPage />} />
            <Route
              path="denversflandingpage"
              element={<DenverSFLandingPage />}
            />
            <Route path="sanjoselandingpage" element={<SanJoseLandingPage />} />
            <Route path="ComedLandingPage" element={<ComedLandingPage />} />
            <Route path="reportv2" element={<AssessmentPage />} />
            {/* Some paths have been used for A/B testing in the past. Redirect to / to support people who had old ones bookmarked. */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </SentryRoutes>
        </Router>
      </div>
    </>
  )
}

App.propTypes = {}

App.defaultProps = {}

export default App
