import React from "react"
import PropTypes from "prop-types"
import styles from "./ImplementationOptions.module.scss"
import DiySection from "../DiySection"
import HireBlocPower from "../HireBlocPower"

const ImplementationOptions = ({ reportInfo, ibr }) => {
  let isCambridgeLead = reportInfo?.is_cambridge_lead
  let isComedLead = reportInfo?.is_comed_lead
  let sectionTitle = "Project Implementation Options"
  return (
    <div
      className={styles.ImplementationOptions}
      data-testid="ImplementationOptions"
    >
      {!(isCambridgeLead || isComedLead) && (
        <div className={styles.Title}>{sectionTitle}</div>
      )}
      <div className={styles.FloatingCells}>
        <HireBlocPower reportInfo={reportInfo} ibr={ibr} />
        {!(isCambridgeLead || isComedLead) && <DiySection {...reportInfo} />}
      </div>
    </div>
  )
}

ImplementationOptions.propTypes = {
  reportInfo: PropTypes.object,
  ibr: PropTypes.string,
}

ImplementationOptions.defaultProps = {}

export default ImplementationOptions
