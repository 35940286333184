import React from "react"
import PropTypes from "prop-types"
import styles from "./InMarketLMF.module.scss"

const InMarketLMF = () => (
  <div className={styles.InMarketLMF} data-testid="InMarketLMF">
    {window.location.replace(process.env.REACT_APP_LMF_PAGE_URL)}
  </div>
)

InMarketLMF.propTypes = {}

InMarketLMF.defaultProps = {}

export default InMarketLMF
