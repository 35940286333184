import React from "react"
import PropTypes from "prop-types"
import styles from "./Button.module.scss"
import { ButtonArraowIcon } from "components/Reusables/Icons"
import { ButtonArraowLightIcon } from "components/Reusables/Icons"
import CircularProgress from "@mui/material/CircularProgress"

const Button = ({ onClick, children, styles, arrow, isLoading }) => {
  let arrowIcon = <ButtonArraowIcon />
  if (arrow == "light") {
    arrowIcon = <ButtonArraowLightIcon />
  } else if (arrow == "none") {
    arrowIcon = <></>
  }
  let buttonText = isLoading ? (
    <>
      <div className={styles.loader}></div>
    </>
  ) : (
    <>
      {children}
      {arrowIcon}
    </>
  )

  return (
    <button
      disabled={isLoading}
      className={isLoading ? styles.loadingButtonSection : styles.Button}
      data-testid="Button"
      onClick={onClick}
    >
      {buttonText}
    </button>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  arrow: PropTypes.string,
  styles: PropTypes.object,
  isLoading: PropTypes.bool,
}

Button.defaultProps = {
  arrow: "dark",
  styles: {},
  isLoading: false,
  children: "",
}

export default Button
