import React from "react"
import PropTypes from "prop-types"
import styles from "./InMarketSF.module.scss"

const InMarketSF = () => (
  <div className={styles.InMarketSF} data-testid="InMarketSF">
    {window.location.replace(process.env.REACT_APP_SF_PAGE_URL)}
  </div>
)

InMarketSF.propTypes = {}

InMarketSF.defaultProps = {}

export default InMarketSF
