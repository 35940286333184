import React from "react"
import PropTypes from "prop-types"
import styles from "./DownloadAssessmentPage.module.scss"
import BPLogoPurple from "images/bp-logo-purple.svg"
import BuildingIcon from "images/icons/grey-buildingtype.svg"
import BuildingSizeIcon from "images/icons/grey-square-ft.svg"
import BuildingSystemIcon from "images/icons/grey-system.svg"
import { Grid, Divider } from "@mui/material"
import AshpIcon from "images/icons/ashp_icon.svg"
import DhwIcon from "images/icons/dhw_icon.svg"
import EVIcon from "images/icons/ev_charger_icon.svg"
import IcIcon from "images/icons/ic_icon.svg"
import SolarIcon from "images/icons/solar_pv_icon.svg"
import EPIcon from "images/icons/electrical_panel_icon.svg"
import EnergyIcon from "images/icons/energy_icon.svg"
import EandDIcon from "images/icons/e_and_d_icon.svg"
import PermittingIcon from "images/icons/permitting_icon.svg"
import ConstructionIcon from "images/icons/construction_man_icon.svg"
import IncentiveIcon from "images/icons/incentive_app_icon.svg"
import CommissioningIcon from "images/icons/commissioning_icon.svg"
import MaintenanceIcon from "images/icons/ongoing_maint_icon.svg"
import ReactToPrint from "react-to-print"
import EstimatedEquipmentCost from "../../IBRv2/ReportV2/EstimatedEquipmentCost"

const DownloadAssessmentPage = ({ address, reportInfo, closePrintPage }) => {
  const componentRef = React.useRef(null)

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current
  }, [componentRef.current])

  const reactToPrintTrigger = React.useCallback(() => {
    return <button className={styles.button}>Download</button>
  }, [])

  //Building Summary Section Mapping
  const buildingAgeMapping = {
    PRE_WAR: "Pre-War (Prior to 1940)",
    POST_WAR: "Post War (1941-1980)",
    POST_1980: "Post-1980",
  }

  const buildingTypeMapping = {
    SINGLE_FAM: "Single Family",
    MULTI_FAM: "Multifamily (2+ Units)",
    MIXED_USE: "Mixed Use (Residential / Commercial)",
    HOTEL: "Hotel",
    HOW: "House of Worship / Religious Institution",
    COMM: "Commercial (Non-Hotel)",
    SCHOOL: "School",
    LARGE_CRE: "Large CRE / Industrial",
    OTHER: "Other",
  }
  const heatingSystemMapping = {
    STEAM: "Steam",
    HOT_WATER: "Hot Water",
    FORCED_AIR: "Forced Air",
    ELECT_RESIST: "Electric Baseboard",
    HEAT_PUMP: "Heat Pump",
    PTAC: "PTAC",
    NOT_SURE: "Unknown",
    NO_HEATING_SYS: "No Heating System",
  }
  const heatingSourcelMapping = {
    OIL: "Oil",
    NATURAL_GAS: "Natural Gas",
    DUAL_FUEL: "Duel Fuel",
    ELECT_RESIST: "Electric",
    OTHER: "Other",
    NOT_SURE: "Unknown",
    NO_HEATING_SYS: "No Heating System",
  }
  const addHeatingSource = !["NOT_SURE", "NO_HEATING_SYS"].includes(
    reportInfo?.heating_source_type
  )

  //Opportunities for Building
  let showCarbonEmission = false
  let showFinancing = false
  if (reportInfo?.project_outcomes) {
    showCarbonEmission =
      Object.values(reportInfo?.project_outcomes).includes("IMPROVE_HEALTH") ||
      Object.values(reportInfo?.project_outcomes).includes(
        "REDUCE_GAS_EMISSIONS"
      ) ||
      reportInfo?.heating_source_type != "OIL"
    showFinancing =
      Object.values(reportInfo?.project_outcomes).includes(
        "FINANCE_UPGRADES"
      ) ||
      (reportInfo?.heating_source_type != "OIL" &&
        !["ELECT_RESIST", "PTAC"].includes(reportInfo?.heating_system_type))
  }

  // Affordability Section
  let totalIncentives = 0
  if (reportInfo?.total_incentive) {
    totalIncentives = reportInfo?.total_incentive?.toLocaleString()
  }

  // SoW: Equipment Upgrades
  const sowMapping = {
    ASHP: {
      icon: AshpIcon,
      title: "Cold Climate Air Source Heat Pump",
      description:
        "Air Source Heat Pumps are effective, low-carbon heating and cooling solutions that use electricity to transfer heat energy in and out of buildings instead of generating heat through combustion of oil or gas. Heat Pumps are 3 to 6 times more energy efficient than conventional heating and cooling technologies.",
    },
    HPWH: {
      icon: DhwIcon,
      title: "Heat Pump Water Heater",
      description:
        "Heat Pump Water Heaters are effective, low-carbon heating and cooling solutions that use electricity to transfer heat energy in and out of buildings instead of generating heat from oil or gas. Heat Pumps are 2 to 4 times more energy efficient than conventional water heating technologies.",
    },
    INDUCTION_STOVE: {
      icon: IcIcon,
      title: "Induction Cooktop",
      description:
        "Induction cooking is performed on an electric stove using direct induction heating instead of cooking vessels and is quite efficient, which means it puts out less waste heat and it can be quickly turned on and off. Induction cooktops provide substantial health benefits over gas stoves. They also boil water faster, heat food more consistently, can be controlled at precise temperatures, are easier to clean, and less likely to cause burns.",
    },
    COMM_SOLAR: {
      icon: SolarIcon,
      title: "Solar Panels",
      description:
        "Solar Photovoltaics (PV) reduce net electric consumption and can generate even more savings by bundling with heat pumps and other electric appliances.",
    },
    AIR_SEALING: {
      icon: EPIcon,
      title: "Air Sealing and Insulation",
      description:
        "Cut down on energy waste by sealing air leakages in air ducts, cavities, doors, ramps, and overhead door hinges, as well as caulking windows and doors.",
    },
    EV_CHARGING: {
      icon: EVIcon,
      title: "EV Charging",
      description:
        "Installing EV charging at your building enhances competitiveness by attracting environmentally-conscious tenants and customers while future-proofing your property for the electrification. It generates additional revenue streams, helps with compliance regulations, and improves tenant satisfaction. Moreover, higher incentives, reduced parking congestion, and an enhanced corporate image further justify the investment in EV charging infrastructure.",
    },
  }

  // Incentives
  let upgradeMeasureItems = reportInfo?.upgrade_measures.filter(
    upgrade_measure => upgrade_measure.upgrade_measure_incentive != "0"
  )

  return (
    <div
      className={styles.DownloadAssessmentPage}
      data-testid="DownloadAssessmentPage"
      ref={componentRef}
    >
      {/* Top Buttons */}
      <div className={styles.buttonSection}>
        <ReactToPrint
          documentTitle="BlocPower Personal Electrification Guide"
          content={reactToPrintContent}
          trigger={reactToPrintTrigger}
        />

        <button className={styles.CloseButton} onClick={closePrintPage}>
          Back
        </button>
      </div>
      {/* OVERVIEW SECTION */}
      <div className={styles.pagebreak}>
        <div className={styles.Logo}>
          <img
            className={styles.BPLogo}
            src={BPLogoPurple}
            alt="BlocPower Logo"
          />
        </div>
        <div className={styles.HeaderText}>
          Project Assessment for {address}
        </div>
        <div className={styles.SectionTitle}>Building Summary</div>
        <div className={styles.BuildingSummary}>
          <Grid container className={styles.SummaryDetails}>
            <Grid item xs={2} sm={2} md={2} lg={3}>
              <Grid
                item
                display={"inline-flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img
                  className={styles.SummaryIcon}
                  alt={"BuildingType"}
                  src={BuildingIcon}
                />
                {buildingTypeMapping[reportInfo?.building_type]}
              </Grid>
              <Grid item justifyContent={"center"} alignItems={"center"}></Grid>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <Grid
                item
                display={"inline-flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img
                  className={styles.SummaryIcon}
                  alt={"NumUnitsIcon"}
                  src={BuildingIcon}
                />
                {buildingAgeMapping[reportInfo?.building_age]}
              </Grid>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <Grid
                item
                display={"inline-flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img
                  className={styles.SummaryIcon}
                  alt={"Square Footage Icon"}
                  src={BuildingSizeIcon}
                />
                {reportInfo?.num_units} unit(s),{" "}
                {reportInfo?.square_footage?.toLocaleString()} Sq. Ft.
              </Grid>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={3}>
              <Grid
                item
                display={"inline-flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img
                  className={styles.SummaryIcon}
                  alt={"Heating System Icon"}
                  src={BuildingSystemIcon}
                />
                {heatingSystemMapping[reportInfo?.heating_system_type]}
                {addHeatingSource && <> System fueled by </>}
                {addHeatingSource &&
                  heatingSourcelMapping[reportInfo?.heating_source_type]}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Divider className={styles.Divider} />
        <Grid
          container
          padding={"10px 0px"}
          spacing={2}
          alignItems="center"
          flexWrap={"nowrap"}
        >
          <Grid item>
            <div className={styles.OpportunityNumberSec}>
              <div className={styles.OpportunityNumber}>
                {reportInfo?.score}/10
              </div>
            </div>
          </Grid>
          <Grid item>
            <div className={styles.OpportunityHeaderText}>
              Retrofit Opportunity Score
            </div>
            <div className={styles.OpportunityRegularText}>
              Determined by your building typology, existing HVAC system and
              potential to benefit from upgrades.
            </div>
          </Grid>
        </Grid>
        <div className={styles.SectionTitle}>
          Top Opportunities for Your Building
        </div>
        <Grid item sm={12} border={1}>
          {reportInfo?.heating_source_type == "OIL" && (
            <Grid
              container
              alignItems="center"
              flexWrap={"nowrap"}
              borderBottom={1}
              className={styles.OpportunityTable}
            >
              <Grid item sm={12}>
                <div className={styles.OpportunityTitle}>
                  Reduce Operating Costs
                </div>

                <div className={styles.OpportunitySubTitle}>
                  Estimated 20-30% annual savings
                </div>
                <div className={styles.OpportunityDescription}>
                  Heat pump systems are 2-4X more efficient and typically result
                  in 20-30% lower heating bills than oil, which has seen prices
                  rise{" "}
                  <a
                    href="https://www.eia.gov/dnav/pet/hist/LeafHandler.ashx?n=PET&s=M_EPD2F_PRS_R1X_DPG&f=M"
                    target="_blank"
                    rel="noreferrer"
                  >
                    85%
                  </a>{" "}
                  from Jan &apos;21 to &apos;23. Combined with smart monitoring,
                  heat pumps also ensure reliable performance.
                </div>
              </Grid>
            </Grid>
          )}

          {["ELECT_RESIST", "PTAC"].includes(
            reportInfo?.heating_system_type
          ) && (
            <Grid
              container
              alignItems="center"
              flexWrap={"nowrap"}
              borderBottom={1}
              className={styles.OpportunityTable}
            >
              <Grid item sm={12}>
                <div className={styles.OpportunityTitle}>
                  Reduce Operating Costs
                </div>
                <div className={styles.OpportunitySubTitle}>
                  Cut heating bills in half
                </div>
                <div className={styles.OpportunityDescription}>
                  Heat pumps use less than half the energy of other electric
                  heating systems including PTACs or baseboards. In addition to
                  lowering heating bills by 50% or more, most heat pumps systems
                  come with 10+ year equipment warranties.
                </div>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            alignItems="center"
            flexWrap={"nowrap"}
            borderBottom={1}
            className={styles.OpportunityTable}
          >
            <Grid item sm={12}>
              <div className={styles.OpportunityTitle}>
                Improve Occupant Comfort
              </div>

              <div className={styles.OpportunitySubTitle}>
                Goodbye complaints!
              </div>
              <div className={styles.OpportunityDescription}>
                Introducing your all-in-one heating and cooling system. Heat
                pumps provide precise, room-by-room temperature control in all
                seasons for dependable comfort. They steadily and quietly
                deliver conditioned and filtered air. No more uneven
                temperatures, no more complaints!
              </div>
            </Grid>
          </Grid>
          {showCarbonEmission && (
            <Grid
              container
              alignItems="center"
              flexWrap={"nowrap"}
              borderBottom={1}
              className={styles.OpportunityTable}
            >
              <Grid item sm={12}>
                <div className={styles.OpportunityTitle}>
                  Cut Carbon Emissions
                </div>

                <div className={styles.OpportunitySubTitle}>
                  35-93% lifetime reduction
                </div>
                <div className={styles.OpportunityDescription}>
                  Over 15 years, a heat pump operating on the nation&apos;s most
                  carbon-intensive electric grid will still{" "}
                  <a href="https://rmi.org/now-is-the-time-to-go-all-in-on-heat-pumps/">
                    reduce emissions by 35%
                  </a>{" "}
                  versus a high-efficiency gas furnace. This number rises as
                  high as 93% for states predominantly utilizing clean energy.
                  No more fossil fuel combustion equals cleaner air and
                  healthier communities.
                </div>
              </Grid>
            </Grid>
          )}
          {showFinancing && (
            <Grid
              container
              alignItems="center"
              flexWrap={"nowrap"}
              borderBottom={1}
              className={styles.OpportunityTable}
            >
              <Grid item sm={12}>
                <div className={styles.OpportunityTitle}>
                  Utilize Flexible Financing
                </div>

                <div className={styles.OpportunitySubTitle}>
                  Complete deferred maintenance
                </div>
                <div className={styles.OpportunityDescription}>
                  Our $0 down financing makes clean energy projects affordable
                  and can also cover supporting upgrades, including roof
                  replacement, mold and asbestos remediation, new windows,
                  facade repair, electrical and more.
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          alignItems="center"
          flexWrap={"nowrap"}
          className={styles.AffordabilitySection}
        >
          <Grid
            container
            alignItems="center"
            flexWrap={"nowrap"}
            backgroundColor={"#F1EFFD"}
            className={styles.ProjectAffordability}
          >
            <Grid>
              <div className={styles.AffordabilityTitle}>
                How We Maximize Project Affordability
              </div>

              <Grid
                container
                className={styles.AffordabilitySection}
                justifyItems={"center"}
                display={"block"}
              >
                <div className={styles.OpportunitySubTitle}>
                  Leverage All Incentives
                </div>
                <Grid item display={"flex"}>
                  <div className={styles.OpportunityDescription}>
                    Potentially eligible for{" "}
                  </div>
                  <div className={styles.IncentivesAmount}>
                    {" "}
                    ${totalIncentives}{" "}
                  </div>
                </Grid>
                <Grid
                  container
                  className={styles.AffordabilitySection}
                  justifyItems={"center"}
                >
                  <div className={styles.OpportunitySubTitle}>
                    Reduced Energy Bills Drive ROI
                  </div>
                  <div className={styles.OpportunityDescription}>
                    Oil-fueled heating systems switching to electric heat pumps
                    have historically saved 30% in annual energy costs.{" "}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            className={styles.OpportunityTable}
            backgroundColor={"#F1åEFFD"}
            width={"60%"}
          >
            <Grid item>
              <div className={styles.AffordabilityTitle}>
                How accurate are these estimates?{" "}
              </div>
            </Grid>
            <Grid item>
              <div className={styles.OpportunityDescription}>
                The incentives, cost and energy bill savings estimates are
                predictions with the information we have available. To get a
                more accurate estimate, schedule a call with our expert team.{" "}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {/* SCOPE OF WORK */}
      <div className={styles.pagebreak}>
        <div className={styles.SectionTitle}>Recommended Scope of Work</div>
        <Divider className={styles.Divider} />
        <div className={styles.HeaderText}>Retrofits</div>
        <div className={styles.OpportunityDescription}>
          BlocPower is your singular, trusted point of contact through the
          project&apos;s completion and beyond.
        </div>
        <div className={styles.SectionTitle}>Equipment Upgrades </div>
        {/* These hard coded values need to be dynamic, same as the assessment.js */}
        <Grid item sm={12} border={1}>
          {Object.entries(sowMapping)
            .filter(([key]) => reportInfo?.scope_of_work?.includes(key))
            .map(([key, value]) => (
              <Grid
                container
                alignItems="center"
                flexWrap={"nowrap"}
                borderBottom={1}
                className={styles.OpportunityTable}
                key={"EqupmentUpgrades_" + key}
              >
                <Grid item sm={12}>
                  <Grid
                    container
                    alignItems="center"
                    flexWrap={"nowrap"}
                    className={styles.SubsectionTitleText}
                    id={key}
                  >
                    <img
                      className={styles.Icon}
                      alt={value.title}
                      src={value.icon}
                    />
                    <div>{value.title}</div>
                  </Grid>
                  <div className={styles.SubsectionDescriptionText}>
                    {value.description}
                  </div>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </div>
      <div className={styles.pagebreak}>
        <div className={styles.SectionTitle}>Recommended Scope of Work</div>
        <Divider className={styles.Divider} />
        <div className={styles.OpportunitySubTitle}>Project Guide </div>
        <Grid item sm={12} border={1}>
          <Grid
            container
            alignItems="center"
            flexWrap={"nowrap"}
            borderBottom={1}
            className={styles.OpportunityTable}
          >
            <Grid item sm={12}>
              <Grid
                container
                alignItems="center"
                flexWrap={"nowrap"}
                className={styles.SubsectionTitleText}
              >
                <img className={styles.Icon} alt={"ashp"} src={EnergyIcon} />
                <div>Energy Assessment</div>
              </Grid>
              <div className={styles.SubsectionDescriptionText}>
                Begin with a free energy assessment to know where your building
                stands, including potential fines from local laws.{" "}
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            flexWrap={"nowrap"}
            borderBottom={1}
            className={styles.OpportunityTable}
          >
            <Grid item sm={12}>
              <Grid
                container
                alignItems="center"
                flexWrap={"nowrap"}
                className={styles.SubsectionTitleText}
              >
                <img className={styles.Icon} alt={"ashp"} src={EandDIcon} />
                <div>Engineering and Design</div>
              </Grid>
              <div className={styles.SubsectionDescriptionText}>
                BlocPower engineers bring decades of expertise to analyze your
                building and design the optimal solution for your
                building&apos;s unique needs.
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            flexWrap={"nowrap"}
            borderBottom={1}
            className={styles.OpportunityTable}
          >
            <Grid item sm={12}>
              <Grid
                container
                alignItems="center"
                flexWrap={"nowrap"}
                className={styles.SubsectionTitleText}
              >
                <img
                  className={styles.Icon}
                  alt={"ashp"}
                  src={PermittingIcon}
                />
                <div>Permitting</div>
              </Grid>
              <div className={styles.SubsectionDescriptionText}>
                We take care of the paperwork so your project complies with
                zoning regulations, building codes and other requirements.
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            flexWrap={"nowrap"}
            borderBottom={1}
            className={styles.OpportunityTable}
          >
            <Grid item sm={12}>
              <Grid
                container
                alignItems="center"
                flexWrap={"nowrap"}
                className={styles.SubsectionTitleText}
              >
                <img
                  className={styles.Icon}
                  alt={"ashp"}
                  src={ConstructionIcon}
                />
                <div>Construction Management</div>
              </Grid>
              <div className={styles.SubsectionDescriptionText}>
                Trust our vetted, job-tested, proven construction crews to get
                your project done right and on schedule.
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="center"
            flexWrap={"nowrap"}
            borderBottom={1}
            className={styles.OpportunityTable}
          >
            <Grid item sm={12}>
              <Grid
                container
                alignItems="center"
                flexWrap={"nowrap"}
                className={styles.SubsectionTitleText}
              >
                <img className={styles.Icon} alt={"ashp"} src={IncentiveIcon} />
                <div>Incentive Application</div>
              </Grid>
              <div className={styles.SubsectionDescriptionText}>
                Incentive capture is a complex but critical step. We help you
                identify and apply for all available incentives to lower your
                cost and increase your ROI.
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            flexWrap={"nowrap"}
            borderBottom={1}
            className={styles.OpportunityTable}
          >
            <Grid item sm={12}>
              <Grid
                container
                alignItems="center"
                flexWrap={"nowrap"}
                className={styles.SubsectionTitleText}
              >
                <img
                  className={styles.Icon}
                  alt={"ashp"}
                  src={CommissioningIcon}
                />
                <div>Commissioning</div>
              </Grid>
              <div className={styles.SubsectionDescriptionText}>
                Thorough testing, verification and documentation ensures our
                work meets quality and safety standards.
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="center"
            flexWrap={"nowrap"}
            borderBottom={1}
            className={styles.OpportunityTable}
          >
            <Grid item sm={12}>
              <Grid
                container
                alignItems="center"
                flexWrap={"nowrap"}
                className={styles.SubsectionTitleText}
              >
                <img
                  className={styles.Icon}
                  alt={"ashp"}
                  src={MaintenanceIcon}
                />
                <div>Ongoing Maintenance Post-Install</div>
              </Grid>
              <div className={styles.SubsectionDescriptionText}>
                Remote monitoring and ongoing maintenance come standard, giving
                you one less thing to worry about.
              </div>
            </Grid>
          </Grid>
        </Grid>
        <div className={styles.HeaderText}>Estimated Cost</div>
        <div className={styles.EstimatedCostTable}>
          <EstimatedEquipmentCost
            upgradeMeasures={reportInfo?.upgrade_measures}
          />
        </div>
      </div>
      {/* ESTIMATED INCENTIVES SECTION */}
      <div className={styles.pagebreak}>
        <div className={styles.SectionTitle}>Estimated Incentives</div>
        <Divider className={styles.Divider} />
        <div className={styles.SubsectionDescriptionText}>
          This list includes incentives from the Federal IRA (available by the
          end of 2023) and some local programs. We do our best to ensure an
          accurate list of incentives, but actual incentives may differ.
          Schedule a call to maximize and start applying for incentives.
        </div>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <div>
              {upgradeMeasureItems?.map((um, i) => (
                <Grid container key={um.upgrade_measure_key} display={"block"}>
                  <Grid id={um.upgrade_measure_key}>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      flexWrap={"wrap"}
                    >
                      <Grid item spacing={8}>
                        <div className={styles.UMTitle}>
                          {um.upgrade_measure_name}
                        </div>
                      </Grid>
                      <Grid item>
                        <div className={styles.UMAmount}>
                          ${um.upgrade_measure_incentives_min}&ndash;$
                          {um.upgrade_measure_incentives_max}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid>
                    {um.incentives.map(incentive => (
                      <>
                        <Grid
                          container
                          spacing={2}
                          flexWrap={"nowrap"}
                          alignItems={"center"}
                        >
                          <Grid item>
                            <div className={styles.UMText}>
                              {incentive.incentive_display_name}
                            </div>
                          </Grid>
                          <Grid item>
                            <div className={styles.UMType}>
                              {incentive.incentive_type.toUpperCase()}
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <p className={styles.UMContent}>
                            {incentive.incentive_explainer_text}
                          </p>
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </Grid>
              ))}
              <Divider className={styles.Divider} />
              <Grid
                container
                spacing={2}
                justifyContent={"space-between"}
                flexWrap={"nowrap"}
                className={styles.TotalSection}
              >
                <Grid item>
                  <div className={styles.TotalIncentivesText}>
                    Total Incentives
                  </div>
                </Grid>
                <Grid item>
                  <div className={styles.TotalIncentivesAmount}>
                    ${reportInfo?.incentives_min}&ndash;$
                    {reportInfo?.incentives_max}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      {/* FAQ SECTION */}
      <div className={styles.pagebreak}>
        <div className={styles.SectionTitle}>FAQs</div>
        <Divider className={styles.Divider} />
        <Grid
          container
          alignItems="center"
          flexWrap={"nowrap"}
          borderBottom={1}
          className={styles.OpportunityTable}
        >
          <Grid item sm={12}>
            <div className={styles.UMTitle}>
              How does all-electric heat and water work?
            </div>
            <div className={styles.OpportunityDescription}>
              Heating, cooling and hot water system equipment that is powered by
              electricity—not the combustion of fossil fuels—uses ambient air
              from outside the building or the ground as either a heat source or
              heat sink. Check out this resource guide for a deeper look, or
              read on for a high-level overview. <br /> <br /> This technology,
              called a heat pump, gets its name because it moves heat into a
              building — it doesn&apos;t generate it as furnaces and boilers do.
              Heat pumps also function in reverse, acting as an air conditioner.
              In heating mode, the ambient air from outside the building acts as
              the heat source. Modern technology makes this possible even when
              the ambient air is cold; the heat pump&apos;s refrigerant can
              extract heat even in cold climates. <br /> <br /> Like any heating
              and cooling system, electricity-powered equipment are at their
              best in a home or building that maximizes efficiency, which is why
              BlocPower offers these systems in conjunction with other
              efficiency measures as a part of the Climate Comfort Service.{" "}
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          flexWrap={"nowrap"}
          borderBottom={1}
          className={styles.OpportunityTable}
        >
          <Grid item sm={12}>
            <div className={styles.UMTitle}>
              Why switch to a heat pump HVAC or hot water system?{" "}
            </div>
            <div className={styles.OpportunityDescription}>
              The best reason for upgrading to a heat pump for your heating and
              cooling needs is because of its performance—it&apos;s 2 to 4 times
              more efficient than boilers, furnaces or window air conditioners.
              <br /> <br /> In addition, heat pumps provide precise,
              room-by-room temperature control that just isn&apos;t possible
              with even the newest boiler or furnace models. The ENERGYSTAR cold
              climate systems we install will keep everyone comfortable all year
              round—even in subzero temperatures.
              <br /> <br /> For these reasons and more, heat pump sales outpaced
              gas furnaces for the first time in the U.S. in 2022.
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          flexWrap={"nowrap"}
          borderBottom={1}
          className={styles.OpportunityTable}
        >
          <Grid item sm={12}>
            <div className={styles.UMTitle}>What incentives are available?</div>
            <div className={styles.OpportunityDescription}>
              Many utilities, states and counties have incentive programs and
              BlocPower makes it our business to not only know the inner
              workings of all programs in our service area, but to completely
              cover the discount ourselves.
              <br /> <br /> Even if it takes weeks or months to get paid back,
              BlocPower handles all the paperwork and administration so you
              don&apos;t have to. <br /> <br /> We&apos;ve provided an initial
              estimate of your incentives in this report, including new federal
              incentives through the Inflation Reduction Act. Schedule time with
              your BlocPower representative to learn more.
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          flexWrap={"nowrap"}
          borderBottom={1}
          className={styles.OpportunityTable}
        >
          <Grid item sm={12}>
            <div className={styles.UMTitle}>
              How long does it take to install the new system?
            </div>
            <div className={styles.OpportunityDescription}>
              Installation times can vary based on complexity of project, age of
              building, type of system and several other factors. In a
              multifamily building, it takes an average of 3 days per unit to
              install a new system.
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

DownloadAssessmentPage.propTypes = {
  address: PropTypes.string,
  reportInfo: PropTypes.object,
  hasIBR: PropTypes.bool,
  isMQL: PropTypes.bool,
  closePrintPage: PropTypes.func,
}

DownloadAssessmentPage.defaultProps = {}

export default DownloadAssessmentPage
