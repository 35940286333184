import * as React from "react"
import { useState } from "react"
import PropTypes from "prop-types"
import styles from "./Assessment.module.scss"
import AssessmentHeader from "../AssessmentHeader"
import TabSection from "../TabSection/TabSection"
import FAQsSection from "../FAQsSection/FAQsSection"
import OverviewSection from "../OverviewSection/OverviewSection"
import SoWSection from "../SoWSection/SoWSection"
import IncentiveSection from "../IncentiveSection/IncentiveSection"
import CaseStudiesSection from "../CaseStudiesSection/CaseStudiesSection"
import TeamSection from "../TeamSection/TeamSection"
import FooterSection from "../FooterSection/FooterSection"
import DownloadAssessmentPage from "components/IBRv2/DownloadAssessmentPage"

const Assessment = ({
  address,
  reportInfo,
  hasIBR,
  isMQL,
  hasFinancing,
  hasProjectManagement,
}) => {
  const printable = !isMQL
  const [printing, setPrinting] = useState(false)
  const openPrintPage = () => setPrinting(true)
  const closePrintPage = () => setPrinting(false)
  return !printing ? (
    <div className={styles.Assessment} data-testid="Assessment" id="assessment">
      <div>
        <div className={styles.Sticky}>
          <AssessmentHeader
            address={address}
            reportInfo={reportInfo}
            hasIBR={hasIBR}
            isMQL={isMQL}
            printable={printable}
            openPrintPage={openPrintPage}
          />
          <TabSection
            reportInfo={reportInfo}
            hasIBR={hasIBR}
            isMQL={isMQL}
            hasProjectManagement={hasProjectManagement}
          />
        </div>
        <div className={styles.ReportContent}>
          <section id="overview">
            <OverviewSection
              reportInfo={reportInfo}
              hasIBR={hasIBR}
              isMQL={isMQL}
              hasFinancing={hasFinancing}
              printable={printable}
              openPrintPage={openPrintPage}
            />
          </section>
          <section id="sow">
            <SoWSection
              reportInfo={reportInfo}
              hasIBR={hasIBR}
              isMQL={isMQL}
              hasFinancing={hasFinancing}
              printable={printable}
              hasProjectManagement={hasProjectManagement}
              openPrintPage={openPrintPage}
            />
          </section>
          <section id="incentives">
            {reportInfo?.upgrade_measures && !hasIBR && (
              <IncentiveSection
                reportInfo={reportInfo}
                isMQL={isMQL}
                printable={printable}
                openPrintPage={openPrintPage}
              />
            )}
          </section>
          <section id="casestudy">
            {hasProjectManagement && isMQL && (
              <CaseStudiesSection reportInfo={reportInfo} />
            )}
          </section>
          <section id="team">
            {isMQL && <TeamSection reportInfo={reportInfo} />}
          </section>
          <section id="faq">
            <FAQsSection
              hubspotOwnerID={reportInfo?.hubspot_owner_id}
              hubspotOwnerURL={reportInfo?.hubspot_owner_calendar_url}
              hasFinancing={hasFinancing}
              isMQL={isMQL}
              printable={printable}
              hasProjectManagement={hasProjectManagement}
              openPrintPage={openPrintPage}
            />
          </section>
          <FooterSection reportInfo={reportInfo} />
        </div>
      </div>
    </div>
  ) : (
    <DownloadAssessmentPage
      reportInfo={reportInfo}
      address={address}
      closePrintPage={closePrintPage}
    />
  )
}

Assessment.propTypes = {
  address: PropTypes.string,
  reportInfo: PropTypes.object,
  hasIBR: PropTypes.bool,
  isMQL: PropTypes.bool,
  hasFinancing: PropTypes.bool,
  printable: PropTypes.bool,
  hasProjectManagement: PropTypes.bool,
}

Assessment.defaultProps = {}

export default Assessment
