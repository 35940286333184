import React from "react"
import styles from "./TestimonialsSection.module.scss"
import CTAButton from "components/Reusables/CTAButton"
import Stan from "images/stan.png"
import PropTypes from "prop-types"
import ActionButton from "components/IBRv2/ActionButton/ActionButton"

const TestimonialsSection = ({ reportInfo, ibr }) => {
  let scheduleButton = (
    <>
      <CTAButton styles={styles}>SCHEDULE YOUR FREE CONSULTATION</CTAButton>
    </>
  )
  if (ibr == "v2") {
    scheduleButton = (
      <>
        <ActionButton
          styles={styles}
          owner={reportInfo?.hubspot_owner_id}
          url={reportInfo?.hubspot_owner_calendar_url}
        >
          SCHEDULE YOUR FREE CONSULTATION
        </ActionButton>
      </>
    )
  }
  return (
    <div
      className={styles.TestimonialsSection}
      data-testid="TestimonialsSection"
    >
      <div className={styles.SectionInner}>
        <div className={styles.MobileImageContent}>
          <div className={styles.ImageFrame}>
            <img alt="Stan" src={Stan} className={styles.Image} />
          </div>
        </div>
        <div className={styles.Content}>
          <h2>
            Building upgrades can be tough. <span>We want to help.</span>
          </h2>
          <blockquote className={styles.Quote}>
            &quot;Working with BlocPower is{" "}
            <span>worth its weight in gold</span>. You don&apos;t know what you
            don&apos;t know about this kind of project, but BlocPower
            does.&quot;
          </blockquote>
          <div className={styles.Citation}>
            Stan, Building Owner from Brooklyn
          </div>
          {scheduleButton}
        </div>
        <div className={styles.ImageContent}>
          <div className={styles.ImageFrame}>
            <img alt="Stan" src={Stan} className={styles.Image} />
          </div>
        </div>
      </div>
    </div>
  )
}

TestimonialsSection.propTypes = {
  reportInfo: PropTypes.object,
  ibr: PropTypes.string,
}

TestimonialsSection.defaultProps = {}

export default TestimonialsSection
