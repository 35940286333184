import React from "react"
import styles from "./ScopeOfWorkSection.module.scss"
import {
  HeatPumpIcon,
  SolarIconWhite,
  WaterHeaterIcon,
  WindowIcon,
  LightbulbIcon,
  InductionIcon,
  ElectricApplianceIcon,
  InsulationIcon,
} from "components/Reusables/Icons"
import Divider from "images/icons/divider.svg"

const ScopeOfWorkSection = reportInfo => {
  const upgrade_measures = reportInfo?.upgrade_measures
  let filtered_upgrade_measures = []
  if (upgrade_measures) {
    filtered_upgrade_measures = upgrade_measures.map(upgrade_measures => {
      return upgrade_measures?.upgrade_measure_name
    })
  }

  const fetchIcon = um => {
    switch (um) {
      case "Cold Climate Air Source Heat Pump":
      case "Air Source Heat Pump":
      case "Ground Source Heat Pump":
        return <HeatPumpIcon className={styles.WorkBoxIcon} />
      case "Solar Photovoltaics":
      case "Community Solar Subscription":
        return <SolarIconWhite className={styles.WorkBoxIcon} />
      case "Heat Pump Water Heater":
        return <WaterHeaterIcon className={styles.WorkBoxIcon} />
      case "LED Lighting and Controls":
      case "Direct Install ECMs":
        return <LightbulbIcon className={styles.WorkBoxIcon} />
      case "Induction Stove":
        return <InductionIcon className={styles.WorkBoxIcon} />
      case "Energy Star Appliances":
      case "Electric Clothes Dryer":
        return <ElectricApplianceIcon className={styles.WorkBoxIcon} />
      case "Insulation":
      case "Window Upgrades and Replacement":
        return <WindowIcon className={styles.WorkBoxIcon} />
      case "Air Sealing":
      case "Air Sealing and Weatherization":
      case "Weatherstripping":
      case "Insulation, Window Treatment, Air Sealing and Weatherization":
        return <InsulationIcon className={styles.WorkBoxIcon} />
      default:
        return <HeatPumpIcon className={styles.WorkBoxIcon} />
    }
  }

  return (
    <div data-testid="ScopeOfWorkSection">
      {filtered_upgrade_measures.length != 0 && (
        <div className={styles.ScopeOfWorkSection}>
          <div className={styles.Title}>Recommended Scope of Work</div>
          <img
            src={Divider}
            alt="React Logo"
            style={{ width: "100%", margin: "0.5em 0" }}
          />
          <div className={styles.FloatingCells}>
            {filtered_upgrade_measures.map(item => (
              <div className={styles.WorkBox} key={item}>
                {fetchIcon(item)}
                <p key={item}>{item}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

ScopeOfWorkSection.propTypes = {}

ScopeOfWorkSection.defaultProps = {}

export default ScopeOfWorkSection
