import React from "react"
import PropTypes from "prop-types"
import styles from "./BMMGSavings.module.scss"
import TooltipInfoIcon from "components/Reusables/TooltipInfoIcon"
import Rectangle from "../../../images/metrics-rectangle.png"
import CTAButton from "components/Reusables/CTAButton"
import { Grid } from "@mui/material"

const BMMGSavings = reportInfo => {
  let isComedLead = reportInfo?.is_comed_lead
  let sectionTitle = isComedLead
    ? "Estimated Cost Savings"
    : "Estimated Savings"
  let ErrorMessage = !isComedLead
    ? "While your building would save energy, cost savings are unlikely at this time. Due to current natural gas costs in your area, switching to all-electric heating is likely to increase your energy bill. However, as electricity costs fall and natural gas prices rise in the future, we expect an all-electric system to save you money in the long run. In the meantime, other energy efficiency upgrades recommended above would offer small cost savings."
    : "While your building would save energy, cost savings are unlikely at this time. Due to current natural gas costs in your area, switching to all-electric heating is likely to increase your energy bill. We expect an all-electric system to save you money in the long run. In the meantime, other energy efficiency upgrades recommended above would offer small cost savings."
  let sectionText = isComedLead
    ? "Decrease on Energy Bills"
    : "Decrease on Monthly Energy Bill"
  if (!reportInfo?.upgrade_measures) {
    return (
      <div className={styles.BMMGSavings} data-testid="BMMGSavings">
        <div className={styles.Title}>{sectionTitle}</div>
        <div className={styles.AlternateMetrics}>
          {
            "A BlocPower expert can help you understand how much you can save on your energy bills."
          }
        </div>

        <Grid container>
          <Grid item xs={10} md={10} lg={10}>
            <div>
              <CTAButton styles={styles} arrow={"arrow"}>
                Schedule a call
              </CTAButton>{" "}
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
  return (
    <div className={styles.BMMGSavings} data-testid="BMMGSavings">
      <div className={styles.Title}>{sectionTitle}</div>
      <div className={styles.Metrics}>
        {reportInfo.savings_min === 0 && reportInfo.savings_max === 0
          ? "Not Applicable"
          : `${reportInfo.savings_min}\u2013${reportInfo.savings_max}%`}
        {reportInfo.savings_min < 0 && (
          <TooltipInfoIcon message={ErrorMessage} />
        )}
      </div>

      <Grid container>
        <Grid item xs={1} md={1} lg={1}>
          <img
            src={Rectangle}
            srcSet={`${Rectangle}, ${Rectangle}`}
            alt="Saving"
            className={styles.MetricsRectangle}
          />
        </Grid>
        <Grid item xs={10} md={10} lg={10}>
          <div className={styles.MetricsDetails}>{sectionText}</div>
        </Grid>
      </Grid>
    </div>
  )
}

BMMGSavings.propTypes = {}

BMMGSavings.defaultProps = {}

export default BMMGSavings
