import React from "react"
import PropTypes from "prop-types"
import styles from "./ProjectRecommendationSection.module.scss"
import CTAButton from "components/Reusables/CTAButton"
import ActionButton from "components/IBRv2/ActionButton/ActionButton"

const ProjectRecommendationSection = ({ reportInfo, ibr }) => {
  let isComedLead = reportInfo?.is_comed_lead
  let isSanJoseLead = reportInfo?.is_san_jose_lead
  let scheduleButton = (
    <>
      <CTAButton styles={styles}>SCHEDULE YOUR FREE CONSULTATION</CTAButton>
    </>
  )
  if (ibr == "v2") {
    scheduleButton = (
      <>
        <ActionButton
          styles={styles}
          owner={reportInfo?.hubspot_owner_id}
          url={reportInfo?.hubspot_owner_calendar_url}
        >
          SCHEDULE YOUR FREE CONSULTATION
        </ActionButton>
      </>
    )
  }
  let isComEdIESingleFam =
    reportInfo?.is_comed_lead &&
    reportInfo?.income_eligible &&
    ["SINGLE_FAM", "CONDO"].includes(reportInfo.building_type)
  let isComEdIEMultiFam =
    reportInfo?.is_comed_lead &&
    reportInfo?.income_eligible &&
    ["MULTI_FAM", "CO_OP"].includes(reportInfo.building_type)
  let headingText = isComEdIEMultiFam ? "big incentives " : "project savings "
  let headingSubText =
    isComEdIESingleFam || isSanJoseLead
      ? "big incentives "
      : "$0 down financing "
  return (
    <div
      className={styles.ProjectRecommendationSection}
      data-testid="ProjectRecommendationSection"
    >
      <div className={styles.Title}>Project Recommendation Report</div>
      <div className={styles.Address}>{reportInfo?.address}</div>
      <h1 className={styles.Heading}>
        Talk to an expert about&nbsp;
        <span style={{ color: "#4608F5" }}>{headingText}</span>
        &nbsp;and&nbsp;
        <span style={{ color: "#4608F5" }}>{headingSubText}</span>
      </h1>
      <div>{scheduleButton}</div>
    </div>
  )
}

ProjectRecommendationSection.propTypes = {
  reportInfo: PropTypes.object,
  ibr: PropTypes.string,
}

ProjectRecommendationSection.defaultProps = {}

export default ProjectRecommendationSection
