import React from "react"
import PropTypes from "prop-types"
import styles from "./CaseStudies.module.scss"
import Carousel from "react-material-ui-carousel"
import { Paper, Grid } from "@mui/material"
import AshpIcon from "images/icons/ashp_icon.svg"
import DhwIcon from "images/icons/dhw_icon.svg"
import IcIcon from "images/icons/ic_icon.svg"
import Case1 from "images/case1.webp"
import Case2 from "images/case2.webp"
import Case3 from "images/case3.webp"
import Case4 from "images/case4.webp"
import SolarIcon from "images/icons/solar_pv_icon.svg"
import STIcon from "images/icons/smart_thermostate_icon.svg"
import EPIcon from "images/icons/electrical_panel_icon.svg"
import Quote from "images/icons/quote.svg"
import MFIcon from "images/icons/mf_icon_blue.svg"
import { useMediaQuery } from "@mui/material"

const CaseStudies = () => {
  const isSmallScreen = useMediaQuery("(max-width: 900px)")
  let swipeFunctionality = isSmallScreen ? false : true
  const items = [
    {
      headline: "Financing enables heat pump and energy upgrades in 24 units",
      buildingType: "Large Multifamily Co-Op",
      buildingTypeIcon: (
        <img className={styles.BuildingIcon} alt={"icon"} src={MFIcon} />
      ),
      description:
        "Wisconsin local housing cooperative sought out solution for a failed boiler while addressing the objective of reducing carbon footprint from everyday power use.",
      quote:
        "BlocPower's partnership in the electrification of the historic Madison Community Coop's defunct space heating boiler came at just the right time. The project could not have succeeded without BlocPower's ability to step in this mission-driven project by providing critical financing and support.",
      caseImg: <img alt="case1" src={Case1} style={{ width: "100%" }} />,
      upgradeMeasure1: "Cold Climate Air Source Heat Pump",
      upgradeMeasure2: "Domestic Hot Water",
      upgradeMeasure3: "Induction Cooktop",
      um1Icon: <img className={styles.Icon} alt={"ASHP"} src={AshpIcon} />,
      um2Icon: <img className={styles.Icon} alt={"DHW"} src={DhwIcon} />,
      um3Icon: <img className={styles.Icon} alt={"Induction"} src={IcIcon} />,
      metric1: 7,
      metric1Text: "Metric Tons of CO2 saved",
      metric2: "$288K",
      metric2Text: "Grant Funding for Project",
      metric3: "92%",
      metric3Text: "Reduction in Annual Gas Use",
      name: "Maria Cecilia Quiñones Peña",
      title: "Project Manager",
    },
    {
      headline:
        "14 unit multifamily building finances gas boiler to heat pump conversion for $0 down",
      buildingType: "Large Multifamily Building",
      buildingTypeIcon: (
        <img className={styles.BuildingIcon} alt={"icon"} src={MFIcon} />
      ),
      description:
        "A Brooklyn resident inherited this 14-unit multifamily building with infrastructure issues. Enter BlocPower with an innovative financing plan to help replace a failed gas boiler heating system.",
      quote:
        "If BlocPower can do my building, they can do any building on the block.",
      caseImg: <img alt="case2" src={Case2} style={{ width: "100%" }} />,
      upgradeMeasure1: "Rooftop condensers",
      upgradeMeasure2: "Multi-split air source heat pumps",
      upgradeMeasure3: "Local incentives",
      um1Icon: <img className={styles.Icon} alt={"ASHP"} src={AshpIcon} />,
      um2Icon: <img className={styles.Icon} alt={"DHW"} src={DhwIcon} />,
      um3Icon: <img className={styles.Icon} alt={"Induction"} src={IcIcon} />,
      metric1: "$1500",
      metric1Text: "Monthly Lease Payment",
      metric2: "$104K",
      metric2Text: "Local Incentives for Project",
      metric3: 14,
      metric3Text: "Apartment Units Retrofitted",
      name: "Lincoln Eccles",
      title: "Owner",
    },
    {
      headline:
        "8 unit multifamily building finances new hot water heater and heat pump installation",
      buildingType: "Large Multifamily Co-Op",
      buildingTypeIcon: (
        <img className={styles.BuildingIcon} alt={"icon"} src={MFIcon} />
      ),
      description:
        "The HDFC co-op board for this building was seeking a solution to tenant complaints over inconsistent water heating and cooling. BlocPower was selected to help meet their needs.",
      quote:
        "I would say, for other building owners considering a similar project, to take a look at the price and see if it makes sense for your building. It's nice that you pay this over time so if you don't have the money to put in a full system right now, this is a great option.",
      caseImg: <img alt="case3" src={Case3} style={{ width: "100%" }} />,
      upgradeMeasure1: "Hybrid heat pump water heaters",
      upgradeMeasure2: "New pipe insulation",
      upgradeMeasure3: "Multi-split air source heat pumps",
      um1Icon: <img className={styles.Icon} alt={"ASHP"} src={AshpIcon} />,
      um2Icon: <img className={styles.Icon} alt={"DHW"} src={DhwIcon} />,
      um3Icon: <img className={styles.Icon} alt={"Induction"} src={IcIcon} />,
      metric1: "$850",
      metric1Text: "Monthly Lease Payment",
      metric2: "$60K",
      metric2Text: "Local Incentives for Project",
      metric3: 8,
      metric3Text: "Apartment Units Retrofitted",
      name: "",
      title: "",
    },
    {
      headline: "8 unit residence finances upgrades to heat pump technology ",
      buildingType: "Large Multifamily Co-Op",
      buildingTypeIcon: (
        <img className={styles.BuildingIcon} alt={"icon"} src={MFIcon} />
      ),
      description:
        "Board president of a Housing Development Fund Corporation (HDFC) co-op building in Brooklyn sought green upgrades for tenant benefits.",
      quote:
        "I'm pleased to see that the customer was thrilled with the comfort of their new heating and cooling system. We worked with them to ensure the installation was affordable and finished in a reasonable timeline.",
      caseImg: <img alt="case4" src={Case4} style={{ width: "100%" }} />,
      upgradeMeasure1: "Wall mounted air handlers",
      upgradeMeasure2: "New heat pump units",
      upgradeMeasure3: "Local contractor partnerships",
      um1Icon: <img className={styles.Icon} alt={"ASHP"} src={AshpIcon} />,
      um2Icon: <img className={styles.Icon} alt={"DHW"} src={DhwIcon} />,
      um3Icon: <img className={styles.Icon} alt={"Induction"} src={IcIcon} />,
      metric1: "$250",
      metric1Text: "Monthly Lease Payment",
      metric2: "2K",
      metric2Text: "Annual Heating Bill Savings",
      metric3: "$120K",
      metric3Text: "Local Incentives for Project",
      name: "Bradley Charles",
      title: "Project Manager",
    },
  ]
  function Item(items) {
    return (
      <Paper className={styles.PaperStyle}>
        <Grid container className={styles.Case}>
          <Grid item sm={12} md={8} className={styles.CaseGrid}>
            <div className={styles.Headline}>{items.item.headline}</div>
            <div className={styles.Description}>{items.item.description}</div>
            <div className={styles.Building}>
              <Grid container flexWrap={"nowrap"} gap={1}>
                <Grid item>{items.item.buildingTypeIcon}</Grid>
                <Grid item>{items.item.buildingType}</Grid>
              </Grid>
            </div>
            <div className={styles.UpgradeMeasure}>
              <Grid container flexWrap={"nowrap"} gap={2}>
                <Grid item>{items.item.um1Icon}</Grid>
                <Grid item>{items.item.upgradeMeasure1}</Grid>
              </Grid>
            </div>
            <div className={styles.UpgradeMeasure}>
              <Grid container flexWrap={"nowrap"} gap={2}>
                <Grid item>{items.item.um2Icon}</Grid>
                <Grid item>{items.item.upgradeMeasure2}</Grid>
              </Grid>
            </div>
            <div className={styles.UpgradeMeasure}>
              <Grid container flexWrap={"nowrap"} gap={2}>
                <Grid item>{items.item.um3Icon}</Grid>
                <Grid item>{items.item.upgradeMeasure3}</Grid>
              </Grid>
            </div>
            <Grid
              container
              flexWrap={"nowrap"}
              justifyContent={"space-between"}
            >
              <Grid item sm={3} className={styles.MetricDisplay}>
                <div className={styles.Metric}>{items.item.metric1}</div>
                <div className={styles.MetricText}>
                  {items.item.metric1Text}
                </div>
              </Grid>
              <Grid item sm={3} className={styles.MetricDisplay}>
                <div className={styles.Metric}>{items.item.metric2}</div>
                <div className={styles.MetricText}>
                  {items.item.metric2Text}
                </div>
              </Grid>
              <Grid item sm={3} className={styles.MetricDisplay}>
                <div className={styles.Metric}>{items.item.metric3}</div>
                <div className={styles.MetricText}>
                  {items.item.metric3Text}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={4} style={{ padding: "1em" }}>
            <Grid container>
              <Grid item sm={12}>
                <div>{items.item.caseImg}</div>
              </Grid>
              <Grid item sm={12}>
                <Grid
                  container
                  flexDirection={"row"}
                  className={styles.QuoteSection}
                >
                  <Grid item className={styles.Quote}>
                    <img alt="quote" src={Quote} />
                  </Grid>
                  <Grid item>{items.item.quote}</Grid>
                  <Grid item xs={12} className={styles.Name}>
                    {items.item.name}
                  </Grid>
                  <Grid item>{items.item.title}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    )
  }
  return (
    <div className={styles.CaseStudies} data-testid="CaseStudies">
      <Carousel
        navButtonsAlwaysVisible
        style={{ overflow: "visible" }}
        autoPlay={false}
        fullHeightHover={true}
        animation="slide"
        className={styles.CarouselStyle}
        swipe={swipeFunctionality}
      >
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>
    </div>
  )
}

CaseStudies.propTypes = {}

CaseStudies.defaultProps = {}

export default CaseStudies
