import React from "react"
import PropTypes from "prop-types"
import styles from "./ReportBody.module.scss"
import ProjectBenefitsSection from "../ProjectBenefitsSection"
import ImplementationOptions from "../ImplementationOptions"
import LeaseBenefitsSection from "../LeaseBenefitsSection"
import AccuracyStatement from "../AccuracyStatement"
import BlocPowerProcess from "../BlocPowerProcess"
import CTAButton from "components/Reusables/CTAButton"
import ActionButton from "components/IBRv2/ActionButton/ActionButton"

const ReportBody = ({ reportInfo, ibr }) => {
  let scheduleButton = (
    <>
      <CTAButton styles={styles}>SCHEDULE YOUR FREE CONSULTATION</CTAButton>
    </>
  )
  if (ibr == "v2") {
    scheduleButton = (
      <>
        <ActionButton
          styles={styles}
          owner={reportInfo?.hubspot_owner_id}
          url={reportInfo?.hubspot_owner_calendar_url}
        >
          SCHEDULE YOUR FREE CONSULTATION
        </ActionButton>
      </>
    )
  }
  let isSanJoseLead = reportInfo?.is_san_jose_lead
  let isComEdIeSingleFam =
    reportInfo?.is_comed_lead &&
    reportInfo?.income_eligible &&
    ["SINGLE_FAM", "CONDO"].includes(reportInfo?.building_type)
  return (
    <div className={styles.ReportBody} data-testid="ReportBody">
      <ProjectBenefitsSection />
      <ImplementationOptions reportInfo={reportInfo} ibr={ibr} />
      <AccuracyStatement ibr={ibr} reportInfo={reportInfo} />
      <BlocPowerProcess {...reportInfo} />
      {!(isComEdIeSingleFam || isSanJoseLead) && (
        <LeaseBenefitsSection reportInfo={reportInfo} ibr={ibr} />
      )}
      <div className={styles.CTAButton}>{scheduleButton}</div>
    </div>
  )
}

ReportBody.propTypes = {
  reportInfo: PropTypes.object,
  ibr: PropTypes.string,
}

ReportBody.defaultProps = {
  ibr: "v1",
}

export default ReportBody
