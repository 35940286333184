import React from "react"
import PropTypes from "prop-types"
import styles from "./ProjectAffordability.module.scss"
import { Grid } from "@mui/material"
import { Link } from "react-scroll"
import { useMediaQuery } from "@mui/material"

const ProjectAffordability = ({ reportInfo, hasIBR, isMQL, hasFinancing }) => {
  const isSmallScreen = useMediaQuery("(max-width: 900px)")
  let offset = isSmallScreen ? -246 : -210
  let totalIncentives = 0
  if (reportInfo?.total_incentive) {
    totalIncentives = reportInfo?.total_incentive?.toLocaleString()
  }
  const oilHeating = reportInfo?.heating_source_type == "OIL"
  const electricHeating =
    reportInfo?.heating_system_type == "ELECT_RESIST" ||
    reportInfo?.heating_source_type == "ELECT_RESIST"
  const showInnovativeApproach = !oilHeating && !electricHeating
  const incentiveDetails = hasIBR ? (
    <Grid item xs={12} className={styles.IncentivesDisplay}>
      <div className={styles.CallText}>
        A BlocPower building expert will help you maximize your incentives.
        Please schedule a call with us.
      </div>
    </Grid>
  ) : (
    <div className={styles.UpgradeMeasure}>
      <Grid item xs={12} className={styles.IncentivesDisplay}>
        <div className={styles.IncentivesText}>Potentially eligible for</div>
        <div className={styles.IncentivesAmount}>${totalIncentives}</div>
      </Grid>
      <Link
        smooth
        spy
        offset={offset}
        to="incentives"
        activeClass={styles.active}
      >
        <div className={styles.SeeDetails}>See details</div>
      </Link>
    </div>
  )
  return (
    <div
      className={styles.ProjectAffordability}
      data-testid="ProjectAffordability"
    >
      <div className={styles.Title}>How We Maximize Project Affordability</div>
      <div className={styles.Section}>
        <div className={styles.SubTitle}>Maximize Incentives</div>
        {incentiveDetails}
      </div>
      {hasFinancing && (
        <div className={styles.Section}>
          <div className={styles.SubTitle}>$0 Down, No Lien Financing</div>
          Accelerate capital improvements for energy system upgrades and
          building infrastructure with our accessible financing.
        </div>
      )}
      {showInnovativeApproach && (
        <div className={styles.Section}>
          <div className={styles.SubTitle}>Innovative Approach</div>
          Our expert team solves complex problems where others have
          failed—thinking outside the box to save our customers time and money.
        </div>
      )}
      {oilHeating && (
        <div className={styles.Section}>
          <div className={styles.SubTitle}>Reduced Energy Bills Drive ROI</div>
          Oil-fueled heating systems switching to electric heat pumps have
          historically saved 30% in annual energy costs.
        </div>
      )}
      {electricHeating && (
        <div className={styles.Section}>
          <div className={styles.SubTitle}>Reduced Energy Bills Drive ROI</div>
          Heat pumps typically produce 50% lower heating bills compared to PTAC
          or electric resistance systems.
        </div>
      )}
    </div>
  )
}
ProjectAffordability.propTypes = {
  reportInfo: PropTypes.object,
  hasIBR: PropTypes.bool,
  isMQL: PropTypes.bool,
  hasFinancing: PropTypes.bool,
}

ProjectAffordability.defaultProps = {}

export default ProjectAffordability
