import Axios from "axios"

export function getHeaders() {
  return {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `Token ${process.env.REACT_APP_BLOCLINK_TOKEN}`,
  }
}

const axios = Axios.create({
  baseURL: `${process.env.REACT_APP_BLOCLINK_URL}/api/`,
  timeout: 80000,
  headers: getHeaders(),
  withCredentials: true, // Include cookies in requests
  credentials: "include", // Include cookies in cross-origin requests
})

export const getCsrfToken = async () => {
  try {
    const response = await axios.get("v2/get-csrf-token/")
    return response.data.csrfToken
  } catch (error) {
    console.error("Error fetching CSRF token:", error)
    return null
  }
}

const initializeCsrfToken = async () => {
  const csrfToken = await getCsrfToken()
  if (csrfToken) {
    axios.defaults.headers.common["X-CSRFToken"] = csrfToken
  }
}

initializeCsrfToken()

export default axios
