import React from "react"
import PropTypes from "prop-types"
import styles from "./ReportChallenger.module.scss"
import ReportTop from "./ReportTop"
import ReportBody from "./ReportBody"
import ReportFooter from "./ReportFooter"

const ReportChallenger = ({ reportInfo, ibr }) => (
  <div className={styles.Report} data-testid="ReportChallenger">
    <ReportTop reportInfo={reportInfo} ibr={ibr} />
    <ReportBody reportInfo={reportInfo} ibr={ibr} />
    <ReportFooter reportInfo={reportInfo} ibr={ibr} />
  </div>
)

ReportChallenger.propTypes = {
  reportInfo: PropTypes.object,
  ibr: PropTypes.string,
}

ReportChallenger.defaultProps = {
  ibr: "v1",
}

export default ReportChallenger
