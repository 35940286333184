import React from "react"
import PropTypes from "prop-types"
import styles from "./FullBleedHeader.module.scss"
import BPLogo from "images/bp-logo-white.svg"

const FullBleedHeader = () => (
  <div className={styles.FullBleedHeader} data-testid="FullBleedHeader">
    <img className={styles.Logo} src={BPLogo} alt="BlocPower Logo" />
  </div>
)

FullBleedHeader.propTypes = {}

FullBleedHeader.defaultProps = {}

export default FullBleedHeader
