import React, { useCallback, useEffect } from "react"
import styles from "./BuildingPage.module.scss"
import BuildingPageForm from "../BuildingPageForm"
import { submitBuildingSubmissionInfo } from "../../../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import Footer from "components/Footer"
import FullBleedHeader from "components/FullBleedHeader"
import largeImage from "../../../images/verify-large-image.png"
import { Grid, Box } from "@mui/material"

const BuildingPage = () => {
  const {
    isError,
    data = {},
    status,
  } = useSelector(state => state.buildingInfoSubmission)
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  let buildingInfo = location?.state?.buildingInfo
  const { submissionId } = useParams()

  const onSubmit = useCallback(
    data => {
      dispatch(submitBuildingSubmissionInfo(submissionId, data))
    },
    [dispatch, submissionId]
  )
  const goToProjectIntent = id => {
    navigate("../../intent/" + id, { replace: true })
  }
  useEffect(() => {
    if (isError) {
      data.detail
        ? window.alert(data.detail)
        : window.alert("There is an error. Please try again.")
    } else if (status == "succeeded") {
      goToProjectIntent(submissionId)
      return
    }
  }, [isError, status])
  return (
    <div className={styles.BuildingPage} data-testid="BuildingPage">
      <FullBleedHeader />
      <div className={styles.BuildingPageContainer}>
        <Grid container spacing={3}>
          <Grid item sm={10} md={4}>
            <div className={styles.BuildingPageForm}>
              <BuildingPageForm
                buildingInfo={buildingInfo}
                onSubmit={onSubmit}
                isLoading={status == "loading"}
              />
            </div>
          </Grid>
          <Box
            component={Grid}
            item
            md={8}
            display={{ xs: "none", md: "flex" }}
            style={{
              alignSelf: "center",
              justifyContent: "right",
              zIndex: "-2",
            }}
          >
            <div className={styles.BuildingPageImage}>
              <img
                src={largeImage}
                srcSet={`${largeImage}, ${largeImage}`}
                alt="Family"
                className={styles.LargeImage}
              />
            </div>
          </Box>
        </Grid>
      </div>
      <Footer />
    </div>
  )
}

BuildingPage.propTypes = {}

BuildingPage.defaultProps = {}

export default BuildingPage
