import React, { useEffect } from "react"
import PropTypes from "prop-types"
import styles from "./AssessmentLoading.module.scss"
import BuildingLoading from "images/IBR_rebranded_loading.gif"
import { Grid, Box } from "@mui/material"
import TextTransition, { presets } from "react-text-transition"

const AssessmentLoading = () => {
  const TEXTS = [
    "Preparing your custom report",
    "Calculating available incentives",
    "Selecting comparable projects",
    "Developing scope of work",
  ]
  const [index, setIndex] = React.useState(0)
  useEffect(() => {
    const intervalId = setInterval(() => setIndex(index => index + 1), 3000)
    return () => clearTimeout(intervalId)
  }, [])
  return (
    <div data-testid="AssessmentLoading">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "80vh" }}
      >
        <Grid item>
          <div className={styles.LoadingIconArea}>
            <img
              src={BuildingLoading}
              alt="Fetching the Report"
              className={styles.LoadingIcon}
            />
          </div>
          <div className={styles.RotateText}>
            <TextTransition
              text={TEXTS[index % TEXTS.length]}
              springConfig={presets.wobbly}
              className={styles.TextTransition}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

AssessmentLoading.propTypes = {}

AssessmentLoading.defaultProps = {}

export default AssessmentLoading
