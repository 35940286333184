import React from "react"
import PropTypes from "prop-types"
import styles from "./ReportTop.module.scss"
import ReportHeader from "../ReportHeader"
import ProjectRecommendationSection from "../ProjectRecommendationSection"
import ScopeOfWorkSection from "../ScopeOfWorkSection"
import MetricsDisplay from "../MetricsDisplay"

const ReportTop = ({ reportInfo, ibr }) => (
  <div className={styles.ReportTop} data-testid="ReportTop">
    <ReportHeader reportInfo={reportInfo} ibr={ibr} />
    <div className={styles.FloatingCols}>
      <div className={styles.FloatingCells}>
        <ProjectRecommendationSection reportInfo={reportInfo} ibr={ibr} />
        <ScopeOfWorkSection {...reportInfo} />
      </div>
      <div className={styles.FloatingGrow}>
        <MetricsDisplay reportInfo={reportInfo} />
      </div>
    </div>
  </div>
)

ReportTop.propTypes = {
  reportInfo: PropTypes.object,
  ibr: PropTypes.string,
}

ReportTop.defaultProps = {}

export default ReportTop
