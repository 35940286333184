import React from "react"
import PropTypes from "prop-types"
import { Grid } from "@mui/material"
import styles from "./DenverIncomeQualificationForm.module.scss"
import { useForm } from "react-hook-form"
import Button from "components/Reusables/Button"
import gridGroup from "../../../images/grid-group.png"
import rectangleLine from "../../../images/verify-rectangle-line.png"
import largeImage from "../../../images/verify-large-image.png"

const DenverIncomeQualificationForm = ({ onSubmit, isLoading }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ shouldUnregister: true })

  return (
    <div className={styles.DenverIncomeQualificationForm}>
      <Grid container>
        <Grid item md={8} lg={8}>
          <img
            src={rectangleLine}
            srcSet={`${rectangleLine}, ${rectangleLine}`}
            alt="Family"
            className={styles.rectangleLine}
          />
          <div className={styles.FormHeading}>
            <span className={styles.FormHeadingColorPart}>Your building</span>{" "}
            information{" "}
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            data-testid="DenverIncomeQualificationForm"
          >
            <fieldset data-testid="IncomeQualificationForm">
              <div>
                <label htmlFor="denver_income_qualification">
                  Are two-thirds of the households in your building considered
                  low income?
                  <p>
                    To qualify as low income, the annual income for a household
                    with four individuals should be no more than $89,400. Review
                    income qualification criteria for other household sizes at
                    80% AMI &nbsp;
                    <a
                      href="https://www.denvergov.org/files/assets/public/housing-stability/documents/2022-host-income-rent-limits.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>
                    .&nbsp;If unsure, answer <b>No</b> and BlocPower can help
                    you find the right answer in the future.
                  </p>
                </label>
                <div>
                  <label htmlFor="denver_income_qualification">
                    <input
                      {...register("denver_income_qualification")}
                      type="radio"
                      value="yes"
                      id="denver_income_qualification"
                      required
                    />
                    <span className={styles.IncomeInput}>Yes</span>
                  </label>
                </div>
                <div>
                  <label htmlFor="denver_income_qualification">
                    <input
                      {...register("denver_income_qualification", {
                        required: true,
                      })}
                      type="radio"
                      value="no"
                      id="denver_income_qualification"
                    />
                    <span className={styles.IncomeInput}>No</span>
                  </label>
                </div>
              </div>
              {errors?.denver_income_qualification?.type === "required" && (
                <span className={styles.isRequired}> This is required. </span>
              )}
            </fieldset>
            <div className={styles.ButtonSection}>
              <Button styles={styles} isLoading={isLoading}>
                Next
              </Button>
            </div>
          </form>
        </Grid>
        <Grid item md={4} lg={4}>
          <div className={styles.LargeImageSection}>
            <img
              src={gridGroup}
              srcSet={`${gridGroup}, ${gridGroup}`}
              alt="Grids"
              className={styles.GridGroupVerifyPage}
            />
            <img
              src={largeImage}
              srcSet={`${largeImage}, ${largeImage}`}
              alt="Family"
              className={styles.LargeImage}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

DenverIncomeQualificationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

DenverIncomeQualificationForm.defaultProps = {
  errors: {},
  styles: {},
  onSubmit: () => {},
}

export default DenverIncomeQualificationForm
